import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_SUPPORT_STATUS, CODE, CODE_SEX } from "@/common/constants";

export default {
    async getList(data, page = 1, limit = 10) {
        return await Repository.post(`${ADMIN_REPOSITORY}/manage-support-progress/list`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getCodeSupportStatus() {
        try {
            return await Repository.get(`code_define?group=${CODE_SUPPORT_STATUS}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getSex() {
        try {
            return await Repository.get(`code_define?group=${CODE_SEX}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthGuideCode() {
        try {
            return await Repository.get(`code_define?group=${CODE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getSupportPerson() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/manage-support-progress/support-person`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
