import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_RECEPTION, CODE_FRACTION } from "@/common/constants";

export default {
    async getListClientOrganization(page = 1, limit = 10) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/management/client-organization/list`,{page, limit})
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async updateClientOrganization(data, id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/client-organization/update/${id}`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async storeClientOrganization(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/client-organization/store`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async deleteClientOrganization(id) {
        try {
            return await Repository.delete(`${ADMIN_REPOSITORY}/management/client-organization/delete/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getListContractByClientOrganizationId(clientOrganizationId) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/management/client-organization-contract/list-by-client-organization-id/${clientOrganizationId}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getHealthSupportPattern() {
        try {
            return await Repository.get("health-support-pattern/list");
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getHealthSupportForm() {
        try {
            return await Repository.get("health-support-form/list-form-support",{support_form_div: "FIRST_SUPPORT"})
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getMedicalGuidanceOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-organization/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getPaymentRepresentativeOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/payment-agencie/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getClientOrganizationListContract() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContract`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getClientOrganizationListContact() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContact`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getClientOrganizationFormLayoutControl(insurer_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client_organization_form_layout_control/${insurer_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    
    async getCodeFraction() {
        try {
            return await Repository.get(`code_define?group=${CODE_FRACTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async updateFormLayoutControl(id, data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/client-organization-form-layout-control/${id}/edit`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async storeFormLayoutControl(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/client-organization-form-layout-control/save`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async deleteFormLayoutControl(id) {
        try {
            return await Repository.delete(`${ADMIN_REPOSITORY}/management/client-organization-form-layout-control/${id}/delete`)
        } catch (e) {
            return this.setErrors(e);
        }
    },


    async deleteClientOrganizationContract(id) {
        try {
            return await Repository.delete(`${ADMIN_REPOSITORY}/management/client-organization-contract/${id}/delete`)
        } catch (e) {
            return this.setErrors(e);
        }
    },


    async updateClientOrganizationContract(id, data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/client-organization-contract/${id}/edit`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async storeClientOrganizationContract(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/client-organization-contract/save`, data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
