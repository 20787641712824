import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_RECEPTION, CODE } from "@/common/constants";

export default {
    async getList() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/management/payment-agencies/list`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async update(data, id) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/payment-agencies/edit/${id}`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async store(data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/management/payment-agencies/create`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async delete(id) {
        try {
            return await Repository.delete(`${ADMIN_REPOSITORY}/management/payment-agencies/delete/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
