<template>
    <v-card>
        <v-container>
            <div class="card-export">
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" v-model="exportInfo.pdf" type="checkbox" id="export_pdf">
                        <label class="form-check-label" for="export_pdf">
                            特定保健指導計画及び実施報告書
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" v-model="exportInfo.xml" type="checkbox" id="export_xml">
                        <label class="form-check-label" for="export_xml">
                            特定保健指導XMLデータ
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" v-model="exportInfo.statistical_csv" type="checkbox" id="export_statistical_csv">
                        <label class="form-check-label" for="export_statistical_csv">
                            請求集計データ
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" v-model="exportInfo.detail_csv" type="checkbox" id="export_detail_csv">
                        <label class="form-check-label" for="export_detail_csv">
                            請求明細データ
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" v-model="exportInfo.status_support_csv" type="checkbox" id="export_status_support_csv">
                        <label class="form-check-label" for="export_status_support_csv">
                            支援状況データ
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-check">
                        <input class="form-check-input" v-model="exportInfo.evaluate_support_csv" type="checkbox" id="export_evaluate_support_csv">
                        <label class="form-check-label" for="export_evaluate_support_csv">
                            支援評価情報データ
                        </label>
                    </div>
                </div>
            </div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                <button type="button" class="btn btn-secondary" @click="$emit('closeDialog')">キャンセル</button>
                <button type="button" class="btn btn-primary" @click="exportData">出力</button>
            </v-card-actions>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "DialogConfirm",
    props: {},
    data() {
        return {
            exportInfo: {
                pdf: false,
                xml: false,
                statistical_csv: false,
                detail_csv: false,
                status_support_csv: false,
                evaluate_support_csv: false,
            }
        }
    },
    methods: {
        exportData() {
            this.$emit("exportData", this.exportInfo);
        }
    }
};
</script>

<style scoped lang="scss">
.card-export {
    margin-left: 2%;
    input, label {
        font-size: 16px !important;
    }
}
</style>
