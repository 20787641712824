<template>
  <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
    <div class="container-fluid">

      <div>
        <v-card-actions class="d-flex justify-content-end">
          <Button v-if="isPageMode_Edit()"  @click="isShowDialog = true" color="error" :rounded="false">
            <v-icon>mdi-trash-can-outline</v-icon>
            削除
          </Button>
        </v-card-actions>
        <div v-if="dataEdit" class="col-12 border tab-eligible-person-information">
          <div class="row">
            <div class="col-12">
              <div>
                <div class="float-right">
                  <div class="d-flex">
                    <div style="width:300px">登録情報</div>
                    <div style="width:300px">更新情報</div>
                  </div>
                  <div class="d-flex">
                    <Sheet width="300" height="40" class="p-2">
                      {{ formatDate(dataEdit.created_at) }} <span v-if="dataEdit.created_by">by {{ String(dataEdit.created_by).padStart(7, '0') }}</span>
                    </Sheet>
                    <Sheet width="300" height="40" class="p-2">
                      {{ formatDate(dataEdit.updated_at) }} <span v-if="dataEdit.updated_by">by {{ String(dataEdit.updated_by).padStart(7, '0') }}</span>
                    </Sheet>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ml-4 align-items-center">
            <div class="col-1 mt-2 form-check">
              <label class="form-check-label" for="disable_flg">無効</label>
              <input v-model="dataEdit.disable_flg" type="checkbox" class="form-check-input" id="disable_flg" />
            </div>
            <div class="col-4">
              <span class="required">表示順</span>
              <input type="text" v-model="dataEdit.disp_seq" class="form-control" />
              <span class="text-danger">{{ errors.disp_seq }}</span>
            </div>
          </div>
          <div class="col-4 ml-4 mt-3 form-check">
            <label style="margin-left: -20px;" class="form-check-label" for="default_flag">デフォルト</label>
            <br>
            <input type="checkbox" v-model="dataEdit.default_flag" class="form-check-input" id="default_flag" />
          </div>
          <div class="col-5">
            <span class="required">機関番号</span>
            <input type="text" v-model="dataEdit.organ_code" class="form-control" />
            <span class="text-danger">{{ errors.organ_code }}</span>
          </div>
          <div class="col-5">
            <span class="required">機関名称</span>
            <input type="text" v-model="dataEdit.organ_name" class="form-control" />
            <span class="text-danger">{{ errors.organ_name }}</span>
          </div>
          <div class="col-5">
            <span>保健指導責任者</span>
            <input type="text" v-model="dataEdit.health_guidance_mngr_name" class="form-control" />
            <span class="text-danger">{{ errors.health_guidance_mngr_name }}</span>
          </div>
          <div class="col-5">
            <span class="required">郵便番号</span>
            <input type="text" v-model="dataEdit.organ_postal_code" class="form-control" />
            <span class="text-danger">{{ errors.organ_postal_code }}</span>
          </div>
          <div class="col-5">
            <span class="required">住所</span>
            <input type="text" v-model="dataEdit.organ_addr_01" class="form-control" />
            <span class="text-danger">{{ errors.organ_addr_01 }}</span>
          </div>
          <div class="col-5">
            <span>住所続き</span>
            <input type="text" v-model="dataEdit.organ_addr_02" class="form-control" />
            <span class="text-danger">{{ errors.organ_addr_02 }}</span>
          </div>
          <div class="col-5">
            <span class="required">電話番号</span>
            <input type="text" v-model="dataEdit.organ_tel_01" class="form-control" />
            <span class="text-danger">{{ errors.organ_tel_01 }}</span>
          </div>
        </div>
        <footer>
          <div class="row">
            <div class="col-md-12 d-inline-flex justify-content-between">
              <button @click="hide" type="button" class="btn btn-secondary">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                キャンセル
              </button>
              <button @click="isShowDialogSave = true" type="button" class="btn btn-primary">
                <i class="fa fa-database" aria-hidden="true"></i>
                保存
              </button>
            </div>
          </div>
        </footer>
      </div>
      <Dialog v-if="isPageMode_Edit()" :maxWidth="600" :isShow="isShowDialog">
        <v-card>
          <v-container>
            <v-card-title>削除</v-card-title>
            <div class="m-3">削除処理を実行します。よろしいですか？</div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
              <button type="button" class="btn btn-secondary" @click="isShowDialog = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="deleteItem(dataEdit.id)">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
      <Dialog :maxWidth="600" :isShow="isShowDialogSave">
        <v-card>
          <v-container>
            <v-card-title>保存</v-card-title>
            <div class="m-3">保存処理を実行します。よろしいですか？</div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
              <button type="button" class="btn btn-secondary" @click="isShowDialogSave = false">
                キャンセル
              </button>
              <button type="button" class="btn btn-primary" @click="save()">
                OK
              </button>
            </v-card-actions>
          </v-container>
        </v-card>
      </Dialog>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import moment from "moment";
import { INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import { TOAST_TIME_OUT } from "@/common/constants";
import SpecificHealthGuidanceOrganizationsManagement from "@/services/api/SpecificHealthGuidanceOrganizationsManagement";
import mixins from "@/mixins";
import _ from "lodash";
import Sheet from "@/components/common/Sheet";
import Button from "@/components/common/Button";
import Dialog from "@/components/common/Dialog";

export default {
    name: "HealthDGuidanceOrganizationDetail",
    mixins: [mixins],
    components: {
        Sheet,
        Button,
        Dialog,
    },
    props: {
        pageMode: null, // "NEW":新規、"EDIT":編集
        dataDetail: null,
        isShowNavigation: {
            default: "",
        },
    },
    data() {
        return {
            page_mode: "NEW",
            isShowDialog: false,  
            isShowDialogSave: false,
            isUpdate: false,
            dataEdit: {
                disable_flg: false,
                disp_seq: 0,
                default_flag: false,
                organ_code: null,
                organ_name: null,
                health_guidance_mngr_name: null,
                organ_postal_code: null,
                organ_addr_01: null,
                organ_addr_02: null,
                organ_tel_01: null,
                created_at: "",
                created_by: null,
                updated_at: "",
                updated_by: null
            },
            errors: {
                disp_seq: null,
                organ_code: null,
                organ_name: null,
                organ_postal_code: null,
                organ_addr_01: null,
                organ_addr_02: null,
                organ_tel_01: null,
                health_guidance_mngr_name: null,
            },
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
        };
    },
    watch: {
        dataDetail() {
            this.getDetail(this.dataDetail);
        },  
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        }
    },
    created() {
        this.page_mode = this.pageMode;
        if (this.dataDetail) {
            this.getDetail(this.dataDetail);
            if (this.isPageMode_New()) {
                delete this.dataEdit.id;
                delete this.dataEdit.created_by;
                delete this.dataEdit.updated_by;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "common/getUser"
        }),
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * 日付フォーマット
         * @param {*} date 
         */
        formatDate(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },

        /**
         * 詳細情報設定.
         * @param {*} dataDetail 
         */
        getDetail(dataDetail) {
            this.dataEdit = { ...dataDetail };
            if (this.isPageMode_New()) {
                this.dataEdit.disp_seq = 0;
                this.dataEdit.disable_flg = 0;
                this.dataEdit.created_at = "";
                this.dataEdit.created_by = null;
                this.dataEdit.updated_at = "";
                this.dataEdit.updated_by = null;
            } else {
                this.dataEdit.created_by = this.dataEdit.created_by?.id;
                this.dataEdit.updated_by = this.dataEdit.updated_by?.id;
            }
        },

        /**
         * データ削除
         * @param {*} id 
         */
        deleteItem(id) {
            this.setIsLoading(true)
            SpecificHealthGuidanceOrganizationsManagement.delete(id)
                .then((res) => {
                    this.setIsLoading(false)
                    const { data, status } = res;
	                this.isShowDialog = false;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: "入力エラー <br>他データから参照されているため、削除できません。",
                            type: "error",
                        });
                    } else {
                        this.$toast.open({
                            message: "特定保健指導機関の削除が完了しました。",
                            type: "success",
                        });
                        this.isUpdate = true;
                        this.hide();
                    }
                })
        },

        /**
         * バリデーション
         */
        checkValidate() {
            this.errors.disp_seq = null;
            this.errors.organ_code = null;
            this.errors.organ_name = null;
            this.errors.organ_postal_code = null;
            this.errors.organ_addr_01 = null;
            this.errors.organ_addr_02 = null;
            this.errors.organ_tel_01 = null;
            this.errors.health_guidance_mngr_name = null;

            if (!this.dataEdit.organ_name) {
                this.errors.organ_name = "取引先名称を入力してください。";
            } else if (String(this.dataEdit.organ_name).length > 20) {
                this.errors.organ_name = "機関名称は20文字以下で入力してください。";
            }

            if (this.dataEdit.disp_seq) {
                if (!String(this.dataEdit.disp_seq).match(/^[0-9]+$/)) {
                    this.errors.disp_seq = "表示順は数字で入力してください。";
                } else if (this.dataEdit.disp_seq > 999 || this.dataEdit.disp_seq < 0) {
                    this.errors.disp_seq = "表示順は[0〜999]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.disp_seq !== 0){
                    this.errors.disp_seq = "表示順を入力してください。";
                }
            }

            if (this.dataEdit.organ_code) {
                if (!String(this.dataEdit.organ_code).match(/^[0-9]+$/)) {
                    this.errors.organ_code = "機関番号は半角数字で入力してください。";
                } else if (String(this.dataEdit.organ_code).length > 10 || String(this.dataEdit.organ_code).length < 10) {
                    this.errors.organ_code = "機関番号は10文字で入力してください。";
                } else if (String(this.dataEdit.organ_code).match(/^[０-９]+$/)) {
                    this.errors.organ_code = "機関番号は半角数字で入力してください。";
                }
            } else {
                this.errors.organ_code = "機関番号を入力してください。";
            }

            if (this.dataEdit.organ_postal_code) {
                if (!String(this.dataEdit.organ_postal_code).match(/^[0-9]+$/)) {
                    this.errors.organ_postal_code = "郵便番号は半角数字で入力してください。";
                } else if (String(this.dataEdit.organ_postal_code).length > 7 || String(this.dataEdit.organ_postal_code).length < 7) {
                    this.errors.organ_postal_code = "郵便番号は7文字で入力してください。";
                }
            } else {
                this.errors.organ_postal_code = "郵便番号を入力してください。";
            }

            if (String(this.dataEdit.health_guidance_mngr_name).length > 20) {
                this.errors.health_guidance_mngr_name = "保健指導責任者は20文字以下で入力してください。";
            }
            if (this.dataEdit.organ_addr_01) {
                if (String(this.dataEdit.organ_addr_01).length > 40) {
                    this.errors.organ_addr_01 = "住所は40文字以下で入力してください。";
                }
            } else {
                this.errors.organ_addr_01 = "住所を入力してください。";
            }

            if (String(this.dataEdit.organ_addr_02).length > 255) {
                this.errors.organ_addr_02 = "住所続きは255文字以下で入力してください。";
            }

            if (this.dataEdit.organ_tel_01) {
                if (!String(this.dataEdit.organ_tel_01).match(/^[0-9]+$/)) {
                    this.errors.organ_tel_01 = "電話番号は半角数字で入力してください。";
                } else if (String(this.dataEdit.organ_tel_01).length > 15) {
                    this.errors.organ_tel_01 = "電話番号は15文字以下で入力してください。";
                }
            } else {
                this.errors.organ_tel_01 = "電話番号を入力してください。";
            }
        },

        /**
         * 保存
         */
        async save() {
            this.checkValidate();
            var checkValidate = true;
            this.isShowDialogSave = false;
            for (const [key, value] of Object.entries(this.errors)) {
                if (value != null) {
                    checkValidate = false;
                    break;
                }
            }

            if (checkValidate) {
                this.setIsLoading(true);
                if (this.isPageMode_New()) {
                    await this.storeData();
                } else {
                    await this.updateData();
                }
            }
        },

        /**
         * 新規追加
         */
        async storeData() {
            SpecificHealthGuidanceOrganizationsManagement.store(this.dataEdit).then((res) => {
                this.setIsLoading(false)
                const { data, status } = res;
                // if(res.errorCode === INTERNAL_SERVER_ERROR) {
                //     this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                //     return;
                // }
                if (status !== SUCCESS) {
                    this.$toast.open({
                        message: `入力エラー<br />機関番号[${this.dataEdit.organ_code}]は既に存在します。`,
                        type: "error",
                    });
                } else {
                    this.$toast.open({
                        message: "特定保健指導機関の登録が完了しました。",
                        type: "success",
                    });
                    this.dataEdit = data.data;
                    this.page_mode = "EDIT";
                    this.isUpdate = true;
                }
            });
        },

        /**
         * 更新
         */
        async updateData() {
            SpecificHealthGuidanceOrganizationsManagement.update(this.dataEdit, this.dataEdit.id).then((res) => {
                this.setIsLoading(false)
                const { data, status } = res;
                // if(res.errorCode === INTERNAL_SERVER_ERROR) {
                //     this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                //     return;
                // }
                if (status !== SUCCESS) {
                    this.$toast.open({
                        message: `入力エラー<br />機関番号[${this.dataEdit.organ_code}]は既に存在します。`,
                        type: "error",
                    });
                } else {
                    this.$toast.open({
                        message: "特定保健指導機関の登録が完了しました。",
                        type: "success",
                    });
                    this.dataEdit.updated_at = data.data.updated_at;
                    this.dataEdit.updated_by = data.data.updated_by;
                    this.isUpdate = true;
                }
            });
        },

        /**
         * 閉じる
         */
        hide() {
            this.hiddenAnimation = true;
            this.dataEdit = {
                disable_flg: false,
                disp_seq: 0,
                default_flag: false,
                organ_code: null,
                organ_name: null,
                health_guidance_mngr_name: null,
                organ_postal_code: null,
                organ_addr_01: null,
                organ_addr_02: null,
                organ_tel_01: null,
            },
            this.errors = {
                disp_seq: null,
                organ_code: null,
                organ_name: null,
                organ_postal_code: null,
                organ_addr_01: null,
                organ_addr_02: null,
                organ_tel_01: null,
                health_guidance_mngr_name: null
            };

            $(".tab-eligible-person-information").scrollTop(0);

            if (this.isUpdate) {
                this.$emit("reload", true);
                this.isUpdate = false;
            }
            this.$emit("reload", false);
            this.$store.commit("hideSlide");
        },

        /**
         * 
         */
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },

        /**
         * 
         */
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },

        /**
         * リロード
         */
        reloadDetail(param) {
            if (param == "update") {
                this.isUpdate = true;
            }
            this.hide();
        },

        /**
         * PageMode 新規の場合true
         */
        isPageMode_New() {
            return this.page_mode == "NEW";
        },

        /**
         * PageMode 編集の場合true
         */
        isPageMode_Edit() {
            return this.page_mode == "EDIT";
        }
    }
};
</script>
<style lang="sass" scoped>
  @import "../../styles/common"
  .sidebar-show
    animation: show 0.5s ease forwards

  .sidebar-hidden
    animation: hidden 0.5s ease forwards

  @media (max-width: 1025px)
    .navigation
      transform: translate(0%) !important
    .v-navigation-drawer--close
      visibility: unset

  @keyframes hidden
    from
      width: var(widthAnimation)
    to
      width: 0

  @keyframes show
    from
      width: 0
    to
      width: var(widthAnimation)
</style>