<template>
    <!-- セグメントブロック -->
    <div class="segment-block-prog-mng mb-2 mt-5" :class="['border-color-'+segment.checkBorder,checkBgGray(segment),checkBgViolet()]">
        <!-- タイトル部 -->
        <div class="header d-flex justify-space-between p-1" :class="classSegment(segment, isSupportFlowStopStatus())">
            <span v-html="checkTitle(segment)"></span>
        </div>
        <!-- コンテンツ部 -->
        <div class="body p-2 position-relative">
            <!-- コンテンツ１行目 -->
            <div class="col-6 pt-1 pb-1">
                <!-- 日付 -->
                <span v-html="getContent1(segment)"></span>
                <!-- ラベル -->
                <span v-if="!isSupportFlowStopStatus() && segment.support_progress_status == codeInProgress" class="p-2 pt-0 pb-0 rounded position-absolute button_save_draff">一時保存</span>
            </div>
            <!-- コンテンツ２行目 -->
            <div class="col-6 pt-0 pb-0 text-no-wrap">
                <!-- 担当者 -->
                {{ getContent2(segment) }}
            </div>
        </div>
    </div>
</template>
<script>
import {CODE_SUPPORT_EXEC_DIV} from "@/common/constants";
import mixins from "@/mixins"
import moment from "moment";

export default {
    name: "SegmentBlock",
    props: {
        segment: null,
        healthSupportFlows: null,
    },
    components: {},
    mixins: [
        mixins
    ],

    data() {
        return {
            UNSET_TEXT: "ー", 

            // 支援実施区分
            codeStatusSupportFirst: 10, // 初回支援
            codeStatusSupportContinuous: 20, // 継続支援
            codeStatusSupportFinal: 30, // 最終評価

            // セグメントステータス
            codePlan: 10, // 計画
            codePurpose: 20, // 予定
            codeInProgress: 30, // 実施中
            codeConfirm: 40, // 確定
        };
    },

    watch: {
        //
    },

    async created() {
        //
    },

    methods: {
        /**
         * タイトル部
         * @param {*} item 
         */
        checkTitle(item){
            let text = "";
            // 初回支援or最終評価の場合
            if (item.support_exec_div == this.codeStatusSupportFirst) {
                text = "初回";
            } else if (item.support_exec_div == this.codeStatusSupportFinal) {
                text = "最終";
            } else if (item.support_exec_div == this.codeStatusSupportContinuous) {
                // 継続支援
                text = item.health_support_form?.support_form_name_disp_01;
            }

            return text + (item.mid_eval_flg ? " <span class='text-danger pl-2'>中間</span>" : "");
        },

        /**
         * コンテンツ１行目
         * @param {*} item 支援フローセグメント
         */
        getContent1(item){
            // 支援中止フラグがONの場合.
            if (item.support_stop_flag) return null;

            if(item.support_progress_status == this.codeConfirm){
                // 確定の場合
                // 実施日の表示
                return this.formatMMDD(item.support_date);
            } else {
                // 計画or予定or実施中の場合
                if (item.scheduled_support_date) {
                    // 予定日が設定されている場合
                    return this.formatMMDD(item.scheduled_support_date);
                } else if (item.plan_support_date) {
                    // 計画日が設定されている場合
                    return `<span style="color: #1c4fff">計:  ${ this.formatMMDD( item.plan_support_date) }</span>`;
                } else {
                    return this.UNSET_TEXT;
                }
            }
        },

        /**
         * コンテンツ２行目
         * @param {*} item  支援フローセグメント
         */
        getContent2(item){
            // 支援中止フラグがONの場合.
            if (item.support_stop_flag) return null;

            let userName = null;

            if(item.support_progress_status == this.codeConfirm){
                // 確定の場合
                userName = item.support_staff_user?.user_name_disp;
            } else {
                // 計画or予定or実施中の場合
                userName = item.get_scheduled_support_staff?.user_name_disp;
            }

            return userName ?? this.UNSET_TEXT;
        },

        /**
         * フォーマット MM/DD
         */
        formatMMDD(date) {
            return date ? moment(date).format("MM/DD") : this.UNSET_TEXT;
        },

        /**
         * 支援進捗ステータスが「確定」
         * @param {*} segment 
         */
        checkBgGray(segment){
            if (segment.support_progress_status == 40) {
                return "background-gray";
            }
            return "";
        },

        /**
         * 支援フローステータスが「初回面接辞退」or「途中終了」
         */
        checkBgViolet(){
            if (this.isSupportFlowStopStatus()) {
                return "background-violet";
            }
            return "";
        },

        /**
         * 初回面接辞退 or 途中終了　ならばtrue
         */
        isSupportFlowStopStatus() {
            return this.healthSupportFlows.support_flow_status == 40 || 
                this.healthSupportFlows.support_flow_status == 50;
        }
    }
}
</script>
<style scoped lang="sass">
    .segment-block-prog-mng
        min-width: 150px
        min-height: 100px
        border: 1px solid #cdcdcd
        font-size: 11px
    .cursor-pointer
        cursor: pointer
    .bg-a-support, .bg-b-support
        background: green
        color: gray
    .ellipsis
        text-overflow: ellipsis
    .button_save_draff
        border: 1px solid blue
        opacity: 0.6
        top: 10px
        left: 70px
        color: blue
    .border-color-red
        border: 2px solid red
    .border-color-orange
        border: 2px solid orange
    .background-violet
        background: #c2b2ff
    .background-gray
        background: #dfdfdf
</style>
