<template>
  <div class="list">
    <div class="box-search mb-5">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">対象者情報</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="border border-top-0 rounded p-1 pr-2">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-4">
                        出力データ
                        <select v-model="dataSearch.export_file" class="form-select">
                          <option value="object-information">対象者情報CSV</option>
                          <option value="phone-log">利用勧奨記録CSV</option>
                          <option value="support-status">支援状況CSV</option>
                          <option value="support-rating-information">支援評価情報CSV</option>
                        </select>
                        <span class="text-danger">{{ errors.organ_code }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 pl-6 pr-6 m-auto mb-9">
                    <span style="margin-left: -12px;">抽出条件</span>
                    <div class="row border">
                      <div class="col-12  text-right">
                        <button @click="clearAllSearch" type="button" class="btn btn-grey">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          クリア
                        </button>
                      </div>
                      <div class="col-2">
                        氏名
                        <input id="name" name="name" type="text" class="form-control" placeholder="氏名 or 氏名カナ"
                               v-model="dataSearch.patient_name" />
                        <span class="text-danger">{{ errors.patient_name }}</span>
                      </div>
                      <div class="col-2">
                        利用券整理番号
                        <input v-model="dataSearch.patient_ticket" type="text" class="form-control" placeholder="" />
                        <span class="text-danger">{{ errors.patient_ticket }}</span>
                      </div>
                      <div class="col-2">
                        電話番号
                        <input v-model="dataSearch.phone_number" type="text" class="form-control" placeholder="" />
                        <span class="text-danger">{{ errors.phone_number }}</span>
                      </div>
                      <div class="col-2">
                        保険者
                        <select v-model="dataSearch.insurer" class="form-select">
                          <option :value="null"></option>
                          <option v-for="insurer in insurerArr" :key="insurer.id + 'insurer'" :value="insurer.id">
                            [{{ insurer.client_organ_code }}]
                            {{ insurer.client_organ_name }}
                          </option>
                        </select>
                      </div>
                      <div class="col-2">
                        保健指導区分
                        <select v-model="dataSearch.health_guidance_div" class="form-select">
                          <option :value="null"></option>
                          <option v-for="item in code_health_guidance_div" :key="item.text + 'item'" :value="item.value">
                            {{ item.text }}
                          </option>
                        </select>
                      </div>
                      <div class="col-2">
                        受付状況
                        <select v-model="dataSearch.reception" class="form-select">
                          <option :value="null"></option>
                          <option v-for="getCodeReception in getCodeReceptionArr" :key="getCodeReception.text + 'getCodeReception'
                    " v-show="getCodeReception.value != 30" :value="getCodeReception.value">
                            {{ getCodeReception.text }}
                          </option>
                        </select>
                      </div>
                      <div class="col-11 m-auto pl-0 mt-5">未受付条件</div>
                      <div class="col-11 m-auto border-top">
                        <div class="row">
                          <div class="col-4">
                            取込日
                            <div class="row align-items-center">
                              <div class="col-5 mt-0 pt-0">
                                <date-picker autocomplete="off" v-model="dataSearch.not_received_date_from" id="not_received_date_from"
                                             name="not_received_date_from" :config="options"></date-picker>
                              </div>
                              <div class="col-1 text-center pt-0">〜</div>
                              <div class="col-5 mt-0 pt-0">
                                <date-picker autocomplete="off" v-model="dataSearch.not_received_date_to" id="not_received_date_to"
                                             name="not_received_date_to" :config="options"></date-picker>
                              </div>
                              <span class="text-danger">{{ errors.not_received_date_to }}</span>
                            </div>
                          </div>
                          <div class="mt-8 col-4 form-check">
                            <label class="form-check-label" for="exampleCheck1">保健指導対象外のみ</label>
                            <input v-model="dataSearch.health_guidance_no_target" type="checkbox" class="form-check-input"
                                   id="exampleCheck1" />
                          </div>
                        </div>
                      </div>
                      <div class="col-11 m-auto pl-0">受付済条件</div>
                      <div class="col-11 mb-5 m-auto border-top">
                        <div class="row mb-5">
                          <div class="col-1">
                            受付年度
                            <date-picker autocomplete="off" v-model="dataSearch.received_year" id="year" name="year" :config="optionYear">
                            </date-picker>
                            <span class="text-danger">{{ errors.received_year }}</span>
                          </div>
                          <div class="col-2">
                            期
                            <select
                                outlined
                                small
                                id="period"
                                class="form-select"
                                v-model="dataSearch.health_guidance_period"
                            >
                              <option :key="period" :value="period" v-for="period in periodOptions">第{{period}}期</option>
                            </select>
                            <span class="text-danger">{{ errors.health_guidance_period }}</span>
                          </div>
                          <div class="col-4">
                            受付日
                            <div class="row align-items-center">
                              <div class="col-5 mt-0 pt-0">
                                <date-picker autocomplete="off" v-model="dataSearch.received_date_from" id="received_date_from"
                                             name="received_date_from" :config="options"></date-picker>
                              </div>
                              <div class="col-1 text-center pt-0">〜</div>
                              <div class="col-5 mt-0 pt-0">
                                <date-picker autocomplete="off" v-model="dataSearch.received_date_to" id="received_date_to"
                                             name="received_date_to" :config="options"></date-picker>
                              </div>
                              <span class="text-danger">{{ errors.received_date_to }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-7">
                            支援状況
                            <div class="row">
                              <div v-for="item in dataSupportStatus" :key="item.value + 'dataSupportStatus'"
                                   class="col-auto ml-3 form-check">
                                <label class="form-check-label" :for="item.value + 'dataSupportStatus'">{{
                                  item.text
                                  }}</label>
                                <input type="checkbox" checked class="form-check-input value_health_guide_status"
                                       :value="item.value" :id="item.value + 'dataSupportStatus'" />
                              </div>
                            </div>
                          </div>
                          <div class="col-5">
                            支援実施日
                            <div class="row align-items-center">
                              <div class="col-5 mt-0 pt-0">
                                <date-picker autocomplete="off" v-model="dataSearch.support_date_from" id="support_date_from"
                                             name="support_date_from" :config="options"></date-picker>
                              </div>
                              <div class="col-1 text-center pt-0">〜</div>
                              <div class="col-5 mt-0 pt-0">
                                <date-picker autocomplete="off" v-model="dataSearch.support_date_to" id="support_date_to"
                                             name="support_date_to" :config="options"></date-picker>
                              </div>
                              <span class="text-danger">{{ errors.support_date_to }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 pr-0 text-right">
                      <button type="button" @click="export_data" style="width: 10%;" class="col-2 p-1 mt-10 btn btn-primary">
                        <i class="fa fa-download" aria-hidden="true"></i>
                        出力
                      </button>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import moment from "moment";
import mixins from "@/mixins";
import ExportData from "@/services/api/ExportData";
import { SUCCESS } from "@/constants";
import { CODE_SUPPORT_STATUS, ENVS } from "@/common/constants";

export default {
    name: "export-data",
    mixins: [mixins],
    components: {},
    data() {
        return {
            dataSearch: {
                export_file: null,
                patient_name: null,
                patient_ticket: null,
                phone_number: null,
                insurer: null,
                health_guidance_div: null,
                reception: null,
                not_received_date_from: moment(new Date()).subtract(6, "months").format("YYYY-MM-DD"),
                not_received_date_to: moment(new Date()).format("YYYY-MM-DD"),
                health_guidance_no_target: false,
                health_guidance_period: this.getHealthGuidancePeriods(this.getFiscalYear()).slice(-1)[0],
                received_year: String(this.getFiscalYear()),
                received_date_from: moment(new Date()).subtract(1, "Y").format("YYYY-MM-DD"),
                received_date_to: moment(new Date()).format("YYYY-MM-DD"),
                support_date_from: moment(new Date()).subtract(3, "M").format("YYYY-MM-DD"),
                support_date_to: moment(new Date()).format("YYYY-MM-DD"),
            },
            errors: {
                patient_name: null,
                patient_ticket: null,
                phone_number: null,
                not_received_date_to: null,
                received_date_to: null,
                support_date_to: null,
                export_file: null,
            },
            code_health_guidance_div: [],
            insurerArr: [],
            getCodeReceptionArr: [],
            dataSupportStatus: [],
            rotateNavBar: false,
        };
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
            };
        },
        periodOptions() {
            return this.getHealthGuidancePeriods(this.dataSearch.received_year);
        },
    },
    async created() {
        await ExportData.getInsurer().then((res) => {
            this.insurerArr = res.data.data;
        });

        await ExportData.getMedicalIndication().then((res) => {
            this.code_health_guidance_div = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.code_health_guidance_div.push({
                    text: value,
                    value: key,
                });
            }
        });
        await ExportData.getCodeReception().then((res) => {
            this.getCodeReceptionArr = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.getCodeReceptionArr.push({
                    text: value,
                    value: key
                });
            }
        });
        await ExportData.getSupportStatus(CODE_SUPPORT_STATUS).then((res) => {
            this.dataSupportStatus = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.dataSupportStatus.push({
                    text: value,
                    value: key
                });
            }
        });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        export_data() {
            let api = null;
            switch (this.dataSearch.export_file) {
            case "phone-log":
                api = "log-phone-csv"
                break;
            case "support-status":
                api = "support-status-csv"
                break;
            case "object-information":
                api = "object-information-csv"
                break;
            case "support-rating-information":
                api = "support-rating-information-csv"
                break;
            default:
                this.$toast.open({
                    message: "出力データを選択してください。",
                    type: "error",
                });
                return;
            }

            this.dataSearch.support_flow_status = [];
            let value = document.getElementsByClassName("value_health_guide_status");
            for (let i = 0; i < value.length; i++) {
                if(value[i].checked){
                    this.dataSearch.support_flow_status.push(parseInt(value[i].value));
                }
            }

            var url = new URL(`${ENVS.API_URL_BASE}/api/export-data/${api}`);
            var search_params = url.searchParams;
            for (const [key, value] of Object.entries(this.dataSearch)) {
                if (value && key != "support_flow_status") {
                    search_params.set(key, value);
                }
            }
            search_params.set("support_flow_status", this.dataSearch.support_flow_status);
            search_params.set("isCheck", 0);
            url.search = search_params.toString();
            var new_url = url.toString();
            window.open(new_url);

            this.$toast.open({
                message: "データエクスポートを実施しました。",
                type: "info",
            });
        },

        clearAllSearch() {
            let value = document.getElementsByClassName("value_health_guide_status");
            this.dataSearch.support_flow_status = [];
            for (let i = 0; i < value.length; i++) {
                value[i].checked = true;
                this.dataSearch.support_flow_status.push(parseInt(value[i].value));
            }

            this.dataSearch = {
                patient_name: null,
                patient_ticket: null,
                phone_number: null,
                insurer: null,
                health_guidance_div: null,
                reception: null,
                not_received_date_from: moment(new Date()).subtract(6, "months").format("YYYY-MM-DD"),
                not_received_date_to: moment(new Date()).format("YYYY-MM-DD"),
                health_guidance_no_target: false,
                health_guidance_period: this.getHealthGuidancePeriods(this.getFiscalYear()).slice(-1)[0],
                received_year: String(this.getFiscalYear()),
                received_date_from: moment(new Date()).subtract(1, "Y").format("YYYY-MM-DD"),
                received_date_to: moment(new Date()).format("YYYY-MM-DD"),
                support_date_from: moment(new Date()).subtract(3, "M").format("YYYY-MM-DD"),
                support_date_to: moment(new Date()).format("YYYY-MM-DD"),
            };
            this.errors = {
                patient_name: null,
                patient_ticket: null,
                phone_number: null,
                not_received_date_to: null,
                received_date_to: null,
                support_date_to: null,
            };
        }
    },
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
</style>

