<template>
    <div class="p-2 list-data">
        <header class="align-items-center pb-3 d-flex flex-row-reverse">
            <button @click="getDetail(null, 'Add')" class="btn btn-primary"><i class="fas fa-plus"></i> 追加</button>
        </header>
        <div class="data-table data-table-container">
            <v-data-table
              :headers="headers"
              :items="lists"
              disable-pagination
              hide-default-footer
              class="data-table table table-responsive table-hover"
            >
              <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>
                            <span v-if="item.disable_flg">◯</span>
                        </td>
                        <td>
                            <span v-if="item.default_flag">◯</span>
                        </td>
                        <td>
                            <a @click="getDetail(item, 'Detail')" class="text-primary">
                                {{ item.organ_code }}
                            </a>
                        </td>
                        <td>{{ item.organ_name }}</td>
                        <td>{{ item.health_guidance_mngr_name }}</td>
                        <td>{{ item.organ_tel_01 }}</td>
                        <td>{{ item.organ_postal_code }}</td>
                        <td>{{ item.organ_addr_01 }} {{ item.organ_addr_02 }}</td>
                    </tr>
                </tbody>
              </template>
            </v-data-table>
        </div>
        <slide>
            <Detail v-if="component === 'Detail'" pageMode="EDIT" :dataDetail="itemDetail" :key="randomString" :isShowNavigation="randomString" @reload="reload"></Detail>
            <Detail v-if="component === 'Add'" pageMode="NEW" :dataDetail="null" :isShowNavigation="randomString" @reload="reload"></Detail>
        </slide>
    </div>
</template>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        &.default-flg {
            min-width: 110px !important;
        }
        &.postal-code {
            min-width: 100px !important;
        }
    }
</style>
<script>
import SpecificHealthGuidanceOrganizationsManagement from "@/services/api/SpecificHealthGuidanceOrganizationsManagement";
import { MESSAGE } from "@/common/constants";
import { SUCCESS } from "@/constants";
import { mapMutations } from "vuex";
import Detail from "@/pages/specific-health-guidance-organizations-management/hg-organ-detail";
import Slide from "@/components/Slide";

export default {
    name: "list",
    components: {
        Slide,
        Detail,
    },
    data() {
        return {
            headers: [
                { text: "無効", align: "start", sortable: false, value: "disable_flg" },
                { text: "デフォルト", sortable: false, value: "default_flag", class: "default-flg" },
                { text: "機関番号", sortable: false, value: "organ_code" },
                { text: "機関名称", sortable: false, value: "organ_name" },
                { text: "保健指導責任者", sortable: false, value: "health_guidance_mngr_name" },
                { text: "電話番号", sortable: false, value: "organ_tel_01" },
                { text: "郵便番号", sortable: false, value: "organ_postal_code", class: "postal-code" },
                { text: "住所", sortable: false, value: "organ_addr_01_organ_addr_02" },
            ],
            component: null,
            itemDetail: null,
            show: false,
            lists: [],
            dataDetail: [],
            messages: MESSAGE,
            randomString: "",
        };
    },
    created() {
        this.getLists();
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),

        getLists() {
            this.setIsLoading(true)
            SpecificHealthGuidanceOrganizationsManagement.getList(this.currentPage, this.perPage)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const { data, status, error } = res;
                    this.setIsLoading(false)
                    if (status !== SUCCESS) {
	                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                        return;
                    }
                    this.lists = data.data;
                    this.lists.map(x => {
                        x.organ_addr_01_organ_addr_02 = x.organ_addr_01 + x.organ_addr_02;
                    });
                })
        },
        getDetail(item, component) {
            this.itemDetail = item;
            this.component = component;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
        reload(param) {
            if (param) {
                this.component = null;
                this.getLists();
            }
        }
    }
}
</script>