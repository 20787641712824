<template>
  <div class="demo-list">
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 start ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 
    <div class="box-search mb-3">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="w-100 border border-top-0 rounded p-1 pr-2">
                  <div class="container-fluid py-1">

                    <div class="row">
                      <div class="col-12 p-0 m-0 text-right">
                        <button
                                @click="clearAllSearch"
                                type="button"
                                class="btn btn-grey">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          クリア
                        </button>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-2">
                        <span>保険者</span><span class="required"></span>
                        <select v-model="dataSearch.insurer" class="form-select" id="insurer" name="insurer" @change="validate('insurer')">
                          <option :value="null"></option>
                          <option
                                  v-for="insurer in insurers"
                                  :key="insurer.id + 'insurer'"
                                  :value="insurer.client_organ_code"
                          >
                            [{{ insurer.client_organ_code }}]
                            {{ insurer.client_organ_name }}
                          </option>
                        </select>
                        <span class="text-danger">{{ errorsSearch.insurer }}</span>
                      </div>
                      <div class="col-2">
                        <span>受付年度</span><span class="required"></span>
                          <date-picker
                            autocomplete="off"
                            v-model="dataSearch.fiscal_year"
                            id="fiscal_year"
                            name="fiscal_year"
                            :config="optionYear"
                            @input="validate('fiscal_year')">
                          </date-picker>
                          <span class="text-danger">{{errorsSearch.fiscal_year}}</span>
                      </div>
                      <div class="col-4">
                        <span>登録日</span><span class="required"></span>
                        <div class="container-fluid m-0 p-0">
                          <div class="row">
                            <div class="col-5 py-0">
                              <date-picker
                                autocomplete="off"
                                @input="validate('created_at_date_from');validate('created_at_date_to')"
                                v-model="dataSearch.created_at_date_from"
                                id="created_at_date_from"
                                name="created_at_date_from"
                                :config="optionDate"></date-picker>
                                <span class="text-danger">{{errorsSearch.created_at_date_from}}</span>
                            </div>
                            <div class="col-auto px-0">
                              <span>〜</span>
                            </div>
                            <div class="col-5 py-0">
                              <date-picker
                                autocomplete="off"
                                @input="validate('created_at_date_to')"
                                v-model="dataSearch.created_at_date_to"
                                id="created_at_date_to"
                                name="created_at_date_to"
                                :config="optionDate"></date-picker>
                                <span class="text-danger">{{errorsSearch.created_at_date_to}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <span>紐付け状況</span>
                        <select v-model="dataSearch.contact_association_status" class="form-select">
                          <option :value="null"></option>
                          <option :value="false">未紐付け</option>
                          <option :value="true">紐付済み</option>
                        </select>
                        <span class="text-danger">{{errorsSearch.contact_association_status}}</span>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col-12 pr-0 pt-0 text-right">
                        <button
                                @click="search(); currentPage = 1"
                                type="button"
                                class="btn btn-success mt-0"
                        >
                          <i class="fa fa-search" aria-hidden="true"></i>
                          検索
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 end ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="data-table-container h-100">
      <v-data-table
        :headers="listHeaders"
        :items="listItems"
        :fixed-header="true"
        :height="'100%'"
        disable-pagination
        hide-default-footer
        class="data-table table table-responsive table-hover"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="'list-item-' + item.scheduling_contact_id">
              <!-- 編集ボタン -->
              <td class="text-nowrap pl-1 pr-0">
                <span v-if="item.patient_associated"></span>
                <a v-else @click="showSchedulingContactEditDialog(item)" class="text-primary">
                  <i class="fa fa-pencil-square text-primary fa-2x" aria-hidden="true"></i>
                </a>
              </td>
              <!-- 連絡先ID -->
              <td class="text-nowrap">
                {{ item.scheduling_contact_id }}
              </td>
              <!-- 紐付け -->
              <td class="text-nowrap">
                {{ (item.patient_associated ? '済み' : '未') }}
              </td>
              <!-- 紐付け対象者ID -->
              <td class="text-nowrap">
                <a v-if="item.patient_associated" @click="userDetail(item.patient_id, null, 'Detail')" class="text-primary">
                  {{ item.patient_id_format }}
                </a>
              </td>
              <!-- 保険者 -->
              <td>
                {{ formatInsurerText(item) }}
              </td>
              <!-- 受付年度 -->
              <td class="text-nowrap">
                {{ formatFiscalYear(item.fiscal_year) }}
              </td>
              <!-- メールアドレス -->
              <td class="text-nowrap">
                {{ item.patient_mail_01 }}
              </td>
              <!-- 氏名カナ -->
              <td class="text-nowrap">
                {{ item.patient_name_kana }}
              </td>
              <!-- 生年月日 -->
              <td class="text-nowrap">
                {{ item.patient_birthday }}
              </td>
              <!-- 連絡先(携帯) -->
              <td class="text-nowrap">
                {{ item.patient_tel_01 }}
              </td>
              <!-- 連絡先(自宅) -->
              <td class="text-nowrap">
                {{ item.patient_tel_02 }}
              </td>
              <!-- 連絡先(職場) -->
              <td class="text-nowrap">
                {{ item.patient_tel_03 }}
              </td>
              <!-- 連絡先(その他) -->
              <td class="text-nowrap">
                {{ item.patient_tel_04 }}
              </td>
              <!-- 登録日時 -->
              <td class="text-nowrap">
                {{ formatDatetime(item.created_at) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <Pagination
      :total-page="totalPage"
      :current-page="currentPage"
      @onHandleChangePage="onHandleChangePage"
    />
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 右スライドパネル start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <slide>
      <Detail
        v-if="component === 'Detail'"
        :dataDetailId="showDetailTargetPatientId"
        :checkViewOrEdit="false"
        :isShowNavigation="randomString"></Detail>
    </slide>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 右スライドパネル end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

      <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● ダイアログ start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
      <Dialog :maxWidth="600" :isShow="dialog.showConfirmDialog">
      <v-card>
        <v-container>
          <v-card-title> 確認 </v-card-title>
          <div class="m-3">{{ dialog.procName }}処理を実行します。よろしいですか？</div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="dialog.showConfirmDialog = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="dialog.procFunc(editTargetSchedulingContactItem), dialog.showConfirmDialog = false">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <Dialog :maxWidth="450" :isShow="dialog.showSchedulingContactEditDialog">
        <v-card>
        <v-container v-if="editTargetSchedulingContactItem">
            <v-card-title>
              事前登録連絡先
            </v-card-title>
            <v-card-actions class="text-right">
              <v-spacer></v-spacer>
              <button @click="prepareDeleteSchedulingContact(editTargetSchedulingContactItem)" type="button" class="btn btn-danger">
                  削除
              </button>
            </v-card-actions>
            <div class="col-12">
                <label class="form-check-label" for="edit_target_sche_contact_scheduling_contact_id">連絡先ID</label>
                <input 
                  v-model="editTargetSchedulingContactItem.scheduling_contact_id" 
                  type="text" 
                  disabled 
                  class="form-control" 
                  id="edit_target_sche_contact_scheduling_contact_id" />
            </div>
            <div class="col-12">
                <label class="form-check-label required" for="edit_target_sche_contact_patient_name_kana">氏名カナ</label>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  id="edit_target_sche_contact_patient_name_kana"
                  @input="validateEditTargetSchedulingContactItem('patient_name_kana')"
                  v-model="editTargetSchedulingContactItem.patient_name_kana"
                />
                <span class="text-danger">{{ schedulingContactEditError.patient_name_kana }}</span>
            </div>
            <div class="col-12">
                <label class="form-check-label required" for="edit_target_sche_contact_patient_birthday">生年月日</label>
                <date-picker
                    autocomplete="off"
                    @input="validateEditTargetSchedulingContactItem('patient_birthday')"
                    v-model="editTargetSchedulingContactItem.patient_birthday"
                    id="edit_target_sche_contact_patient_birthday"
                    name="edit_target_sche_contact_patient_birthday"
                    class="form-control"
                    :config="optionDate">
                </date-picker>
                <span class="text-danger">{{ schedulingContactEditError.patient_birthday }}</span>
            </div>
            <div style="min-height:400px !important; height:400px !important;"></div>
            <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
              <button type="button" class="btn btn-secondary" @click="dialog.showSchedulingContactEditDialog = false">
                  キャンセル
              </button>
              <button @click="prepareUpdateSchedulingContact(editTargetSchedulingContactItem)" type="button" class="btn btn-primary">
                  変更
              </button>
            </v-card-actions>
        </v-container>
        </v-card>
    </Dialog>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● ダイアログ end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
  </div>
</template>

<script>

import Slide from "@/components/Slide";
import Detail from "@/components/DetailImportDiagnosticData";
import Pagination from "@/components/common/Pagination";
import { mapMutations, mapGetters } from "vuex";
import { SUCCESS } from "@/constants";
import moment from "moment";
import { 
    schedulingContactSchemaValidation as schedulingSchemaValidation,
    schedulingContactEditSchemaValidation as schedulingSchemaEditValidation,
} from "../../common/schemaValidation";
import { CODE_GENDER, CODE_RECEPTION, CODE_HEALTH_GUIDANCE_DIV, TOAST_TIME_OUT} from "@/common/constants";
import mixins from "@/mixins";
import SchedulingApiService from "@/services/api/SchedulingApiService";
import Dialog from "@/components/common/Dialog";

export default {
    name: "scheduling-contact-list",
    mixins: [mixins],

    components: {
        Pagination,
        Slide,
        Detail,
        Dialog,
    },

    data() {
        return {
            insurers: [],
            healthGuideReceptionDivCodes: [],

            dialog: {
                /** trueの場合、確認ダイアログ表示 */
                showConfirmDialog: false,
                /** trueの場合、日程調整編集ダイアログ表示 */
                showSchedulingContactEditDialog: false,
                /** 処理名 */
                procName: null,
                /** 処理関数 */
                procFunc: null,
            },

            /** 編集対象 日程調整連絡先 */
            editTargetSchedulingContactItem: null,
            /** 日程調整連絡先 編集エラー */
            schedulingContactEditError: null,

            dataSearch: {
                insurer: null,
                fiscal_year: String(this.getFiscalYear()),
                created_at_date_from: moment(new Date()).subtract(3, "months").format("YYYY-MM-DD"),
                created_at_date_to: moment(new Date()).format("YYYY-MM-DD"),
                contact_association_status: false,
            },
            errorsSearch: {
                insurer: null,
                fiscal_year: null,
                created_at_date_from: null,
                created_at_date_to: null,
                contact_association_status: null,
            },

            totalPage: 1,
            currentPage: 1,
            perPage: 150,
            listItems: [],
            listHeaders: [
                {
                    text: "",
                    sortable: false,
                    value: "scheduling_contact_edit",
                    class: "text-nowrap",
                },
                {
                    text: "連絡先ID",
                    sortable: true,
                    value: "scheduling_contact_id",
                    class: "text-nowrap",
                },
                { 
                    text: "紐付け", 
                    sortable: true, 
                    value: "patient_associated", 
                    class: "text-nowrap" 
                },
                {
                    text: "紐付け対象者ID",
                    sortable: true,
                    value: "patient_id",
                    class: "text-nowrap",
                },
                { 
                    text: "保険者", 
                    sortable: true, 
                    value: "insurer", 
                    class: "text-nowrap",
                    width: "280px",
                },
                { 
                    text: "受付年度", 
                    sortable: true, 
                    value: "fiscal_year", 
                    class: "text-nowrap" 
                },
                {
                    text: "登録メールアドレス",
                    sortable: true,
                    value: "patient_mail_01",
                    class: "text-nowrap",
                },
                {
                    text: "氏名(カナ)",
                    sortable: true,
                    value: "patient_name_kana",
                    class: "text-nowrap",
                },
                {
                    text: "生年月日",
                    sortable: true,
                    value: "patient_birthday",
                    class: "text-nowrap",
                },
                {
                    text: "連絡先(携帯)",
                    sortable: true,
                    value: "patient_tel_01",
                    class: "text-nowrap",
                },
                {
                    text: "連絡先(自宅)",
                    sortable: true,
                    value: "patient_tel_02",
                    class: "text-nowrap",
                },
                {
                    text: "連絡先(職場)",
                    sortable: true,
                    value: "patient_tel_03",
                    class: "text-nowrap",
                },
                {
                    text: "連絡先(その他)",
                    sortable: true,
                    value: "patient_tel_04",
                    class: "text-nowrap",
                },
                {
                    text: "登録日時",
                    sortable: true,
                    value: "created_at",
                    class: "text-nowrap",
                },
            ],
            listErrors: [],
            rotateNavBar: false,

            component: undefined,
            showDetailTargetPatientId: undefined,
            randomString: "",
        }
    },

    async created() {

        // 保険者 取得.
        await SchedulingApiService.getInsurer().then((res) => {
            this.insurers = [];
            this.insurers = res.data.data;
        });

        // コード定義 取得.
        await SchedulingApiService.getCodeDefineGroups([CODE_RECEPTION]).then((res) => {
            const codeDefineGroups = res.data;

            // 保健指導受付区分
            const healthGuideReceptionDivCodeGroup = codeDefineGroups[CODE_RECEPTION];
            this.healthGuideReceptionDivCodes = healthGuideReceptionDivCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                        "text_short": item["acronym"],
                    };
                })
                .filter(function(item) {
                    return item["code"] != "30";
                });
        });
    },

    computed: {
        /**
         * 年入力オプション
         */
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2099-12-30",
                locale: moment.locale("ja"),
            };
        },
        /**
         * 日付入力オプション
         */
        optionDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "1900-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            };
        },
        ...mapGetters({
            user: "common/getUser",
        }),
    },

    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * 
         * @param {*} date 
         */
        formatDatetime(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },

        /**
         * 
         */
        formatFiscalYear(fiscalYear) {
            if (fiscalYear) {
                return String(fiscalYear).slice(-2) + "年度";
            }
            return null;
        },

        /**
         * コード定義取得 保健指導受付区分.
         * @param {*} healthGuidanceReceptionDiv 
         */
        getCodeDef_HealthGuidanceReceptionDiv(healthGuidanceReceptionDiv) {
            if (healthGuidanceReceptionDiv) {
                let data = this.healthGuideReceptionDivCodes.filter((x) => x.code == healthGuidanceReceptionDiv);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * 
         * @param {*} item 
         */
        formatInsurerText(item) {
            return `[${item.insurer_code}] ${item.insurer_name}`;
        },

        /**
         * 検索クリア.
         */
        clearAllSearch() {

            this.clearSearchErrorMessage();
            this.dataSearch = {
                insurer: null,
                fiscal_year: String(this.getFiscalYear()),
                created_at_date_from: moment(new Date()).subtract(3, "months").format("YYYY-MM-DD"),
                created_at_date_to: moment(new Date()).format("YYYY-MM-DD"),
                contact_association_status: false,
            };
        },

        /**
         * 検索エラーメッセージクリア.
         */
        clearSearchErrorMessage() {
            this.errorsSearch = {
                insurer: null,
                fiscal_year: null,
                created_at_date_from: null,
                created_at_date_to: null,
                contact_association_status: null,
            };
        },

        /**
         * バリデーション.
         * @param {*} field 
        */
        validate(field) {
            schedulingSchemaValidation
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errorsSearch[field] = "";
                })
                .catch((err) => {
                    this.errorsSearch[field] = err.message;
                });
        },

        /**
         * バリデーション.
         * @param {*} field 
        */
        validateEditTargetSchedulingContactItem(field) {
            schedulingSchemaEditValidation
                .validateAt(field, this.editTargetSchedulingContactItem)
                .then(() => {
                    this.schedulingContactEditError[field] = "";
                })
                .catch((err) => {
                    this.schedulingContactEditError[field] = err.message;
                });
        },

        /**
         * 
         */
        search() {
            this.totalPage = 1;
            this.currentPage = 1;
            this.getList();
        },

        /**
         * 一覧取得.
        */
        getList() {
            schedulingSchemaValidation
                .validate(this.dataSearch, { abortEarly: false })
                .then(() => {
                   
                    this.listItems = [];
                    this.clearSearchErrorMessage();
                    this.setIsLoading(true);
                    this.dataSearch.fiscal_year = moment(this.dataSearch.fiscal_year).format("YYYY");
                    
                    SchedulingApiService.getSchedulingContactList(this.dataSearch, this.currentPage, this.perPage)
                        .then((res) => {
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0,
                            });

                            const { data, status, error } = res;
                            this.setIsLoading(false);

                            if (status !== SUCCESS) {
                                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                                return;
                            }

                            this.listItems = data.data;
                            this.listItems.forEach(listItem => {
                                if (listItem.patient_id) {
                                    listItem.patient_id_format = String(listItem.patient_id).padStart(10, "0");
                                }
                            });

                            $(".demo-list").closest(".container-fluid").css("height", this.listItems.length ? "100%" : "auto");

                            this.totalPage = data.last_page;
                            this.currentPage = data.current_page;

                            if (this.listItems.length < 1) {
                                this.notifyNoSearchResults();
                            }
                        }).catch((err) => {
                            this.setIsLoading(false);
                            this.$toast.error(`${err}`, TOAST_TIME_OUT);
                            return;
                        });
                })
                .catch((err) => {
                    this.setIsLoading(false);
                    this.$toast.error(`${err}`, TOAST_TIME_OUT);
                    this.notifyInputInvalidToast();
                    return;
                });
        },

        /**
         * 連絡先 編集ダイアログ表示
         */
        showSchedulingContactEditDialog(item){
            this.dialog.showConfirmDialog = false;
            this.dialog.showSchedulingContactEditDialog = false;
            this.dialog.procName = null;
            this.dialog.procFunc = null;
            this.editTargetSchedulingContactItem = null;
            this.schedulingContactEditError = {
                patient_name_kana: null,
                patient_birthday: null,
            };
            
            if (!item) return;
           
            this.dialog.showSchedulingContactEditDialog = true;
            this.editTargetSchedulingContactItem = {
                scheduling_contact_id: item.scheduling_contact_id,
                patient_name_kana: item.patient_name_kana,
                patient_birthday: item.patient_birthday,
            };
        },

        /**
         * 連絡先 変更保存 準備
         */
        prepareUpdateSchedulingContact(item) {
            // 確認ダイアログ表示前にバリデーション
            schedulingSchemaEditValidation
                .validate(item, { abortEarly: false })
                .then(() => {
                    this.dialog.procName = "連絡先変更";
                    this.dialog.procFunc = this.updateSchedulingContact;
                    this.dialog.showConfirmDialog = true;
                });
        },

        /**
         * 連絡先 変更保存
         */
        updateSchedulingContact(item) {
            schedulingSchemaEditValidation
                .validate(item, { abortEarly: false })
                .then(() => {
                    try {
                        // マスク.
                        this.setIsLoading(true);

                        // ポストデータ生成.
                        const postData = {
                            scheduling_contact_id: item.scheduling_contact_id,
                            patient_name_kana: item.patient_name_kana,
                            patient_birthday: item.patient_birthday,
                        };

                        // APIコール.
                        SchedulingApiService.updateSchedulingContact(postData)
                            .then((res) => {
                                const { data, status, error } = res;
                                this.setIsLoading(false);

                                if (status !== SUCCESS) {
                                    this.notifyError(`[${error?.messageID}] ${error?.message}`);
                                    return;
                                }

                                this.notifySuccess("連絡先変更が完了しました。");

                                this.dialog.showSchedulingContactEditDialog = false;
                                this.getList();
                            })
                            .catch((err) => {
                                // ローディング状態解除.
                                this.setIsLoading(false);
                                this.notifyError(`${err}`);
                            });

                    } catch(err) {
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        this.notifyError(`${err}`);
                    }
                });
        },

        /**
         * 連絡先 削除 準備
         */
        prepareDeleteSchedulingContact(item) {
            this.dialog.procName = "連絡先削除";
            this.dialog.procFunc = this.deleteSchedulingContact;
            this.dialog.showConfirmDialog = true;
        },

        /**
         * 連絡先 削除
         */
        deleteSchedulingContact(item) {
            try {
                // マスク.
                this.setIsLoading(true);

                // ポストデータ生成.
                const postData = {
                    scheduling_contact_id: item.scheduling_contact_id,
                };

                // APIコール.
                SchedulingApiService.deleteSchedulingContact(postData)
                    .then((res) => {
                        const { data, status, error } = res;
                        this.setIsLoading(false);

                        if (status !== SUCCESS) {
                            this.notifyError(`[${error?.messageID}] ${error?.message}`);
                            return;
                        }

                        this.notifySuccess("連絡先削除が完了しました。");

                        this.dialog.showSchedulingContactEditDialog = false;
                        this.getList();
                    })
                    .catch((err) => {
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        this.notifyError(`${err}`);
                    });

            } catch(err) {
                // ローディング状態解除.
                this.setIsLoading(false);
                this.notifyError(`${err}`);
            }
        },

        /**
         * 
         * @param {*} pageNumber 
         */
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getList();
        },

        /**
         * 対象者詳細
         * @param {*} id 
         * @param {*} health_guidance_reception_id 
         * @param {*} type 
         */
        userDetail(id, health_guidance_reception_id, type) {
            this.showDetailTargetPatientId = id;
            //this.dataReceptionsId = health_guidance_reception_id;
            this.component = type;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
    },

    /**
     * 
     */
    destroyed() {
        $(document).unbind()
    }
}

</script>

<style lang="sass">
@import "../../styles/common"
</style>
<style lang="scss" scoped>
  .wrapper .main .content .container-fluid .data-table-container {
    padding: 0;
    .data-table {
      margin: 0;
    }
  }
  .container-pagination {
    margin: 0px !important;
    height: 60px !important;
    max-height: 60px !important;
  }
</style>