<template>
  <div class="demo-list">
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 start ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 
    <div class="box-search mb-3">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="w-100 border border-top-0 rounded p-1 pr-2">
                  <div class="container-fluid py-1">

                    <div class="row">
                      <div class="col-12 p-0 m-0 text-right">
                        <button
                                @click="clearAllSearch"
                                type="button"
                                class="btn btn-grey">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          クリア
                        </button>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-2">
                        <span>保険者</span><span class="required"></span>
                        <select v-model="dataSearch.insurer" class="form-select" id="insurer" name="insurer" @change="validate('insurer')">
                          <option :value="null"></option>
                          <option
                                  v-for="insurer in insurers"
                                  :key="insurer.id + 'insurer'"
                                  :value="insurer.client_organ_code"
                          >
                            [{{ insurer.client_organ_code }}]
                            {{ insurer.client_organ_name }}
                          </option>
                        </select>
                        <span class="text-danger">{{ errorsSearch.insurer }}</span>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-2">
                        <span>氏名</span>
                        <input
                          id="patient_name"
                          name="patient_name"
                          type="text"
                          class="form-control"
                          placeholder="氏名 or 氏名カナ"
                          autocomplete="off"
                          v-model="dataSearch.patient_name"
                          @keyup="validate('patient_name')"
                        />
                        <span class="text-danger">{{ errorsSearch.patient_name }}</span>
                      </div>
                      <div class="col-2">
                        <span>利用券整理番号</span>
                        <input
                          id="patient_ticket"
                          name="patient_ticket"
                          v-model="dataSearch.patient_ticket"
                          type="text"
                          class="form-control"
                          placeholder=""
                          autocomplete="off"
                          @keyup="validate('patient_ticket')"
                        />
                        <span class="text-danger">{{errorsSearch.patient_ticket}}</span>
                      </div>
                      <div class="col-2">
                        <span>保健指導区分</span>
                        <select v-model="dataSearch.health_guide_div_code" class="form-select">
                          <option :value="null"></option>
                          <option
                            v-for="item in healthGuideDivCodes"
                              :key="'key_' + item.id"
                              :value="item.code">
                              {{ item.text }}
                          </option>
                        </select>
                        <span class="text-danger">{{errorsSearch.health_guide_div_code}}</span>
                      </div>
                      <div class="col-2">
                        <span>受付状況</span>
                        <select v-model="dataSearch.health_guide_reception_div_code" class="form-select">
                          <option :value="null"></option>
                          <option
                            v-for="item in healthGuideReceptionDivCodes"
                              :key="'key_' + item.id"
                              :value="item.code">
                              {{ item.text }}
                          </option>
                        </select>
                        <span class="text-danger">{{errorsSearch.health_guide_reception_div_code}}</span>
                      </div>
                      <div class="col-2">
                        <span>日程調整 依頼状況</span>
                        <select v-model="dataSearch.scheduling_request_status" class="form-select">
                          <option :value="null"></option>
                          <option :value="false">依頼 未連絡</option>
                          <option :value="true">依頼 連絡済み</option>
                        </select>
                        <span class="text-danger">{{errorsSearch.scheduling_request_status}}</span>
                      </div>
                      <div class="col-2">
                        <span>日程調整結果</span>
                        <select v-model="dataSearch.scheduling_result_status" class="form-select">
                          <option :value="null"></option>
                          <option
                            v-for="item in schedulingResultStatusCodes"
                              :key="'key_' + item.id"
                              :value="item.code">
                              {{ item.text }}
                          </option>
                        </select>
                        <span class="text-danger">{{errorsSearch.scheduling_result_status}}</span>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col-4">
                        <span>調整結果 取込日</span><span class="required"></span>
                        <div class="container-fluid m-0 p-0">
                          <div class="row">
                            <div class="col-5 py-0">
                              <date-picker
                                autocomplete="off"
                                @input="validate('scheduling_result_date_from');validate('scheduling_result_date_to')"
                                v-model="dataSearch.scheduling_result_date_from"
                                id="scheduling_result_date_from"
                                name="scheduling_result_date_from"
                                :config="optionDate"></date-picker>
                                <span class="text-danger">{{errorsSearch.scheduling_result_date_from}}</span>
                            </div>
                            <div class="col-auto px-0">
                              <span>〜</span>
                            </div>
                            <div class="col-5 py-0">
                              <date-picker
                                autocomplete="off"
                                @input="validate('scheduling_result_date_to')"
                                v-model="dataSearch.scheduling_result_date_to"
                                id="scheduling_result_date_to"
                                name="scheduling_result_date_to"
                                :config="optionDate"></date-picker>
                                <span class="text-danger">{{errorsSearch.scheduling_result_date_to}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-2 mt-3">
                        <div class="mb-1">
                          <input
                            class="form-check-input"
                            v-model="dataSearch.has_to_mail"
                            checked
                            type="checkbox"
                            id="has_to_mail"
                          />
                          <label class="ml-1 form-check-label h6" for="has_to_mail">
                            送信先メール登録済
                          </label>
                        </div>
                        <div>
                          <input
                            class="form-check-input"
                            v-model="dataSearch.scheduling_unfixed"
                            checked
                            type="checkbox"
                            id="scheduling_unfixed"
                          />
                          <label class="ml-1 form-check-label h6" for="scheduling_unfixed">
                            予約未確定
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col">
                        <div class="container-fluid py-0">
                          <div class="row">
                            <div class="col-auto pb-0 pl-0">
                              <span>受付済条件</span>
                            </div>
                            <hr class="m-0" />
                            <div class="col-2 pl-4 pb-0">
                              <span>受付年度</span><span class="required"></span>
                              <date-picker
                                autocomplete="off"
                                v-model="dataSearch.fiscal_year"
                                id="fiscal_year"
                                name="fiscal_year"
                                :config="optionYear"
                                @input="validate('fiscal_year')">
                              </date-picker>
                              <span class="text-danger">{{errorsSearch.fiscal_year}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-12 pr-0 pt-0 text-right">
                        <button
                                @click="search(); currentPage = 1"
                                type="button"
                                class="btn btn-success mt-0"
                        >
                          <i class="fa fa-search" aria-hidden="true"></i>
                          検索
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 end ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧エラーメッセージ領域 start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="mb-2" id="list-item-error-msg" v-if="0 < listErrors.length">
      <ul>
        <li v-for="(errorMsg, index) in listErrors" v-bind:key="'list-item-error-' + index">
            {{ errorMsg  }}
        </li>
      </ul>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧エラーメッセージ領域 end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="data-table-container h-100">
      <v-data-table
        :headers="listHeaders"
        :items="listItems"
        :fixed-header="true"
        :height="'100%'"
        disable-pagination
        hide-default-footer
        class="data-table table table-responsive table-hover"
      >

        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="'list-item-' + item.patient_id">
              <!-- チェックボックス -->
              <td>
                <input type="checkbox" 
                  :id="'list-item-checkbox-' + item.patient_id" v-model="item.selected" class="scheduling-list-checkbox"
                  v-if="item.has_contact" />
              </td>
              <!-- 対象者ID -->
              <td class="text-nowrap">
                <a @click="userDetail(item.patient_id, null, 'Detail')" class="text-primary">
                  {{ item.patient_id_format }}
                </a>
              </td>
              <!-- 氏名カナ -->
              <td class="text-nowrap">
                {{ item.patient_name_kana }}
              </td>
              <!-- 生年月日/性別 -->
              <td class="text-nowrap">
                {{ item.patient_birthday }} /
                {{ getCodeDef_Gender(item.patient_gender)?.text_short }}
              </td>
              <!-- 利用券整理番号 -->
              <td class="text-nowrap">
                {{ item.use_ticket_ref_number }}
              </td>
              <!-- 保健指導区分 -->
              <td class="text-nowrap">
                {{ getCodeDef_HealthGuidanceDiv(item.health_guidance_div)?.text }}
              </td>
               <!-- 保険者 -->
              <td>
                {{ formatInsurerText(item) }}
              </td>
              <!-- 受付 -->
              <td class="text-nowrap">
                {{ (item.has_reception ? '済み' : '未') }}
              </td>
              <!-- 受付年度 -->
              <td class="text-nowrap">
                {{ formatFiscalYear(item.reception_fiscal_year) }}
              </td>
              <!-- 初回面談予定 -->
              <td class="text-nowrap">
                {{ formatYYYYMMDD_HHMM(item.scheduled_first_support_datetime) }}
              </td>
              <!-- 送信先メールアドレス -->
              <td class="text-nowrap">
                {{ item.patient_mail_01 }}
              </td>
              <!-- 調整依頼 -->
              <td class="text-nowrap">
                {{ (item.scheduling_requested ? '済み' : '未') }}
              </td>
              <!-- 依頼回数 -->
              <td class="text-nowrap">
                {{ item.scheduling_request_count }}
              </td>
              <!-- 依頼日時 -->
              <td class="text-nowrap">
                {{ formatDatetime(item.scheduling_request_datetime) }}
              </td>
              <!-- 日程調整結果ステータス -->
              <td class="text-nowrap">
                {{ getCodeDef_SchedulingResultStatus(item.scheduling_result_status)?.text }}
                
              </td>
              <!-- 調整結果取込日時 -->
              <td class="text-nowrap">
                {{ formatDatetime(item.scheduling_result_import_datetime) }}
              </td>
              <!-- 面談 参加者 -->
              <td class="text-nowrap">
                {{ item.meeting_attendee_email }}
              </td>
              <!-- 日程調整受付ページ -->
              <td class="text-nowrap">
                {{ item.scheduling_reception_page_name ? (item.scheduling_reception_page_name + " [" + item.scheduling_reception_page_url + "]") : "" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <Pagination
      :total-page="totalPage"
      :current-page="currentPage"
      @onHandleChangePage="onHandleChangePage"
    />
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->


    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● フッタ start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="mt-5 pb-3 text-right">
      
      <div class="container-fluid">
        <div class="row justify-content-end">
          <div class="col-auto tet-right px-0 pt-5 mr-2">
              <span>日程調整受付ページ</span><span class="required"></span>
          </div>
          <div v-if="eeasyLoadEnable" class="col-auto px-0 pt-4">
              <button @click="dialog.isImportConfirm = true" type="button" class="btn btn-secondary btn-sm">取込</button>
          </div>
          <div class="col-6 ml-0">
            <select v-model="selectedReceptionPage" class="form-select">
              <option :value="null"></option>
              <option
                  v-for="(item, index) in receptionPages"
                  :key="'receptionPage_' + index"
                  :value="item.page_url">
                  {{ formatReceptionPageText(item) }}
              </option>
            </select>
          </div>
          <div class="ml-3 col-auto">
            <button @click="dialog.isShowConfirm = true" type="button" class="btn btn-primary pl-5 pr-5">
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
              日程調整依頼 送信
            </button>
          </div>
        </div>
      </div>

    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● フッタ end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 右スライドパネル start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <slide>
      <Detail
        v-if="component === 'Detail'"
        :dataDetailId="showDetailTargetPatientId"
        :checkViewOrEdit="false"
        :isShowNavigation="randomString"></Detail>
    </slide>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 右スライドパネル end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->


    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● ダイアログ start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <Dialog :maxWidth="850" :isShow="dialog.isShowConfirm">
      <v-card>
        <v-container>
          <v-card-title> 確認 </v-card-title>
          <div class="m-3">
            <div>
              以下の内容で、選択行の対象者へ日程調整メールを送信します。よろしいですか？
            </div>
            <div class="pl-5 py-3">
              <div>
                <span>{{ "保険者：" + formatClientOrganText(getInsurerByCode(searchedCondition.insurer_code)) }}</span>
              </div>
              <div>
                <span>{{ "受付年度：" + formatFiscalYear(searchedCondition.fiscal_year)  }}</span>
              </div>
              <div>
                <span>{{ "日程調整受付ページ：" + formatReceptionPageText(getReceptionPage(selectedReceptionPage)) }}</span>
              </div>
            </div>
            <div class="overflow-auto" style="height:500px;">
              <ul class="list-group">
                <li v-for="(item, index) in getSelectedListItems()" :key="'selected-list-item-' + index" class="list-group-item">
                    <span class="mr-5">{{ "[" + item.patient_id_format + "] " + item.patient_name_kana  }}</span>
                    <span class="mr-5">{{ "利用券：" + item.use_ticket_ref_number }}</span>
                    <span>{{ "メール：" + item.patient_mail_01 }}</span>
                </li>
              </ul>
            </div>
          </div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="dialog.isShowConfirm = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="send(), dialog.isShowConfirm = false">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <Dialog :maxWidth="600" :isShow="dialog.isImportConfirm">
      <v-card>
        <v-container>
          <v-card-title> 確認 </v-card-title>
          <div class="m-3">Eeasyから日程調整 予約受付ページの取込処理を実行します。よろしいですか？</div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="dialog.isImportConfirm = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="importReceptionPages(), dialog.isImportConfirm = false">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● ダイアログ end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

  </div>
</template>

<script>

import Slide from "@/components/Slide";
import Detail from "@/components/DetailImportDiagnosticData";
import Dialog from "@/components/common/Dialog";
import Pagination from "@/components/common/Pagination";
import { mapMutations, mapGetters } from "vuex";
import { SUCCESS } from "@/constants";
import moment from "moment";
import { schedulingSchemaValidation } from "../../common/schemaValidation";
import { 
    CODE_GENDER, CODE_RECEPTION, 
    CODE_HEALTH_GUIDANCE_DIV,
    CODE_SCHEDULING_RESULT_STATUS,
    TOAST_TIME_OUT,
    SYS_SETTING_KEY_EEASY_SYSTEM_PUBLIC_KEY,
    SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL,
    SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_QUERY,
    ENVS,
} from "@/common/constants";
import mixins from "@/mixins";

import SchedulingApiService from "@/services/api/SchedulingApiService";

export default {
    name: "scheduling-list",
    mixins: [mixins],

    components: {
        Pagination,
        Slide,
        Detail,
        Dialog,
    },

    head: {
        script: [
            {
                type: "text/javascript",
                src: ENVS.EEASY_LIB_URL,
                async: false
            }
        ]
    },

    data() {
        return {
            insurers: [],
            healthGuideReceptionDivCodes: [],
            healthGuideDivCodes: [],
            genderCodes: [],
            schedulingResultStatusCodes: [],
            receptionPages: [],
            systemSettings: null,

            dataSearch: {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                fiscal_year: String(this.getFiscalYear()),
                scheduling_request_status: null,
                scheduling_result_status: null,
                scheduling_result_date_from: moment(new Date()).subtract(3, "months").format("YYYY-MM-DD"),
                scheduling_result_date_to: moment(new Date()).format("YYYY-MM-DD"),
                has_to_mail: true,
                scheduling_unfixed: false,
            },
            errorsSearch: {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                fiscal_year: null,
                scheduling_request_status: null,
                scheduling_result_status: null,
                scheduling_result_date_from: null,
                scheduling_result_date_to: null,
            },

            searchedCondition: {
                insurer_code: null,
                fiscal_year: null,
            },

            totalPage: 1,
            currentPage: 1,
            perPage: 30,
            listItems: [],
            listHeaders: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "checkbox",
                    class: "text-nowrap scheduling-list-checkbox-column",
                },
                {
                    text: "対象者ID",
                    sortable: false,
                    value: "patient_id",
                    class: "text-nowrap",
                },
                {
                    text: "氏名(カナ)",
                    sortable: false,
                    value: "patient_name_kana",
                    class: "text-nowrap",
                },
                {
                    text: "生年月日/性別",
                    sortable: false,
                    value: "patient_birthday_gender",
                    class: "text-nowrap",
                },
                {
                    text: "利用券整理番号",
                    sortable: false,
                    value: "patient_use_ticket_ref_number",
                    class: "text-nowrap",
                },
                {
                    text: "保健指導区分",
                    sortable: false,
                    value: "health_guidance_div",
                    class: "text-nowrap",
                },
                { 
                    text: "保険者", 
                    sortable: false, 
                    value: "insurer", 
                    class: "text-nowrap",
                    width: "300px",
                },
                { 
                    text: "受付", 
                    sortable: false, 
                    value: "health_gudaince_reception", 
                    class: "text-nowrap" 
                },
                { 
                    text: "受付年度", 
                    sortable: false, 
                    value: "fiscal_year", 
                    class: "text-nowrap" 
                },
                { 
                    text: "初回面談予定", 
                    sortable: false, 
                    value: "scheduled_first_support_datetime", 
                    class: "text-nowrap" 
                },
                { 
                    text: "送信先メール", 
                    sortable: false, 
                    value: "to_mail", 
                    class: "text-nowrap" 
                },
                { 
                    text: "依頼", 
                    sortable: false, 
                    value: "scheduling_requested", 
                    class: "text-nowrap" 
                },
                { 
                    text: "依頼回数", 
                    sortable: false, 
                    value: "scheduling_request_count", 
                    class: "text-nowrap" 
                },
                { 
                    text: "依頼日時", 
                    sortable: false, 
                    value: "scheduling_request_datetime", 
                    class: "text-nowrap" 
                },
                { 
                    text: "調整結果", 
                    sortable: false, 
                    value: "scheduling_result_status", 
                    class: "text-nowrap" 
                },
                { 
                    text: "調整結果 取込日時", 
                    sortable: false, 
                    value: "scheduling_result_import_datetime", 
                    class: "text-nowrap" 
                },
                { 
                    text: "面談参加者", 
                    sortable: false, 
                    value: "meeting_attendee_email", 
                    class: "text-nowrap" 
                },
                { 
                    text: "受付ページ", 
                    sortable: false, 
                    value: "scheduling_reception_page", 
                    class: "text-nowrap" 
                },
            ],
            listErrors: [],
            listSendErrors: [],
            rotateNavBar: false,

            dialog: {
                isShowConfirm: false,
                isImportConfirm: false,
            },

            component: undefined,
            showDetailTargetPatientId: undefined,
            randomString: "",

            selectedReceptionPage: null,
            eeasyLoadEnable: false,
        }
    },

    /**
     * 
     */
    async created() {
        // eeasyモジュールの初期化.
        if (ENVS.EEASY_LIB_LOAD_ENABLE) {
            await SchedulingApiService.getSystemSettings(
                SYS_SETTING_KEY_EEASY_SYSTEM_PUBLIC_KEY + "|" +
                SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL + "|" +
                SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_QUERY)
                .then((res) => {
                    if (res.data) {
                        const sysSettings = res.data;

                        this.systemSettings = {};
                        this.systemSettings[SYS_SETTING_KEY_EEASY_SYSTEM_PUBLIC_KEY] = 
                            sysSettings.filter(v => v.setting_key == SYS_SETTING_KEY_EEASY_SYSTEM_PUBLIC_KEY)[0].setting_val;
                        this.systemSettings[SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL] = 
                            sysSettings.filter(v => v.setting_key == SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL)[0].setting_val;
                        this.systemSettings[SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_QUERY] = 
                            sysSettings.filter(v => v.setting_key == SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_QUERY)[0].setting_val;

                        const systemPublicKey = this.systemSettings[SYS_SETTING_KEY_EEASY_SYSTEM_PUBLIC_KEY];
                        try {
                            eeasy.load(systemPublicKey)
                                .then(function (result) {
                                    this.eeasyLoadEnable = true;
                                }.bind(this))
                                .catch(function (err) {
                                    console.log(err);
                                });
                        } catch(err) {
                            console.log(err);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        // 受付ページ 取得.
        await SchedulingApiService.getReceptionPages().then((res) => {
            this.receptionPages = res.data;
        });

        // 保険者 取得.
        await SchedulingApiService.getInsurer().then((res) => {
            this.insurers = [];
            this.insurers = res.data.data;
        });

        // コード定義 取得.
        await SchedulingApiService.getCodeDefineGroups(
            [
                CODE_GENDER, 
                CODE_RECEPTION, 
                CODE_HEALTH_GUIDANCE_DIV,
                CODE_SCHEDULING_RESULT_STATUS,
            ]).then((res) => {
            const codeDefineGroups = res.data;

            // 性別
            const genderCodeGroup = codeDefineGroups[CODE_GENDER];
            this.genderCodes = genderCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                        "text_short": item["acronym"],
                    };
                });
            
            // 保健指導受付区分
            const healthGuideReceptionDivCodeGroup = codeDefineGroups[CODE_RECEPTION];
            this.healthGuideReceptionDivCodes = healthGuideReceptionDivCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                        "text_short": item["acronym"],
                    };
                })
                .filter(function(item) {
                    return item["code"] != "30";
                });
            
            // 保健指導区分
            const healthGuideDivCodeGroup = codeDefineGroups[CODE_HEALTH_GUIDANCE_DIV];
            this.healthGuideDivCodes = healthGuideDivCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                    };
                });

            // 日程調整結果ステータス
            const shcedulingResultStatusCodeGroup = codeDefineGroups[CODE_SCHEDULING_RESULT_STATUS];
            this.schedulingResultStatusCodes = shcedulingResultStatusCodeGroup
                .filter(function(item){
                    return item.control_flg_01;
                })
                .map(function(item) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["code"],
                        "text": item["value"],
                    };
                });
        });

        // if(document.getElementById("scheduling-list-checkbox-all") == null){
        //     $("th.scheduling-list-checkbox-column span").append("<input id='scheduling-list-checkbox-all' type='checkbox' />");
            
        //     const that = this;
        //     $("#scheduling-list-checkbox-all").on("change", function(){
        //         const chkVal = this.checked;
        //         $(".scheduling-list-checkbox").each(function(index, chk) {
        //             chk.checked = chkVal;
        //         });
        //         that.listItems.forEach(listItem => {
        //             if (listItem.selected !== null) {
        //                 listItem.selected = chkVal;
        //             }
        //         });
        //     });
        // }
    },

    /**
     * 
     */
    computed: {
        /**
         * 年入力オプション
         */
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2099-12-30",
                locale: moment.locale("ja"),
            };
        },
        optionDate() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            };
        },
        ...mapGetters({
            user: "common/getUser",
        }),
    },

    /**
     * 
     */
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * 
         * @param {*} date 
         */
        formatDatetime(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return "";
        },

        /**
         * 
         * @param {*} date 
         */
        formatYYYYMMDD_HHMM(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm");
            }
            return "";
        },

        /**
         * 
         */
        formatFiscalYear(fiscalYear) {
            if (fiscalYear) {
                return String(fiscalYear).slice(-2) + "年度";
            }
            return "";
        },

        /**
         * コード定義取得 性別.
         * @param {*} genderCode 
         */
        getCodeDef_Gender(genderCode) {
            if (genderCode) {
                let data = this.genderCodes.filter((x) => x.code == genderCode);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * コード定義取得 保健指導区分.
         * @param {*} healthGuidanceDiv 
         */
        getCodeDef_HealthGuidanceDiv(healthGuidanceDiv) {
            if (healthGuidanceDiv) {
                let data = this.healthGuideDivCodes.filter((x) => x.code == healthGuidanceDiv);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * コード定義取得 保健指導受付区分.
         * @param {*} healthGuidanceReceptionDiv 
         */
        getCodeDef_HealthGuidanceReceptionDiv(healthGuidanceReceptionDiv) {
            if (healthGuidanceReceptionDiv) {
                let data = this.healthGuideReceptionDivCodes.filter((x) => x.code == healthGuidanceReceptionDiv);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * コード定義取得 日程調整結果ステータス.
         * @param {*} schedulingResultStatus 
         */
        getCodeDef_SchedulingResultStatus(schedulingResultStatus) {
            if (schedulingResultStatus) {
                let data = this.schedulingResultStatusCodes.filter((x) => x.code == schedulingResultStatus);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * 
         * @param {*} url 
         */
        getReceptionPage(url) {
            if (url) {
                let data = this.receptionPages.filter((x) => x.page_url == url);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * 
         * @param {*} insurerCode 
         */
        getInsurerByCode(insurerCode) {
            if (insurerCode) {
                let data = this.insurers.filter((x) => x.client_organ_code == insurerCode);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * 
         * 
         */
        formatReceptionPageText(item) {
            if (item == null) return "";
            return `${item.page_name} [${item.page_url}]`;
        },

        /**
         * 
         * 
         */
        formatClientOrganText(item) {
            if (item == null) return "";
            return `[${item.client_organ_code}] ${item.client_organ_name}`;
        },

        /**
         * 
         * 
         */
        formatInsurerText(item) {
            if (item == null) return "";
            return `[${item.insurer_code}] ${item.insurer_name}`;
        },

        /**
         * 
         * 
         */
        formatPatientIdText(patientId) {
            return String(patientId).padStart(10, "0")
        },

        /**
         * 
         * 
         */
        formatPatientText(patientId, patientName) {
            return `[${this.formatPatientIdText(patientId)}] ${patientName}`;
        },

        /**
         * 検索クリア.
         */
        clearAllSearch() {

            this.clearSearchErrorMessage();
            this.dataSearch = {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                fiscal_year: String(this.getFiscalYear()),
                scheduling_request_status: null,
                scheduling_result_status: null,
                scheduling_result_date_from: moment(new Date()).subtract(3, "months").format("YYYY-MM-DD"),
                scheduling_result_date_to: moment(new Date()).format("YYYY-MM-DD"),
                has_to_mail: true,
                scheduling_unfixed: false,
            };
        },

        /**
         * 
         */
        clearSearchedCondition() {
            this.searchedCondition = {
                insurer_code: null,
                fiscal_year: null,
            };
        },

        /**
         * 検索エラーメッセージクリア.
         */
        clearSearchErrorMessage() {
            this.errorsSearch = {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                fiscal_year: null,
                scheduling_request_status: null,
                scheduling_result_status: null,
                scheduling_result_date_from: null,
                scheduling_result_date_to: null,
            };
        },

        /**
         * 一覧エラーメッセージクリア.
         */
        clearListErrorMessage() {
            this.listErrors = [];
        },

        /**
         * 一覧送信エラーメッセージクリア.
         */
        clearListSendErrorMessage() {
            this.listSendErrors = [];
        },

        /**
         * バリデーション.
         * @param {*} field 
        */
        validate(field) {
            schedulingSchemaValidation
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errorsSearch[field] = "";
                })
                .catch((err) => {
                    this.errorsSearch[field] = err.message;
                });
        },

        /**
         * 
         */
        search() {
            this.totalPage = 1;
            this.currentPage = 1;
            this.getList();
        },

        /**
         * 一覧取得.
        */
        getList() {
            schedulingSchemaValidation
                .validate(this.dataSearch, { abortEarly: false })
                .then(() => {
                   
                    this.listItems = [];

                    this.clearSearchedCondition();
                    this.clearSearchErrorMessage();
                    this.clearListErrorMessage();

                    this.setIsLoading(true);
                    this.dataSearch.fiscal_year = moment(this.dataSearch.fiscal_year).format("YYYY");
                    
                    SchedulingApiService.getPatientSchedulingAdjustmentList(this.dataSearch, this.currentPage, this.perPage)
                        .then((res) => {
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0,
                            });

                            const { data, status, error } = res;
                            this.setIsLoading(false);

                            if (status !== SUCCESS) {
                                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                                return;
                            }

                            this.listItems = data.data;
                            this.listItems.forEach(listItem => {

                                listItem.patient_id_format = this.formatPatientIdText(listItem.patient_id);

                                //===================================
                                // 行選択
                                //===================================
                                listItem.selected = false;
                                if (!listItem.has_contact) {
                                    listItem.selected = null;
                                } else {
                                    if (!listItem.scheduling_requested) {
                                        // 対象行にチェック.
                                        listItem.selected = true;
                                    }
                                }
                            });

                            $(".demo-list").closest(".container-fluid").css("height", this.listItems.length ? "100%" : "auto");

                            this.totalPage = data.last_page;
                            this.currentPage = data.current_page;

                            this.searchedCondition.insurer_code = this.dataSearch.insurer;
                            this.searchedCondition.fiscal_year = this.dataSearch.fiscal_year;

                            if (this.listItems.length < 1) {
                                this.notifyNoSearchResults();
                            }

                            if (0 < this.listSendErrors.length) {
                                this.listErrors = this.listSendErrors.map(x => x);
                                this.clearListSendErrorMessage();
                            }
                        }).catch((err) => {
                            this.setIsLoading(false);
                            this.$toast.error(`${err}`, TOAST_TIME_OUT);
                            return;
                        });
                })
                .catch((err) => {
                    this.setIsLoading(false);
                    this.$toast.error(`${err}`, TOAST_TIME_OUT);
                    this.notifyInputInvalidToast();
                    return;
                });
        },

        getSelectedListItems() {
            return this.listItems.filter(listItem => {
                if (listItem.selected === null) return false;
                return listItem.selected;
            });
        },

        /**
         * 日程調整依頼 送信.
         */
        send() {
            // エラーメッセージクリア.
            this.clearListErrorMessage();
            this.clearListSendErrorMessage();

            // 選択行の取得
            const selectedListItems = this.getSelectedListItems();

            if (!this.selectedReceptionPage) {
                this.notifyWarn("日程調整受付ページを選択してください。");
                return;
            }

            if (selectedListItems.length < 1) {
                this.notifyWarn("適用対象にチェックを付けてください。");
                return;
            }

            // ポストデータ生成.
            const receptionPage = this.getReceptionPage(this.selectedReceptionPage);
            const postData = {
                reception_page: {
                    url: receptionPage.page_url,
                    name: receptionPage.page_name,
                    type: receptionPage.page_type,
                },
                fiscal_year: this.searchedCondition.fiscal_year,
                insurer_code: this.searchedCondition.insurer_code,
                receipient_patient_ids: selectedListItems.map(item => item.patient_id)
            };

            try {
                // マスク.
                this.setIsLoading(true);

                SchedulingApiService.storeSchedulingAdjustmentRequest(postData)
                    .then((res) => {
                        const { data, status, error } = res;
                        this.setIsLoading(false);

                        if (status !== SUCCESS) {
                            this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                            return;
                        }

                        const execResult = data.data
                        const execErrorList = execResult.filter(function(item) {
                            return !item.success;
                        });

                        if (0 < execErrorList.length) {
                            this.listSendErrors = execErrorList.map(function(item) {
                                const patientText = this.formatPatientText(item.patientId, item.patientNameKana)
                                return `${patientText} [依頼送信エラー]： ${item.errorMessage}`;
                            }.bind(this));
                            this.notifyWarn("一部の日程調整依頼送信に失敗しました。");
                        } else {
                            this.$toast.open({
                                message: "日程調整依頼の送信が完了しました。",
                                type: "success",
                            });
                        }

                        this.listItems = [];
                        this.search();
                    })
                    .catch((err) => {
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        this.$toast.error(`${err}`, TOAST_TIME_OUT);
                    });
            } catch(err) {
                // ローディング状態解除.
                this.setIsLoading(false);
                this.$toast.error(`${err}`, TOAST_TIME_OUT);
            };
        },

        /**
         * 日程調整 予約受付ページ取込.
         */
        async importReceptionPages() {

            if (!this.eeasyLoadEnable) return;
            if (!this.systemSettings[SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL]) return;

            try {
                // マスク.
                this.setIsLoading(true);
                const userMails = this.systemSettings[SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_USER_EMAIL].split("|").map(x => x.trim());
                let errorOccured = false;
                let getReceptionPages = [];

                for await (let userMail of userMails) {
                    // eeasy連携
                    await eeasy.getReceptionPages({
                        userEmail:userMail,
                        q: this.systemSettings[SYS_SETTING_KEY_EEASY_RECEPTION_PAGE_QUERY]
                    })
                        .then(function (result) {
                            if (0 < result["pages"].length) {
                                getReceptionPages = getReceptionPages.concat(result["pages"].map(p => {
                                    p["userMail"] = userMail;
                                    return p;
                                }));
                            }
                        })
                        .catch(function (err) {
                            console.error(err);
                            errorOccured = true;
                        });

                    // 連続呼び出しのため、sleep
                    await new Promise(s => setTimeout(s, 100))
                }

                if (errorOccured) {
                    throw new Exception();
                }

                await this.storeReceptionPages(getReceptionPages);

            } catch(e) {
                console.error(e);
                this.notifyError("Eeasyから日程調整 予約受付ページを取得中にエラーが発生しました");
            } finally {
                this.setIsLoading(false);
            }
        },

        /**
         * 
         * @param {*} repceptionPages 
        */
        async storeReceptionPages(repceptionPages) {

            if (repceptionPages.length < 1) return;

            const eeasyReceptionPages = [];
            repceptionPages.forEach(function(p, index){
                eeasyReceptionPages.push({
                    page_user: p.userMail,
                    page_url: p.url,
                    page_name: p.name,
                    page_type: p.kind,
                    disp_seq: index,
                });
            });

            // ポストデータ生成.
            const postData = {
                reception_pages: eeasyReceptionPages,
            };

            await SchedulingApiService.storeReceptionPage(postData)
                .then((res) => {
                    const { data, status, error } = res;
                    this.setIsLoading(false);

                    if (status !== SUCCESS) {
                        this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                        return;
                    }

                    this.$toast.open({
                        message: "日程調整 予約受付ページの取込が完了しました。",
                        type: "success",
                    });

                    this.receptionPages = res.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        /**
         * 
         * @param {*} pageNumber 
         */
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getList();
        },

        /**
         * 対象者詳細
         * @param {*} id 
         * @param {*} health_guidance_reception_id 
         * @param {*} type 
         */
        userDetail(id, health_guidance_reception_id, type) {
            this.showDetailTargetPatientId = id;
            //this.dataReceptionsId = health_guidance_reception_id;
            this.component = type;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
    },

    /**
     * 
     */
    destroyed() {
        $(document).unbind()
    }
}

</script>

<style lang="sass">
@import "../../styles/common"
</style>
<style lang="scss" scoped>
  .wrapper .main .content .container-fluid .data-table-container {
    padding: 0;
    .data-table {
      margin: 0;
    }
  }
  .container-pagination {
    margin: 0px !important;
    height: 60px !important;
    max-height: 60px !important;
  }

  #list-item-error-msg {
    width: 100%;
    height: 120px;
    min-height: 120px;
    max-height: 180px;
    overflow: auto;
    background-color: #FFF0F5;
    color: red;
  }
</style>