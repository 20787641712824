<template>
  <div class="demo-list">
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 start ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 
    <div class="box-search mb-3">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="border border-top-0 rounded p-1 pr-2">
                  <div class="col-12 p-0 m-0 text-right">
                    <button
                            @click="clearAllSearch"
                            type="button"
                            class="btn btn-grey"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      クリア
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      <div>
                        受付年度
                      </div>
                      <div class="row">
                        <div class="col pr-0 py-0">
                          <date-picker
                              autocomplete="off"
                              v-model="dataSearch.contract_fiscal_year"
                              id="year"
                              name="year"
                              :config="optionYear"
                              @input="validate('contract_fiscal_year')"
                          ></date-picker>
                        </div>
                        <div class="col-auto px-1 pt-2 pb-0">
                          以降
                        </div>
                      </div>
                      <div class="mt-3">
                        <span class="text-danger">{{
                          errorsSearch.contract_fiscal_year
                        }}</span>
                      </div>
                    </div>
                    <div class="col-3">
                      受付日
                      <div class="row align-items-center">
                        <div class="col-5 mt-0 pt-0">
                          <date-picker
                                  autocomplete="off"
                                  @input="validate('reception_date_from');validate('reception_date_to')"
                                  v-model="dataSearch.reception_date_from"
                                  id="reception_date_from"
                                  name="reception_date_from"
                                  :config="options"
                          ></date-picker>
                        </div>
                        <div class="col-1 text-center pt-0">〜</div>
                        <div class="col-5 mt-0 pt-0">
                          <date-picker
                                  autocomplete="off"
                                  @input="validate('reception_date_to')"
                                  v-model="dataSearch.reception_date_to"
                                  id="reception_date_to"
                                  name="reception_date_to"
                                  :config="options"
                          ></date-picker>
                        </div>
                        <span class="text-danger">{{
                  errorsSearch.reception_date_from
                }}</span>
                        <span class="text-danger">{{
                  errorsSearch.reception_date_to
                }}</span>
                <span class="text-danger">{{
                  In_ValRng_TermOver
                }}</span>
                      </div>
                    </div>
                    <div class="col-6">
                      支援状況
                      <div class="row">
                        <div
                                class="col-auto"
                                v-for="item in statusFolow"
                                :key="item.value + 'statusFolow'"
                        >
                          <input
                                  class="form-check-input data-status-support"
                                  :value="item.value"
                                  type="checkbox"
                                  :checked="item.value == 10 || item.value == 20"
                                  :id="
                      'flexRadioDefault1' + item.value + 'person_familiy_div'
                    "
                          />
                          <label
                                  class="mr-8 form-check-label"
                                  :for="
                      'flexRadioDefault1' + item.value + 'person_familiy_div'
                    "
                  >
                    {{ item.text }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-2">
              実施ポイント
              <div class="row">
                <div class="col-auto">
                  <input
                    class="form-check-input data-search-point"
                    :value="1"
                    checked
                    type="checkbox"
                    id="point1"
                  />
                  <label class="mr-10 form-check-label" for="point1">
                    満了
                  </label>
                </div>
                <div class="col-auto">
                  <input
                    class="form-check-input data-search-point"
                    :value="0"
                    checked
                    type="checkbox"
                    id="point0"
                  />
                  <label class="mr-10 form-check-label" for="point0">
                    未満
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              氏名
              <input
                id="name"
                name="name"
                type="text"
                class="form-control"
                placeholder="氏名 or 氏名カナ"
                v-model="dataSearch.patient_name"
                @keyup="validate('patient_name')"
              />
              <span class="text-danger">{{ errorsSearch.patient_name }}</span>
            </div>
            <div class="col-2">
              利用券整理番号
              <input
                v-model="dataSearch.patient_ticket"
                type="text"
                class="form-control"
                placeholder=""
                @keyup="validate('patient_ticket')"
              />
              <span class="text-danger">{{
                errorsSearch.patient_ticket
              }}</span>
                    </div>
                    <div class="col-2">
                      保険者
                      <select v-model="dataSearch.insurer" class="form-select">
                        <option :value="null"></option>
                        <option
                                v-for="insurer in insurerArr"
                                :key="insurer.id + 'insurer'"
                                :value="insurer.client_organ_code"
                        >
                          [{{ insurer.client_organ_code }}]
                          {{ insurer.client_organ_name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2">
                      保健指導区分
                      <select
                              v-model="dataSearch.health_guide_code"
                              class="form-select"
                      >
                        <option :value="null"></option>
                        <option
                                v-for="item in healthGuideCodeArr"
                                :key="item.text + 'item'"
                                :value="item.value"
                        >
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-2 pt-3">
                      <!-- <v-checkbox
                        v-model="dataSearch.health_guide_code"
                        label="初回未完了"
                      /> -->
                      <input
                                class="form-check-input mr-1"
                                :value="1"
                                v-model="dataSearch.filter_incomplete_first_support"
                                type="checkbox"
                                id="filter-incomplete"
                        />
                        <label
                                class="mr-20 form-check-label"
                                for="filter-incomplete"
                        >
                        初回未完了
                        </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-auto pb-0 pl-0">
                        <input
                                class="form-check-input mr-2"
                                :value="1"
                                v-model="dataSearch.filter_plan_support"
                                type="checkbox"
                                id="直近支援予定で絞込み"
                        />
                        <label
                                class="mr-20 form-check-label"
                                for="直近支援予定で絞込み"
                        >
                          直近支援予定で絞込み
                        </label>
                      </div>
                      <hr class="m-0" />
                      <div class="col-2 pl-0">
                        支援担当者
                        <select
                                v-model="dataSearch.support_person_id"
                                class="form-select"
                        >
                          <option :value="null"></option>
                          <option
                                  v-for="item in supportPersonArr"
                                  :key="item.id + 'item_user_name'"
                                  :value="item.id"
                          >
                            {{ item.user_name_disp }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-3 pl-0 pt-0">
                        <input
                                class="form-check-input mr-2 check-filter-bottom"
                                value="check_date_support"
                                type="radio"
                                name="check"
                                id="check_1"
                                checked
                                @click="checkValidateFilterPlanSupport()"
                        />
                        <label class="mr-20 form-check-label" for="check_1">
                          支援予定日or計画日で絞込み
                        </label>
                        <div class="row align-items-center">
                          <div class="col-5 mt-0 pt-0">
                            <date-picker
                                    autocomplete="off"
                                    v-model="dataSearch.check_date_support_from"
                                    id="from_date_support"
                                    name="from_date_support"
                                    :config="options"
                            ></date-picker>
                          </div>
                          <div class="col-1 text-center pt-0">〜</div>
                          <div class="col-5 mt-0 pt-0">
                            <date-picker
                                    autocomplete="off"
                                    v-model="dataSearch.check_date_support_to"
                                    id="to_date_support"
                                    name="to_date_support"
                                    :config="options"
                            ></date-picker>
                          </div>
                          <span class="text-danger">{{validateFilterPlanSupport.check_date_support_to}}</span>
                        </div>
                      </div>

                      <div class="col-3 pl-0 pt-0">
                        <input
                                class="form-check-input mr-2 check-filter-bottom"
                                value="check_not_assigned_task"
                                type="radio"
                                name="check"
                                id="check_2"
                                @click="checkValidateFilterPlanSupport()"
                        />
                        <label class="mr-20 form-check-label" for="check_2">
                          タスク未割当で絞込み
                        </label>
                        <div class="row">
                          <div class="col-3">計画日が</div>
                          <div class="col-3 pt-0 pl-0 pr-0">
                            <input
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                    v-model="dataSearch.not_assigned_task"
                            />
                          </div>
                          <div class="col-4">日以内に到来</div>
                        </div>
                        <span class="text-danger">{{
                  validateFilterPlanSupport.not_assigned_task
                }}</span>
                      </div>

                      <div class="col-3 pl-0 pt-0">
                        <input
                                class="form-check-input mr-2 check-filter-bottom"
                                value="check_overdue"
                                type="radio"
                                name="check"
                                id="overdue"
                                @click="checkValidateFilterPlanSupport()"
                        />
                        <label class="mr-20 form-check-label" for="overdue">
                          期限超過で絞込み
                        </label>
                        <div class="row">
                          <div class="col-4 pt-0 pr-5">
                            <input
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                    v-model="dataSearch.overdue"
                            />
                          </div>
                          <div class="col-6 pl-0">
                            日以上期限超過
                          </div>
                        </div>
                        <span class="text-danger">{{ validateFilterPlanSupport.overdue }}</span>
                      </div>
                      <div class="col-3 pl-0 pt-0">
                        <input
                                class="form-check-input mr-2 check-filter-bottom"
                                value="check_reminder"
                                type="radio"
                                name="check"
                                id="reminder"
                                @click="checkValidateFilterPlanSupport()"
                        />
                        <label class="mr-20 form-check-label" for="reminder">
                          督促ありで絞込み
                        </label>
                      </div>
                      <div class="col-12 pr-0 pt-0 text-right">
                        <button
                                @click="getList(); currentPage = 1"
                                type="button"
                                class="btn btn-success mt-5"
                        >
                          <i class="fa fa-search" aria-hidden="true"></i>
                          検索
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 end ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="data-table-container">
      <v-data-table
        :headers="headers"
        :items="lists"
        :fixed-header="true"
        :height="'100%'"
        disable-pagination
        hide-default-footer
        class="data-table table table-responsive table-hover"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id + 'lists'">
              <td>
                <router-link :to="`/dashboard?reception_id=${item.id}`">
                  <i class="fa fa-book"></i>
                </router-link>
              </td>
              <td class="text-nowrap">
                <a
                  @click="userDetail(item.patients.id, null, 'Detail')"
                  class="text-primary"
                >
                  {{ String(item.patients.id).padStart(10, "0") }}</a
                >
                <br />
                <a
                  @click="userDetail(item.patients.id, item.id, 'Receipt')"
                  class="text-primary"
                  >受付</a
                >
              </td>
              <td class="text-nowrap">
                <!-- 受付年度 -->
                {{ item.contract_fiscal_year }}年度(第{{ item.health_guidance_period }}期)
              </td>
              <td class="text-nowrap">
                <!-- 氏名カナ -->
                {{ item.patients.patient_name_kana }}
              </td>
              <td class="text-nowrap">
                <!-- 生年月日/性別 -->
                {{ item.patients.patient_birthday }} /
                {{ sexArr[item.patients.patient_gender] }}
              </td>
              <td class="text-nowrap">
                <!-- 利用券整理番号 -->
                {{ item.patients.use_ticket_ref_number }}
              </td>
              <td class="text-nowrap">
                <!-- 保健指導区分 -->
                {{ healthGuideCodeName[item.health_guidance_div] }}
              </td>
              <td>
                <!-- 保険者 -->
                <p style="width: 200px;word-break: break-word;">
                  {{ getInsurer(item.patients.insurer_id) }}
                </p>
              </td>
              <td class="text-nowrap">
                <!-- 支援ポイント -->
                {{ getSupportPoint(item.health_guidance_div, item.health_support_flows) }}
              </td>
              <td class="text-nowrap">
                <!-- 支援フローステータス -->
                {{ getStatusFolow(item.health_support_flows) }}
              </td>
              <td class="text-nowrap">
                <!-- 支援予定or計画日 -->
                {{ getScheduleSupport(item.health_support_flows) }}
              </td>
              <td class="text-nowrap">
                <!-- 支援更新日時 -->
                {{ getUpdateMaxFlowSegment(item.health_support_flows) }}
              </td>
              <td class="text-nowrap">
                 <!-- アイコン群 -->
                <div class="d-flex align-items-center m-auto">
                  <!-- 督促アイコン -->
                  <span v-if="checkIconPhone(item.health_support_flows)" class="d-grid text-center mr-2">
                    <v-icon color="red" large>mdi-phone-in-talk</v-icon>
                    <span style="font-size: 10px; color: red;">督促</span>
                  </span>
                  <!-- 支援辞退アイコン -->
                  <span v-if="checkIconYellow(item.health_support_flows)" class="d-grid text-center mr-2">
                    <v-icon color="yellow" large>mdi-alert-circle-outline</v-icon>
                    <span style="font-size: 10px; color: red;">支援辞退</span>
                  </span>
                  <span v-if="checkIconOrange(item.health_support_flows)" class="d-grid text-center mr-2">
                    <v-icon color="orange" large>mdi-alert-circle-outline</v-icon>
                    <span style="font-size: 10px; color: red;">初回未完了</span>
                  </span>
                  <!-- タスク未割当アイコン -->
                  <span v-if="checkIconWarning(item.health_support_flows)" class="d-grid text-center mr-2">
                    <v-icon color="warning" large>mdi-alert-outline</v-icon>
                    <span style="font-size: 10px; color: red;">未割当</span>
                  </span>
                  <!-- 期限超過アイコン -->
                  <span v-if="checkIconRed(item.health_support_flows)" class="d-grid text-center mr-2">
                    <v-icon color="red" large>mdi-alert-outline</v-icon>
                    <span style="font-size: 10px; color: red;">期限超過</span>
                  </span>
                </div>
              </td>
              <td class="text-nowrap">
                <!-- 支援進捗 -->
                <div v-if="item.health_support_flows.length > 0" class="d-flex align-center mb-5 gap-2 container-support-flow-segment">
                  <div v-for="(segment, indexIt) in item.health_support_flows[0].health_support_flow_segments" :key="indexIt">
                    <!-- 支援フローに含まれる支援セグメントでループ -->
                    <div :class="{'segments-block-content': indexIt < item.health_support_flows[0].health_support_flow_segments.length - 1}">
                      <SegmentBlock :segment="segment" :healthSupportFlows="item.health_support_flows[0]"></SegmentBlock>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

    <Pagination
      :total-page="totalPage"
      :current-page="currentPage"
      @onHandleChangePage="onHandleChangePage"
    />
    <slide>
      <Detail
        v-if="component === 'Detail'"
        :dataDetailId="idSelected"
        :checkViewOrEdit="false"
        :isShowNavigation="randomString"
      ></Detail>
      <ReceiptMedical
        v-if="component === 'Receipt'"
        :dataDetailId="idSelected"
        :dataReceptionsId="dataReceptionsId"
        :checkViewOrEdit="false"
        :isShowNavigation="randomString"
      />
      <!-- @reload="reload" -->
    </slide>
  </div>
</template>
<script>
import Slide from "@/components/Slide";
import Detail from "@/components/DetailImportDiagnosticData";
import ReceiptMedical from "../../components/ReceiptMedicalDirectives/Modal/ReceiptMedical";
import SegmentBlock from "@/pages/Support-progress-management/SegmentBlock";
import Pagination from "@/components/common/Pagination";
import { mapMutations, mapGetters } from "vuex";
import { SUCCESS } from "@/constants";
import moment from "moment";
import SupportProgressManagement from "@/services/api/SupportProgressManagement";
import { formSearchSupportProgressManagement } from "../../common/schemaValidation";
import _, { uniqueId } from "lodash";
import { CODE_GENDER, ENVS } from "@/common/constants";
import mixins from "@/mixins";
import { object } from "yup";

export default {
    name: "list",
    mixins: [mixins],
    data() {
        return {
            headers: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "",
                    class: "text-nowrap",
                },
                {
                    text: "対象者ID",
                    sortable: true,
                    value: "patients.id",
                    class: "text-nowrap",
                },
                {
                    text: "受付年度",
                    sortable: true,
                    value: "patients.patient_name",
                    class: "text-nowrap",
                },
                {
                    text: "氏名(カナ)",
                    sortable: true,
                    value: "patients.patient_name",
                    class: "text-nowrap",
                },
                {
                    text: "生年月日/性別",
                    sortable: true,
                    value: "patients.patient_birthday",
                    class: "text-nowrap",
                },
                {
                    text: "利用券整理番号",
                    sortable: true,
                    value: "patients.use_ticket_ref_number",
                    class: "text-nowrap",
                },
                {
                    text: "保健指導区分",
                    sortable: true,
                    value: "health_guidance_div",
                    class: "text-nowrap",
                },
                { text: "保険者", sortable: true, value: "patients.insurer_id", class: "text-nowrap" },
                { text: "ポイント", sortable: true, value: "health_support_flows.health_support_flows_point", class: "text-nowrap" },
                { text: "支援状況", sortable: false, value: "", class: "text-nowrap" },
                {
                    text: "支援予定or計画日",
                    sortable: true,
                    value: "health_support_flows.health_support_flows_schedule_date",
                    class: "text-nowrap",
                },
                {
                    text: "支援更新日時",
                    sortable: true,
                    value: "health_support_flows.health_support_flows_support_update_date",
                    class: "text-nowrap",
                },
                {
                    text: "進捗フロー",
                    sortable: false,
                    value: "",
                    class: "text-nowrap",
                },
                { text: "", sortable: false, value: "", class: "text-nowrap" },
            ],
            component: undefined,
            idSelected: undefined,
            dataReceptionsId: null,
            sexArr: [],
            statusFolow: [],
            insurerArr: [],
            healthGuideCodeArr: [],
            healthGuideCodeName: [],
            supportPersonArr: [],
            lists: [],
            totalPage: 1,
            currentPage: 1,
            perPage: 30,
            dataSearch: {
                contract_fiscal_year: String(this.getFiscalYear()),
                reception_date_from: moment(new Date())
                    .subtract(1, "years")
                    .format("YYYY-MM-DD"),
                reception_date_to: moment(new Date()).format("YYYY-MM-DD"),
                point: [],
                patient_name: null,
                patient_ticket: null,
                insurer: null,
                support_person_id: null,
                health_guide_code: null,
                check_not_assigned_task: false,
                check_date_support: false,
                check_date_support_to: moment(new Date())
                    .add(5, "weeks")
                    .format("YYYY-MM-DD"),
                check_date_support_from: moment(new Date()).format("YYYY-MM-DD"),
                not_assigned_task: ENVS.SUPPORT_PROG_SCOND_DEFAULT_SUPPORT_UNASSIGNED,
                check_overdue: false,
                overdue: ENVS.SUPPORT_PROG_SCOND_DEFAULT_PERIOD_OVER,
                data_status_support: [],
                filter_plan_support: 1,
                filter_incomplete_first_support: false,
            },
            errorsSearch: {
                reception_date_from: null,
                reception_date_to: null,
                patient_name: null,
                patient_ticket: null,
                contract_fiscal_year: null
            },
            In_ValRng_TermOver: null,
            validateFilterPlanSupport: {
                check_date_support_to: null,
                check_date_support_from: null,
                not_assigned_task: null,
                overdue: null,
            },
            abc: null,
            check_data_point: true,
            check_data_status: true,
            rotateNavBar: false,
            randomString: "",
        };
    },
    computed: {
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            };
        },
        options() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2999-12-30",
                locale: moment.locale("ja"),
            };
        },
        ...mapGetters({
            user: "common/getUser",
        }),
    },
    components: {
        Pagination,
        SegmentBlock,
        ReceiptMedical,
        Slide,
        Detail,
    },
    watch: {
        "dataSearch.reception_date_to"() {
            if (this.dataSearch.reception_date_from && this.dataSearch.reception_date_to && !this.errorsSearch.reception_date_from && !this.errorsSearch.reception_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.reception_date_to).diff(moment(this.dataSearch.reception_date_from), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "dataSearch.reception_date_from"() {
            if (this.dataSearch.reception_date_from && this.dataSearch.reception_date_to && !this.errorsSearch.reception_date_from && !this.errorsSearch.reception_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.reception_date_to).diff(moment(this.dataSearch.reception_date_from), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "errorsSearch.reception_date_from"() {
            if (this.dataSearch.reception_date_from && this.dataSearch.reception_date_to && !this.errorsSearch.reception_date_from && !this.errorsSearch.reception_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.reception_date_to).diff(moment(this.dataSearch.reception_date_from), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },
        "errorsSearch.reception_date_to"() {
            if (this.dataSearch.reception_date_from && this.dataSearch.reception_date_to && !this.errorsSearch.reception_date_from && !this.errorsSearch.reception_date_to) {
                this.In_ValRng_TermOver = null
                let countDay = moment(this.dataSearch.reception_date_to).diff(moment(this.dataSearch.reception_date_from), "days");
                if (countDay > 366) {
                    this.In_ValRng_TermOver = "受付日の範囲は366日以内で入力してください。";
                }
            }else{
                this.In_ValRng_TermOver = null;
            }
        },

        "dataSearch.filter_plan_support"() {
            this.checkValidateFilterPlanSupport();
        },
        "dataSearch.check_date_support_to"() {
            this.checkValidateFilterPlanSupport();
        },
        "dataSearch.check_date_support_from"() {
            this.checkValidateFilterPlanSupport();
        },
        "dataSearch.not_assigned_task"() {
            this.checkValidateFilterPlanSupport();
        },
        "dataSearch.overdue"() {
            this.checkValidateFilterPlanSupport();
        }
    },
    async created() {
        this.sexArr = await this.getCodeDefine(CODE_GENDER, "acronym");

        // 支援フローステータス.
        await SupportProgressManagement.getCodeSupportStatus().then((res) => {
            this.statusFolow = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.statusFolow.push({
                    text: value,
                    value: key,
                });
            }
        });

        // 保険者.
        await SupportProgressManagement.getInsurer().then((res) => {
            this.insurerArr = [];
            this.insurerArr = res.data.data;
        });
        
        // 保健指導区分
        await SupportProgressManagement.getHealthGuideCode().then((res) => {
            this.healthGuideCodeName = res.data;
            this.healthGuideCodeArr = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.healthGuideCodeArr.push({
                    text: value,
                    value: key,
                });
            }
        });

        // 支援担当者
        await SupportProgressManagement.getSupportPerson().then((res) => {
            this.supportPersonArr = res.data.data;
            if (this.user && this.user != undefined) {
                if (this.supportPersonArr.filter((x) => x.id == this.user.id)) {
                    this.dataSearch.support_person_id = this.user.id;
                }
            }
            if (this.user) {
                if (
                    !this.dataSearch.support_person_id &&
                    this.supportPersonArr.filter((x) => x.id == this.user.id)
                ) {
                    this.dataSearch.support_person_id = this.user.id;
                }
            }
        });

        await this.getList();

        $(document).keypress((e) => {
            if (e.which === 13) {
                this.getList()
            }
        });
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * バリデーション
         */
        checkValidateFilterPlanSupport(){
            this.validateFilterPlanSupport.check_date_support_to = null;
            this.validateFilterPlanSupport.not_assigned_task = null;
            this.validateFilterPlanSupport.overdue = null;
            if(this.dataSearch.filter_plan_support){
                const check_filter_bottom = document.getElementsByClassName(
                    "check-filter-bottom"
                );
                for (let i = 0; i < check_filter_bottom.length; i++) {
                    if (check_filter_bottom[i].checked == true) {
                        switch (check_filter_bottom[i].value) {
                        case "check_date_support":
                            if(!this.dataSearch.check_date_support_to || !this.dataSearch.check_date_support_from){
                                this.validateFilterPlanSupport.check_date_support_to = "支援予定日or計画日で絞込みの場合を入力してください。";
                            }
                            if(this.dataSearch.check_date_support_to && this.dataSearch.check_date_support_from){
                                let countDay = moment(this.dataSearch.check_date_support_to).diff(moment(this.dataSearch.check_date_support_from), "days");
                                if(moment(this.dataSearch.check_date_support_from).format("YYYY-MM-DD") > moment(this.dataSearch.check_date_support_to).format("YYYY-MM-DD")){
                                    this.validateFilterPlanSupport.check_date_support_to = "支援予定(計画)日は開始と終了が逆転しています。";
                                }else if(countDay > 180){
                                    this.validateFilterPlanSupport.check_date_support_to = "支援予定(計画)日の範囲は180日以内で入力してください。";
                                }
                            }
                            break;
                        case "check_not_assigned_task":
                            if(!this.dataSearch.not_assigned_task){
                                this.validateFilterPlanSupport.not_assigned_task = "タスク未割当で絞込みの場合を入力してください。";
                            }else if(this.dataSearch.not_assigned_task < 0 || this.dataSearch.not_assigned_task > 999){
                                this.validateFilterPlanSupport.not_assigned_task = "タスク未割当 到来日数は[0〜999]の範囲で入力してください。";
                            }
                            break;
                        case "check_overdue":
                            if(!this.dataSearch.overdue){
                                this.validateFilterPlanSupport.overdue = "期限超過で絞込みの場合を入力してください。";
                            }else if(this.dataSearch.overdue < 1 || this.dataSearch.overdue > 999){
                                this.validateFilterPlanSupport.overdue = "タスク未割当 到来日数は[1〜999]の範囲で入力してください。";
                            }
                            break;
                        }
                        break;
                    }
                }
            }
            return ;
        },

        /**
         * バリデーション.
         * @param {*} field 
         */
        validate(field) {
            formSearchSupportProgressManagement
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errorsSearch[field] = "";
                })
                .catch((err) => {
                    this.errorsSearch[field] = err.message;
                });
        },

        /**
         * 支援ポイント取得.
         * @param {*} item  支援フロー
         */
        getSupportPoint(health_guidance_div, item) {
            item.health_support_flows_point = null;
            if (item.length < 1) return null;

            if (!this.isHealthGuidanceDiv_ActiveSupport(health_guidance_div)) return "ー";

            item.health_support_flows_point = item[0].current_support_point;
            return item[0].current_support_point + "/" + item[0].requried_support_point;
        },

        /**
         * 支援フローステータス取得.
         * @param {*} item 
         */
        getStatusFolow(item) {
            if (item.length < 1) return null;
            let value = this.statusFolow.filter(
                (x) => x.value == item[0].support_flow_status
            );
            return value[0]?.text;
        },

        /**
         * 督促あり 判定処理.
         * @param {} item 
         */
        checkIconPhone(item) {
            if (item.length < 1) return false;

            // アクティブセグメント
            const activeSeg = this.findActiveSegment(item[0]);
            if (activeSeg == null) return false;

            if (activeSeg.segment_status_01) {
                activeSeg.checkBorder = "phone";
                return true;
            }
            return false;
        },

        /**
         * 継続支援辞退 判定処理.
         * @param {*} item 
         */
        checkIconYellow(item) {
            if (item.length < 1) return false;
            let health_support_flow = item[0];
            if (health_support_flow.support_decline_flag && -1 < [10, 20].indexOf(parseInt(health_support_flow.support_flow_status))) {
                return true;
            }
            return false;
        },

        /**
         * 継続支援辞退 初回未完了
         * @param {*} item 
         */
        checkIconOrange(item) {
            if (item.length < 1) return false;
            let health_support_flow = item[0];
            if (health_support_flow.incomplete_first_support_flag) {
                return true;
            }
            return false;
        },


        /**
         * タスク未割当 判定処理
         * @param {*} item 支援フロー (なぜか配列)
         */
        checkIconWarning(item) {
            if (item.length < 1) return false;

            // アクティブセグメント
            const activeSeg = this.findActiveSegment(item[0]);
            if (activeSeg == null) return false;
            if (activeSeg.support_progress_status != 10) return false;

            const today = this.getToday();
            const planSupportDate = activeSeg.plan_support_datetime ? 
                this.getDatePart(activeSeg.plan_support_datetime) : null;

            if (planSupportDate && !today.isSameOrBefore(planSupportDate)) return false;

            const diffDays = (planSupportDate ?? this.getToday()).diff(today, "days");

            // 当日から計画日までの残日数 <= システム設定値[支援進捗 要警告 タスク未割当(日数)].
            if (diffDays <= ENVS.SUPPORT_PROG_WARN_REQED_SUPPORT_UNASSIGNED) {
                activeSeg.checkBorder = "orange";
                return true;
            }

            return false;
        },

        /**
         * 期限超過 判定処理.
         * @param {*} item 
         */
        checkIconRed(item) {
            if (item.length < 1) return false;

            // アクティブセグメント
            const activeSeg = this.findActiveSegment(item[0]);
            if (activeSeg == null) return false;

            const compareDate = activeSeg.scheduled_support_datetime ? activeSeg.scheduled_support_datetime :
                activeSeg.plan_support_datetime ? activeSeg.plan_support_datetime : null;

            if (compareDate == null) return false;

            const overDays = this.getToday().diff(this.getDatePart(compareDate), "days");
            if (ENVS.SUPPORT_PROG_WARN_REQED_PERIOD_OVER <= overDays) {
                activeSeg.checkBorder = "red";
                return true;
            }

            return false;
        },

        /**
         * 当日取得.
         */
        getToday() {
            return moment().startOf("days");
        },

        /**
         * 日時から日付部分取得.
         */
        getDatePart(datetime) {
            return moment(datetime).startOf("days");
        },

        /**
         * 現在アクティブの支援セグメント取得.
         * @param {*} flow 
         */
        findActiveSegment(flow) {
            // 支援セグメント取得([計画, 予定, 実施中]かつ支援中止フラグOFF)
            const segs = flow.health_support_flow_segments.filter(x => {
                return !x.support_stop_flag && -1 < [10, 20, 30].indexOf(parseInt(x.support_progress_status));
            });

            // フィルタ後の支援セグメントが1つも取得できなければ処理終了.
            if (segs.length < 1) {
                return null;
            }

            // 支援フロー順序でソート.
            const itemHealthFlowSegment = _.sortBy(segs, "support_flow_seq");
            if (itemHealthFlowSegment.length < 1) {
                return null;
            }

            return itemHealthFlowSegment[0];
        },

        /**
         * 最新更新日時取得.
         * @param {*} item 支援フロー
         */
        getUpdateMaxFlowSegment(item) {
            item.health_support_flows_support_update_date = null;
            if (item.length < 1) return item.health_support_flows_support_update_date;

            var maxDate = null;
            item[0].health_support_flow_segments.map( x => {
                const dt = moment(x.updated_at);
                if (maxDate == null || maxDate.isBefore(dt)) {
                    maxDate = dt;
                }
            });

            item.health_support_flows_support_update_date = maxDate?.format("YYYY-MM-DD HH:mm:ss");
            return item.health_support_flows_support_update_date;
        },

        /**
         * 支援予定or計画日
         * @param {*} item 支援フロー
         */
        getScheduleSupport(item) {
            item.health_support_flows_schedule_date = null;
            if (item.length < 1) return item.health_support_flows_schedule_date;

            const flow = item[0];
            if ([10, 20].indexOf(parseInt(flow.support_flow_status)) < 0) return item.health_support_flows_schedule_date;

            // アクティブセグメント
            const activeSeg = this.findActiveSegment(flow);
            if (activeSeg == null) return item.health_support_flows_schedule_date;

            let dt = activeSeg.scheduled_support_datetime ? activeSeg.scheduled_support_datetime :
                activeSeg.plan_support_datetime ? activeSeg.plan_support_datetime : null;
            if (dt != null) dt = this.getDatePart(dt).format("YYYY-MM-DD");
            item.health_support_flows_schedule_date = dt;

            return item.health_support_flows_schedule_date;
        },

        /**
         * 保険者取得.
         * @param {*} insurer 
         */
        getInsurer(insurer) {
            if (insurer) {
                let data = this.insurerArr.filter((x) => x.id == insurer);
                if (data.length > 0) {
                    return `[${data[0].client_organ_code}] ${data[0].client_organ_name}`;
                }
            }
            return null;
        },

        /**
         * 検索クリア.
         */
        clearAllSearch() {
            this.dataSearch = {
                contract_fiscal_year: String(this.getFiscalYear()),
                reception_date_from: moment(new Date())
                    .subtract(1, "years")
                    .format("YYYY-MM-DD"),
                reception_date_to: moment(new Date()).format("YYYY-MM-DD"),
                point: [],
                patient_name: null,
                patient_ticket: null,
                insurer: null,
                support_person_id: null,
                health_guide_code: null,
                check_not_assigned_task: false,
                check_date_support: false,
                check_date_support_to: moment(new Date())
                    .add(5, "weeks")
                    .format("YYYY-MM-DD"),
                check_date_support_from: moment(new Date()).format("YYYY-MM-DD"),
                not_assigned_task: ENVS.SUPPORT_PROG_SCOND_DEFAULT_SUPPORT_UNASSIGNED,
                check_overdue: false,
                overdue: ENVS.SUPPORT_PROG_SCOND_DEFAULT_PERIOD_OVER,
                data_status_support: [],
                filter_plan_support: 1,
                filter_incomplete_first_support: false,
            };
            const check_filter_bottom = document.getElementsByClassName(
                "check-filter-bottom"
            );
            for (let i = 0; i < check_filter_bottom.length; i++) {
                check_filter_bottom[i].checked = false;
            }
            let value_status_support = document.getElementsByClassName(
                "data-status-support"
            );
            for (let i = 0; i < value_status_support.length; i++) {
                if (
                    value_status_support[i].value == 10 ||
                    value_status_support[i].value == 20
                ) {
                    value_status_support[i].checked = true;
                } else {
                    value_status_support[i].checked = false;
                }
            }

            let value_point = document.getElementsByClassName("data-search-point");
            for (let i = 0; i < value_point.length; i++) {
                if (value_point[i].value == 1) {
                    value_point[i].checked = true;
                } else {
                    value_point[i].checked = true;
                }
            }
            if (this.user && this.user != undefined) {
                if (this.supportPersonArr.filter((x) => x.id == this.user.id)) {
                    this.dataSearch.support_person_id = this.user.id;
                }
            }

            //////this.getList();
        },

        /**
         * 一覧取得.
         */
        getList() {
            let checkValidateFilterPlanSupport = true;
            for (const [key, value] of Object.entries(this.validateFilterPlanSupport)) {
                if(value){
                    checkValidateFilterPlanSupport = false;
                }
            }
            if(checkValidateFilterPlanSupport){
                formSearchSupportProgressManagement
                    .validate(this.dataSearch, { abortEarly: false })
                    .then(() => {
                        this.errorsSearch = {
                            reception_date_from: null,
                            reception_date_to: null,
                            patient_name: null,
                            patient_ticket: null,
                            contract_fiscal_year: null
                        };
                        this.validateFilterPlanSupport = {
                            check_date_support_to: null,
                            check_date_support_from: null,
                            not_assigned_task: null,
                            overdue: null,
                        };

                        if(!this.In_ValRng_TermOver){
                            this.setIsLoading(true);

                            this.dataSearch.contract_fiscal_year = moment(
                                this.dataSearch.contract_fiscal_year
                            ).format("YYYY");

                            this.dataSearch.data_status_support = [];

                            if (this.check_data_status_point) {
                                this.dataSearch.data_status_support = [10, 20];
                                this.check_data_status = false;
                            }

                            let value_status_support = document.getElementsByClassName(
                                "data-status-support"
                            );
                            for (let i = 0; i < value_status_support.length; i++) {
                                if (value_status_support[i].checked == true) {
                                    this.dataSearch.data_status_support.push(
                                        parseInt(value_status_support[i].value)
                                    );
                                }
                            }

                            this.dataSearch.point = [];
                            if (this.check_data_status_point) {
                                this.dataSearch.point = [1];
                                this.check_data_point = false;
                            }

                            let value_point = document.getElementsByClassName("data-search-point");
                            for (let i = 0; i < value_point.length; i++) {
                                if (value_point[i].checked == true) {
                                    this.dataSearch.point.push(parseInt(value_point[i].value));
                                }
                            }

                            this.dataSearch.check_date_support = false;
                            this.dataSearch.check_not_assigned_task = false;
                            this.dataSearch.check_overdue = false;
                            this.dataSearch.check_reminder = false;
                            const check_filter_bottom = document.getElementsByClassName(
                                "check-filter-bottom"
                            );
                            for (let i = 0; i < check_filter_bottom.length; i++) {
                                if (check_filter_bottom[i].checked == true) {
                                    switch (check_filter_bottom[i].value) {
                                    case "check_date_support":
                                        this.dataSearch.check_date_support = true;
                                        break;
                                    case "check_not_assigned_task":
                                        this.dataSearch.check_not_assigned_task = true;
                                        break;
                                    case "check_overdue":
                                        this.dataSearch.check_overdue = true;
                                        break;
                                    case "check_reminder":
                                        this.dataSearch.check_reminder = true;
                                        break;
                                    }
                                    break;
                                }
                            }

                            SupportProgressManagement.getList(
                                this.dataSearch,
                                this.currentPage,
                                this.perPage
                            ).then((res) => {
                                window.scrollTo({
                                    behavior: "smooth",
                                    top: 0,
                                });
                                const { data, status, error } = res;

                                this.setIsLoading(false);
                                if (status !== SUCCESS) {
                                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                                    return;
                                }
                                this.lists = [];
                                this.lists = data.data;
                                $(".demo-list").closest(".container-fluid").css("height", this.lists.length ? "100%" : "auto");
                                this.totalPage = data.last_page;
                                this.currentPage = data.current_page;

                                if (this.lists.length < 1) {
                                    this.notifyNoSearchResults();
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        this.setIsLoading(false);
                        err.inner.forEach((error) => {
                            this.errorsSearch[error.path] = error.message;
                        });
                    });
            }
        },

        /**
         * 
         * @param {*} pageNumber 
         */
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getList();
        },

        /**
         * 
         * @param {*} id 
         * @param {*} health_guidance_reception_id 
         * @param {*} type 
         */
        userDetail(id, health_guidance_reception_id, type) {
            this.idSelected = id;
            this.dataReceptionsId = health_guidance_reception_id;
            this.component = type;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
            // this.$store.commit("showSlide");
        },
    },
    destroyed() {
        $(document).unbind()
    }
};
</script>
<style lang="sass">
@import "../../styles/common"
.fa-book
    font-size: 26px !important

.container-support-flow
    max-height: 70%
    & .container-support-flow-segment
        overflow: scroll
        scroll-behavior: smooth
        overflow-y: hidden
    & .container-support
        min-width: 50%
        border: solid .25px $backgroundHoverPaginationItem
        position: relative
        & .container-panel
            width: 3%
            height: 100%
            top: 0
            position: absolute
    & .container-title
        background: $backgroundHoverPaginationItem
    & .container-sub-title
        & .border-left
            border-left: solid thin $black
        & select
            & option:first-child
                display: none
    & .row span
        font-size: small
    & table
        & tr td
            font-size: 12px
            padding: 10px 0

.segments-block-content
      position: relative
      margin-right: 13px
      &:before
          content: ">>"
          position: absolute
          right: -17px
          top: 40%
</style>
<style lang="scss" scoped>
  .wrapper .main .content .container-fluid .data-table-container {
    padding: 0;
    .data-table {
      margin: 0;
    }
  }
  .container-pagination {
    margin: 0;
  }
</style>
