<template>
  <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
    <div class="container-fluid">
    <div class="container-body">
      <div class="col-12 border">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td class="bg-gray width-180px">対象者ID</td>
              <td class="width-250px">{{ String(dataDetail.patient_id).padStart(10, "0") }}</td>
              <td class="bg-gray width-180px">利用券整理番号</td>
              <td colspan="3">
                {{ dataDetail.use_ticket_ref_number }}
                ({{ dataDetail.use_ticket_effective_date }})
              </td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">氏名カナ</td>
              <td class="width-250px">{{ dataDetail.patient_name_kana }}</td>
              <td class="bg-gray width-180px">氏名</td>
              <td class="width-250px">{{ dataDetail.patient_name }}</td>
              <td class="bg-gray width-180px">生年月日 / 性別</td>
              <td class="width-250px">
                {{ dataDetail.patient_birthday }} /
                {{ dataDetail.patient_gender }}
              </td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">保険証番号</td>
              <td class="width-250px">{{ dataDetail.insured_card_number }}</td>
              <td class="bg-gray width-180px">保険証記号</td>
              <td class="width-250px">{{ dataDetail.insured_card_symbol }}</td>
              <td class="bg-gray width-180px">保険証枝番</td>
              <td class="width-250px">{{ dataDetail.insured_card_branch_code }}</td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">年度</td>
              <td class="width-250px">{{ String(dataDetail.contract_fiscal_year).slice(-2) }}年度</td>
              <td class="bg-gray width-180px">保険者</td>
              <td class="width-250px">{{ dataDetail.insurer_id }}</td>
              <td class="bg-gray width-180px">支払代行機関</td>
              <td class="width-250px">{{ dataDetail.payment_agency_id }}</td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">保健指導区分</td>
              <td class="width-250px">{{ dataDetail.health_guidance_div }}</td>
              <td class="bg-gray width-180px">契約区分</td>
              <td class="width-250px">{{ dataDetail.health_guidance_contract_div }}</td>
              <td class="bg-gray width-180px">本人家族区分</td>
              <td class="width-250px">{{ dataDetail.person_familiy_div }}</td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">保健指導機関</td>
              <td class="width-250px">{{ dataDetail.health_guidance_organization_id }}</td>
              <td class="bg-gray width-180px">請求・報告先</td>
              <td class="width-250px">
                [{{ dataDetail.claim_report_to_organ_code }}]
                {{ dataDetail.claim_report_to_organ_name }}
              </td>
              <td class="bg-gray width-180px">請求書 指定送付先</td>
              <td class="width-250px">{{ dataDetail.addressee_organiation_id }}</td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">実施時点</td>
              <td class="width-250px">{{ getClaimTiming(dataDetail.claim_timing_div, dataDetail.support_stop_reason) }}</td>
              <td class="bg-gray width-180px">対象日</td>
              <td class="width-250px">{{ dataDetail.hg_date }}</td>
              <td class="bg-gray width-180px">実施済/計画上</td>
              <td class="width-250px">
                {{ dataDetail.done_support_point }} /
                {{ dataDetail.requried_support_point }}
              </td>
            </tr>
            <tr>
              <td class="bg-gray width-180px">請求日</td>
              <td colspan="5">{{ dataDetail.claim_date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-3 pb-0" style="margin-bottom: -10px;"><span>端数処理区分</span></div>
            <div class="col-3 pb-0" style="margin-bottom: -10px;"><span>契約単価(XMLデータ出力)</span></div>
            <div class="col-6 pb-0" style="margin-bottom: -10px;"><span>契約単価(請求出力)</span></div>
            <div class="col-3 pb-0">
              <select v-model="dataEdit.fractionating_div" class="form-select">
                <option v-for="item in listCodeFraction" :key="item.text + 'item'" :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div class="col-3 pb-0 position-relative">
              <input v-model="dataEdit.contract_unit_price_01" type="number" class="form-control pr-10" placeholder="" />
              <p class="position-absolute unit">円</p>
            </div>
            <div class="col-3 pb-0 position-relative">
              <input v-model="dataEdit.contract_unit_price_02" type="number" class="form-control pr-10" placeholder="" />
              <p class="position-absolute unit">円</p>
            </div>
            <div class="col-3 pb-0"></div>
            <div class="col-3 pt-0 m-0">
              <span class="text-danger">{{ validateDataEdit.fractionating_div }}</span>
            </div>
            <div class="col-3 pt-0 m-0">
              <span class="text-danger">{{ validateDataEdit.contract_unit_price_01 }}</span>
            </div>
            <div class="col-3 pt-0 m-0">
              <span class="text-danger">{{ validateDataEdit.contract_unit_price_02 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 pl-0">支払設定</div>
          <div class="col-8 border">
            <div class="row">
              <div class="col-4 position-relative">
                開始時
                <input disabled v-model="dataEdit.start_pyament_rate" type="number" class="form-control pr-10"
                  placeholder="" />
                <p class="position-absolute percent-one">%</p>
              </div>
              <div class="col-4 position-relative">
                最終評価
                <input disabled v-model="dataEdit.final_eval_payment_rate" type="number" class="form-control pr-10"
                  placeholder="" />
                <p class="position-absolute percent-one">%</p>
              </div>
              <div class="col-4 mb-2 position-relative">
                途中終了時
                <input disabled v-model="dataEdit.retire_payment_rate" type="number" class="form-control pr-10"
                  placeholder="" />
                <p class="position-absolute percent-one">%</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-8 pl-0">
        <div class="row">
          <div class="col-4 position-relative">
            消費税率
            <input v-model="dataEdit.tax_rate" type="number" class="form-control pr-10" placeholder="" />
            <p class="position-absolute percent-one">%</p>
            <span class="text-danger">{{ validateDataEdit.tax_rate }}</span>
          </div>
          <div class="col-4 position-relative">
            消費税 端数処理区分
            <select v-model="dataEdit.tax_fractionating_div" class="form-select">
              <option v-for="item in listCodeFraction" :key="item.text + 'item'" :value="item.value">
                {{ item.text }}
              </option>
            </select>
            <span class="text-danger">{{ validateDataEdit.tax_fractionating_div }}</span>
          </div>
          <div class="col-2">
            <button @click="calculate()" style="background: #4caf50" class="btn text-white mt-5">
              再計算
            </button>
          </div>
        </div>
      </div>

      <div class="col-8 pl-0">
        <div class="row">
          <div class="col-4 position-relative">
            契約単価(XMLデータ出力) 算定額
            <input disabled v-model="dataEdit.calculated_unit_price_01" type="number" class="form-control pr-10"
              placeholder="" />
            <p class="position-absolute percent-one">円</p>
            <span class="text-danger">{{ validateDataEdit.calculated_unit_price_01 }}</span>
          </div>
          <div class="col-4 position-relative">
            契約単価(請求出力) 算定額
            <input disabled v-model="dataEdit.calculated_unit_price_02" type="number" class="form-control pr-10"
              placeholder="" />
            <p class="position-absolute percent-one">円</p>
            <span class="text-danger">{{ validateDataEdit.calculated_unit_price_02 }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-4 position-relative">
            保険者請求額
            <input v-model="dataEdit.insurer_claim_amount" type="number" class="form-control pr-10" placeholder="" />
            <p class="position-absolute percent-one">円</p>
            <span class="text-danger">{{ validateDataEdit.insurer_claim_amount }}</span>
          </div>
          <div class="col-4 position-relative">
            請求出力額
            <input v-model="dataEdit.calculated_amount_02" type="number" class="form-control pr-10" placeholder="" />
            <p class="position-absolute percent-one">円</p>
            <span class="text-danger">{{ validateDataEdit.calculated_amount_02 }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-4 position-relative">
            窓口負担額
            <input disabled v-model="dataEdit.present_own_payment_amount" type="number" class="form-control pr-10"
              placeholder="" />
            <p class="position-absolute percent-one">円</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4 position-relative">
            今回算定額(XMLデータ用)
            <input disabled v-model="dataEdit.calculated_amount_01" type="number" class="form-control pr-10"
              placeholder="" />
            <p class="position-absolute percent-one">円</p>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-11">
          メモ
          <VueEditor v-model="dataEdit.claim_memo_rich"
                  :editorToolbar="vue2EditorCustomize" @blur="validate('memo')" @keyup="validate('memo')" />
          <span class="text-danger">{{ validateDataEdit.claim_memo_rich }}</span>
        </div>
      </div>
    </div>
    <footer>
      <div class="row">
        <div class="col-md-12 d-inline-flex justify-content-between">
          <Button color="default" :rounded="false" @click="hide"><v-icon>mdi-arrow-left</v-icon> キャンセル</Button>
          <Button color="info" :rounded="false" @click="isShowDialogEdit = true"><v-icon>mdi-database</v-icon> 保存</Button>
        </div>
      </div>
    </footer>
    </div>
    <Dialog :maxWidth="600" :isShow="isShowDialogEdit">
      <v-card>
        <v-container>
          <v-card-title>
            確認
          </v-card-title>
          <div class="m-3">保存処理を実行します。よろしいですか？</div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="isShowDialogEdit = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="save()">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import { SUCCESS } from "@/constants";
import InvoiceDataReport from "@/services/api/InvoiceDataReport";
import mixins from "@/mixins";
import { CODE_GENDER, CODE_TIME_OF_MAKING_INVOICE, CODE_REASON_TERMINATE, vue2EditorCustomize, ENVS } from "@/common/constants";
import Dialog from "@/components/common/Dialog";
import { VueEditor } from "vue2-editor";
import Button from "@/components/common/Button";

export default {
    name: "detail",
    mixins: [mixins],
    components: {
        Dialog,
        VueEditor,
        Button
    },
    props: {
        dataDetailId: {
            default: 0,
        },
        checkViewOrEdit: {
            default: false,
        },
    },
    data() {
        return {
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
            vue2EditorCustomize: vue2EditorCustomize,
            isShowDialogEdit: false,
            dataDetail: {
                patient_id: null,
                use_ticket_ref_number: null,
                use_ticket_effective_date: null,
                patient_name_kana: null,
                patient_name: null,
                patient_gender: null,
                patient_birthday: null,
                insured_card_number: null,
                insured_card_symbol: null,
                insured_card_branch_code: null,
                patient_postal_code: null,
                patient_addr_01: null,
                contract_fiscal_year: null,
                insurer_id: null,
                payment_agency_id: null,
                health_guidance_div: null,
                health_guidance_contract_div: null,
                person_familiy_div: null,
                health_guidance_organization_id: null,
                claim_report_to_organ_code: null,
                claim_report_to_organ_name: null,
                addressee_organiation_id: null,
                claim_timing_div: null,
                support_stop_reason: null,
                hg_date: null,
                requried_support_point: null,
                done_support_point: null,
                claim_date: null,
            },
            dataEdit: {
                fractionating_div: 1,
                contract_unit_price_01: 0,
                contract_unit_price_02: 0,
                tax_rate: ENVS.SUPPORT_PROG_WARN_REQED_SUPPORT_UNASSIGNED,
                tax_fractionating_div: ENVS.DEFAULT_TAX_RATE_FRACTIONATING_DIV,
                calculated_unit_price_01: 0,
                calculated_unit_price_02: 0,
                insurer_claim_amount: 0,
                present_own_payment_amount: 0,
                calculated_amount_01: 0,
                calculated_amount_02: 0,
                claim_memo_rich: null,
                pyament_rate: 0,
                start_pyament_rate: 0,
                final_eval_payment_rate: 0,
                retire_payment_rate: 0,
                contract_unit_price_with_tax_01: 0,
                contract_unit_price_with_tax_02: 0,
            },

            listCodeFraction: [],
            code_gender: [],
            insurerArr: [],
            addressee_organiations: [],
            listPaymentAgency: [],
            codeGuidanceDiv: [],
            listContractCode: [],
            listFamilyCode: [],
            code_time_of_making_invoice: [],
            code_reason_terminate: [],
            guidance_organization: [],
            validateDataEdit: {
                calculated_unit_price_01: null,
                calculated_unit_price_02: null,
                tax_rate: null,
                tax_fractionating_div: null,
                insurer_claim_amount: null,
                claim_memo_rich: null,
                contract_unit_price_01: null,
                contract_unit_price_02: null
            },
        };
    },
    watch: {
        dataDetailId() {
            this.getDetail(this.dataDetailId);
        },
        checkViewOrEdit() {
            // console.log(this.checkViewOrEdit);
        },
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        }
    },
    computed: {
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
    },
    async created() {

        this.code_gender = await this.getCodeDefine(CODE_GENDER, "acronym");
        this.code_time_of_making_invoice = await this.getCodeDefine(CODE_TIME_OF_MAKING_INVOICE);
        this.code_reason_terminate = await this.getCodeDefine(CODE_REASON_TERMINATE);

        InvoiceDataReport.getGuidanceOrganization().then((res) => {
            this.guidance_organization = res.data.data;
        })

        InvoiceDataReport.getCodeFraction().then((res) => {
            this.listCodeFraction = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.listCodeFraction.push({
                    text: value,
                    value: parseInt(key),
                });
            }
        });

        InvoiceDataReport.getInsurer().then((res) => {
            this.insurerArr = res.data.data;
        });

        InvoiceDataReport.getAddresseeOrganization().then((res) => {
            this.addressee_organiations = res.data.data;
        })

        InvoiceDataReport.getPaymentRepresentativeOrganization().then((res) => {
            this.listPaymentAgency = [];
            res.data.data.map((x) => {
                this.listPaymentAgency.push({
                    text: `[${x.agency_code}] ${x.agency_name}`,
                    value: x.id,
                });
            });
        });

        InvoiceDataReport.getMedicalIndication().then((res) => {
            this.codeGuidanceDiv = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.codeGuidanceDiv.push({
                    text: value,
                    value: key,
                });
            }
        });

        InvoiceDataReport.getCodeMedicalIndication().then((res) => {
            this.listContractCode = [];
            for (const [key, value] of Object.entries(res.data)) {
                this.listContractCode.push({
                    text: value,
                    value: key,
                });
            }
        });

        InvoiceDataReport.getCodeFamily().then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
                this.listFamilyCode.push({
                    text: value,
                    value: key,
                });
            }
        });

        this.getDetail(this.dataDetailId);
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),
        formatDate(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD");
            }
            return null;
        },
        calculate() {
            this.checkValidate();

            // 税率(パーセントを割合に変換)
            const tax_rate_v = this.dataEdit.tax_rate / 100;

            // 税込単価計算 消費税端数処理区分による端数処理.
            this.dataEdit.contract_unit_price_with_tax_01 = this.processFraction(
                this.dataEdit.tax_fractionating_div,
                this.dataEdit.contract_unit_price_01 * (1.0 + tax_rate_v)
            );
            this.dataEdit.contract_unit_price_with_tax_02 = this.processFraction(
                this.dataEdit.tax_fractionating_div,
                this.dataEdit.contract_unit_price_02 * (1.0 + tax_rate_v)
            );

            // 支払割合
            const payment_rate_v = this.dataEdit.pyament_rate / 100;

            // 算定単価
            let calculated_unit_price_01 = this.dataEdit.contract_unit_price_with_tax_01 * payment_rate_v;
            let calculated_unit_price_02 = this.dataEdit.contract_unit_price_with_tax_02 * payment_rate_v;

            // 途中終了の場合. 満了ポイントに対する実施済みポイントで按分
            if (parseInt(this.dataDetail.claim_timing_div) == 40) {
                let weight = 1.0;
                if (this.dataDetail.done_support_point_v < this.dataDetail.requried_support_point_v) {
                    weight = (this.dataDetail.done_support_point_v / this.dataDetail.requried_support_point_v);
                    weight = Math.floor((weight * 100)) / 100;
                }
                calculated_unit_price_01 = calculated_unit_price_01 * weight;
                calculated_unit_price_02 = calculated_unit_price_02 * weight;
            }

            this.dataEdit.calculated_unit_price_01 = this.processFraction(this.dataEdit.fractionating_div, calculated_unit_price_01);
            this.dataEdit.calculated_unit_price_02 = this.processFraction(this.dataEdit.fractionating_div, calculated_unit_price_02);

            this.dataEdit.insurer_claim_amount = this.dataEdit.calculated_unit_price_01;
            this.dataEdit.calculated_amount_01 = this.dataEdit.calculated_unit_price_01 + this.dataEdit.present_own_payment_amount;
            this.dataEdit.calculated_amount_02 = this.dataEdit.calculated_unit_price_02;
            this.$toast.open({
                message: "再計算処理が完了しました。",
                type: "success",
            });
        },
        processFraction(fractionating_div, v) {

            switch (fractionating_div) {
            case 1:
                return Math.round(v);
            case 2:
                return parseInt(v);
            default:
                return Math.ceil(v);
            }
        },
        getDetail(detailId) {
            this.setIsLoading(true);
            InvoiceDataReport.getDetailCreate(detailId).then((res) => {
                const { data, status } = res;
                this.setIsLoading(false);
                if (status !== SUCCESS) return;
                const result = data.data.data;
                this.dataDetail = {
                    patient_id: result.patient_id,
                    use_ticket_ref_number: result.use_ticket_ref_number,
                    use_ticket_effective_date: result.use_ticket_effective_date,
                    patient_name_kana: result.patient_name_kana,
                    patient_name: result.patient_name,
                    patient_gender: this.code_gender[result.patient_gender],
                    patient_birthday: moment(result.patient_birthday).format("YYYY-MM-DD"),
                    insured_card_number: result.insured_card_number,
                    insured_card_symbol: result.insured_card_symbol,
                    insured_card_branch_code: result.insured_card_branch_code,
                    patient_postal_code: result.patient_postal_code,
                    patient_addr_01: result.patient_addr_01,
                    contract_fiscal_year: result.contract_fiscal_year,
                    insurer_id: this.getInsurer(result.insurer_id),
                    payment_agency_id: this.getPaymentAgency(result.payment_agency_id),
                    health_guidance_div: this.getHealthGuidanceDiv(
                        result.health_guidance_div
                    ),
                    health_guidance_contract_div: this.getHealthGuidanceContract(
                        result.health_guidance_contract_div
                    ),
                    person_familiy_div: this.getFamilyCode(result.person_familiy_div),
                    health_guidance_organization_id: this.getGuidanceOrganization(result.health_guidance_organization_id),
                    claim_report_to_organ_code: result.claim_report_to_organ_code,
                    claim_report_to_organ_name: result.claim_report_to_organ_name,
                    addressee_organiation_id: this.getAddreseeOrganization(result.addressee_organiation_id),
                    claim_timing_div: result.claim_timing_div,
                    support_stop_reason: result.support_stop_reason,
                    hg_date: result.hg_date,
                    requried_support_point: result.claim_prepare_claim?.requried_support_point ?? "ー",
                    done_support_point: result.claim_prepare_claim?.done_support_point ?? "ー",
                    requried_support_point_v: result.claim_prepare_claim?.requried_support_point ?? 0,
                    done_support_point_v: result.claim_prepare_claim?.done_support_point ?? 0,
                    claim_date: result.claim_date,
                };
                const reception = result.health_guidance_reception;
                const dataEdit = result.claim_prepare_claim;
                if (dataEdit) {
                    this.dataEdit = {
                        fractionating_div: parseInt(dataEdit.fractionating_div),
                        contract_unit_price_01: dataEdit.contract_unit_price_01,
                        contract_unit_price_02: dataEdit.contract_unit_price_02,
                        tax_rate: dataEdit.tax_rate,
                        tax_fractionating_div: dataEdit.tax_fractionating_div,
                        calculated_unit_price_01: dataEdit.calculated_unit_price_01,
                        calculated_unit_price_02: dataEdit.calculated_unit_price_02,
                        insurer_claim_amount: dataEdit.insurer_claim_amount,
                        present_own_payment_amount: dataEdit.present_own_payment_amount,
                        calculated_amount_01: dataEdit.calculated_amount_01,
                        calculated_amount_02: dataEdit.calculated_amount_02,
                        claim_memo_rich: dataEdit.claim_memo_rich,
                        pyament_rate: parseInt(dataEdit.pyament_rate),
                        start_pyament_rate: parseInt(dataEdit.start_pyament_rate),
                        final_eval_payment_rate: parseInt(dataEdit.final_eval_payment_rate),
                        retire_payment_rate: parseInt(dataEdit.retire_payment_rate),
                        contract_unit_price_with_tax_01: dataEdit.contract_unit_price_with_tax_01,
                        contract_unit_price_with_tax_02: dataEdit.contract_unit_price_with_tax_01,
                    };
                }
            });
        },
        getGuidanceOrganization(guidance_organization_id) {
            if (guidance_organization_id && guidance_organization_id != undefined) {
                let GuidanceOrganization = this.guidance_organization.filter(
                    (x) => x.id == guidance_organization_id
                )[0];
                return `[${GuidanceOrganization?.organ_code}] ${GuidanceOrganization?.organ_name}`;
            }
            return null;
        },
        getFamilyCode(person_familiy_div) {
            if (person_familiy_div && person_familiy_div != undefined) {
                let familyCode = this.listFamilyCode.filter(
                    (x) => x.value == person_familiy_div
                )[0];
                return familyCode.text;
            }
            return null;
        },
        getHealthGuidanceContract(health_guidance_contract_div) {
            if (
                health_guidance_contract_div &&
        health_guidance_contract_div != undefined
            ) {
                let contractCode = this.listContractCode.filter(
                    (x) => x.value == health_guidance_contract_div
                )[0];
                return contractCode.text;
            }
            return null;
        },
        getHealthGuidanceDiv(code) {
            const value = this.codeGuidanceDiv.filter((x) => x.value == code);
            if (value.length > 0) {
                return value[0].text;
            }
            return null;
        },
        getPaymentAgency(payment_agency_id) {
            if (payment_agency_id && payment_agency_id != undefined) {
                let paymentAgency = this.listPaymentAgency.filter(
                    (x) => x.value == payment_agency_id
                )[0];
                return paymentAgency?.text;
            }
            return null;
        },
        getInsurer(insurer_id) {
            if (insurer_id && insurer_id != undefined) {
                let insurer = this.insurerArr.filter((x) => x.id == insurer_id)[0];
                return `[${insurer.client_organ_code}] ${insurer.client_organ_name}`;
            }
            return null;
        },
        getAddreseeOrganization(addressee_organiation_id) {
            if (addressee_organiation_id && addressee_organiation_id != undefined) {
                let addresseeOrgan = this.addressee_organiations.filter((x) => x.id == addressee_organiation_id)[0];
                return `[${addresseeOrgan.client_organ_code}] ${addresseeOrgan.client_organ_name}`;
            }
            return null;
        },
        getClaimTiming(claim_timing_div, support_stop_reason) {
            let timing_str = this.code_time_of_making_invoice[claim_timing_div];

            if (parseInt(claim_timing_div) == 40) {
                const reason = support_stop_reason ? this.code_reason_terminate[parseInt(support_stop_reason)] : null;
                timing_str += " (" + reason + ")";
            }

            return timing_str;
        },
        checkValidate() {
            this.validateDataEdit.calculated_unit_price_01 = null;
            this.validateDataEdit.calculated_unit_price_02 = null;
            this.validateDataEdit.tax_rate = null;
            this.validateDataEdit.fractionating_div = null;
            this.validateDataEdit.tax_fractionating_div = null;
            this.validateDataEdit.insurer_claim_amount = null;
            this.validateDataEdit.calculated_amount_02 = null;
            this.validateDataEdit.claim_memo_rich = null;
            this.validateDataEdit.contract_unit_price_01 = null;
            this.validateDataEdit.contract_unit_price_02 = null;

            if (this.dataEdit.contract_unit_price_01) {
                if (!String(this.dataEdit.contract_unit_price_01).match(/^[0-9]+$/)) {
                    this.validateDataEdit.contract_unit_price_01 = "契約単価(XMLデータ出力)は半角数字で入力してください。";
                } else if (this.dataEdit.contract_unit_price_01 < 0 || this.dataEdit.contract_unit_price_01 > 99999999) {
                    this.validateDataEdit.contract_unit_price_01 = "契約単価(XMLデータ出力)は[0〜99999999]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.calculated_unit_price_01 = "契約単価(XMLデータ出力)を入力してください。";
            }

            if (this.dataEdit.contract_unit_price_02) {
                if (!String(this.dataEdit.contract_unit_price_02).match(/^[0-9]+$/)) {
                    this.validateDataEdit.contract_unit_price_02 = "契約単価(請求出力)は半角数字で入力してください。";
                } else if (this.dataEdit.contract_unit_price_02 < 0 || this.dataEdit.contract_unit_price_02 > 99999999) {
                    this.validateDataEdit.contract_unit_price_02 = "契約単価(請求出力)は[0〜99999999]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.calculated_unit_price_01 = "契約単価(請求出力)を入力してください。";
            }


            if (this.dataEdit.calculated_unit_price_01) {
                if (this.dataEdit.calculated_unit_price_01 < 0 || this.dataEdit.calculated_unit_price_01 > 99999999) {
                    this.validateDataEdit.calculated_unit_price_01 = "契約単価(XMLデータ出力)は[0〜99999999]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.calculated_unit_price_01 = "契約単価(XMLデータ出力)を入力してください。";
            }

            if (this.dataEdit.calculated_unit_price_02) {
                if (this.dataEdit.calculated_unit_price_02 < 0 || this.dataEdit.calculated_unit_price_02 > 99999999) {
                    this.validateDataEdit.calculated_unit_price_02 = "契約単価(請求出力)は[0〜99999999]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.calculated_unit_price_02 = "契約単価(請求出力)を入力してください。";
            }

            if (this.dataEdit.tax_rate) {
                if (!String(this.dataEdit.tax_rate).match(/^[0-9]+$/)) {
                    this.validateDataEdit.tax_rate = "消費税率は半角数字で入力してください。";
                } else if (this.dataEdit.tax_rate < 0 || this.dataEdit.tax_rate > 99) {
                    this.validateDataEdit.tax_rate = "消費税率は[0〜99]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.tax_rate = "消費税率を入力してください。";
            }

            if (!this.dataEdit.tax_fractionating_div) {
                this.validateDataEdit.tax_fractionating_div = "消費税 端数処理区分を選択してください。";
            }

            if (!this.dataEdit.fractionating_div) {
                this.validateDataEdit.fractionating_div = "端数処理区分を選択してください。";
            }

            if (this.dataEdit.insurer_claim_amount) {

                if (!String(this.dataEdit.insurer_claim_amount).match(/^[0-9]+$/)) {
                    this.validateDataEdit.insurer_claim_amount = "契約単価(XMLデータ出力)は半角数字で入力してください。";
                } else if (this.dataEdit.insurer_claim_amount < 0 || this.dataEdit.insurer_claim_amount > 99999999) {
                    this.validateDataEdit.insurer_claim_amount = "契約単価(XMLデータ出力)は[0〜99999999]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.insurer_claim_amount = "契約単価(XMLデータ出力)を入力してください。";
            }

            if (this.dataEdit.calculated_amount_02) {
                if (!String(this.dataEdit.calculated_amount_02).match(/^[0-9]+$/)) {
                    this.validateDataEdit.calculated_amount_02 = "契約単価(請求出力)は半角数字で入力してください。";
                } else if (this.dataEdit.calculated_amount_02 < 0 || this.dataEdit.calculated_amount_02 > 99999999) {
                    this.validateDataEdit.calculated_amount_02 = "契約単価(請求出力)は[0〜99999999]の範囲で入力してください。";
                }
            } else {
                this.validateDataEdit.calculated_amount_02 = "契約単価(請求出力)を入力してください。";
            }

            if (this.dataEdit.claim_memo_rich && this.dataEdit.claim_memo_rich.length > 8000) {
                this.validateDataEdit.claim_memo_rich = "メモは1000文字以下で入力してください。";
            }

        },
        async save() {
            this.checkValidate();
            this.isShowDialogEdit = false;
            var checkSave = true;
            for (const [key, value] of Object.entries(this.validateDataEdit)) {
                if (value != null) {
                    checkSave = false;
                    break;
                }
            }

            this.dataEdit.claim_memo = jQuery(this.dataEdit.claim_memo_rich).text();

            if (checkSave) {
                this.setIsLoading(true);
                InvoiceDataReport.saveDetailCreate(this.dataDetailId, this.dataEdit).then(
                    (res) => {
                        const { data, status } = res;
                        this.setIsLoading(false);
                        if (status !== SUCCESS) return;
                        this.$toast.open({
                            message: "請求情報の登録が完了しました。",
                            type: "success",
                        });
                        this.$emit("reload");
                    }
                );
            }
        },
        hide() {
            this.hiddenAnimation = true;
            this.$store.commit("hideSlide");
            this.dataDetail = {
                patient_id: null,
                use_ticket_ref_number: null,
                use_ticket_effective_date: null,
                patient_name_kana: null,
                patient_name: null,
                patient_gender: null,
                patient_birthday: null,
                insured_card_number: null,
                insured_card_symbol: null,
                insured_card_branch_code: null,
                patient_postal_code: null,
                patient_addr_01: null,
                contract_fiscal_year: null,
                insurer_id: null,
                payment_agency_id: null,
                health_guidance_div: null,
                health_guidance_contract_div: null,
                person_familiy_div: null,
                health_guidance_organization_id: null,
                claim_report_to_organ_code: null,
                claim_report_to_organ_name: null,
                addressee_organiation_id: null,
                claim_timing_div: null,
                support_stop_reason: null,
                hg_date: null,
                requried_support_point: null,
                done_support_point: null,
                requried_support_point_v: null,
                done_support_point_v: null,
                claim_date: null,
            };
            this.dataEdit = {
                fractionating_div: 1,
                contract_unit_price_01: 0,
                contract_unit_price_02: 0,
                tax_rate: ENVS.SUPPORT_PROG_WARN_REQED_SUPPORT_UNASSIGNED,
                tax_fractionating_div: ENVS.DEFAULT_TAX_RATE_FRACTIONATING_DIV,
                calculated_unit_price_01: 0,
                calculated_unit_price_02: 0,
                insurer_claim_amount: 0,
                present_own_payment_amount: 0,
                calculated_amount_01: 0,
                calculated_amount_02: 0,
                claim_memo_rich: null,
                pyament_rate: 0,
                start_pyament_rate: 0,
                final_eval_payment_rate: 0,
                retire_payment_rate: 0,
                contract_unit_price_with_tax_01: 0,
                contract_unit_price_with_tax_02: 0,
            };
            this.validateDataEdit = {
                calculated_unit_price_01: null,
                calculated_unit_price_02: null,
                tax_rate: null,
                tax_fractionating_div: null,
                insurer_claim_amount: null,
                claim_memo_rich: null,
                contract_unit_price_01: null,
                contract_unit_price_02: null
            };
            // this.getDetail(this.dataDetailId);
        },
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
              direction === "right"
                  ? document.body.scrollWidth - e.clientX
                  : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },
    },
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
.unit
  top: 13px
  right: 12.4px
  font-size: 15px
  background: #e7e7e7
  padding: 7px
  border-radius: 0px 4px 4px 0px
.percent-one
  top: 32px
  right: 12.7px
  font-size: 15px
  background: #e7e7e7
  padding: 7px
  border-radius: 0px 4px 4px 0px

.width-250px
  width: 250px
  max-width: 250px
  min-width: 250px
.width-180px
  width: 180px
  max-width: 180px
  min-width: 180px
.sidebar-show
  animation: show 0.5s ease forwards

.sidebar-hidden
  animation: hidden 0.5s ease forwards

@media (max-width: 1025px)
  .navigation
    transform: translate(0%) !important
  .v-navigation-drawer--close
    visibility: unset

@keyframes hidden
  from
    width: var(widthAnimation)
  to
    width: 0

@keyframes show
  from
    width: 0
  to
    width: var(widthAnimation)
.container-body
  max-height: 85vh
  height: 85vh
  overflow-y: scroll
  overflow-x: hidden
</style>
