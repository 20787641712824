import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_XML_OUTPUT_STATUS, CODE_HEALTH_GUIDANCE_DIV, CODE_TIME_OF_MAKING_INVOICE } from "@/common/constants";

export default {
    async getList(data, page, limit) {
        return await Repository.post("invoice-data-report/not-created", {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },

    async getXMLOutputStatus() {
        try {
            return await Repository.get(`code_define?group=${CODE_XML_OUTPUT_STATUS}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getHalthGuidanceDiv() {
        try {
            return await Repository.get(`code_define?group=${CODE_HEALTH_GUIDANCE_DIV}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getExecutionTime() {
        try {
            return await Repository.get(`code_define?group=${CODE_TIME_OF_MAKING_INVOICE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async whereToIssueInvoiceReport() {
        try {
            return await Repository.get("where-to-issue-invoice-report");
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getHgXmlFileArchiveControl() {
        try {
            return await Repository.get("get-hg-xml-file-archive-control");
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getGuidanceOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-organization/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getListBlockLeft(data) {
        try {
            return await Repository.get("invoice-data-report/search",data);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getBlockLeftDetail(data) {
        try {
            return await Repository.get("invoice-data-report/detail-group",data);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async exportData(bodyRequest = {}) {
        try {
            return await Repository.post("export-data", bodyRequest);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async downloadFile(file_name) {
        try {
            return await Repository.post(`export-data/download?file_name=${file_name}`, {},
                { Accept: "application/zip" },
                {responseType: "arraybuffer"}
            );
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
