<template>
    <div class="list-data-contract">
        <header class="align-items-center pb-3 d-flex flex-row-reverse">
            <div class="row">
                <div class="col-12 text-right pb-0 pt-0 mb-0 mt-0">
                    <a @click="back">
                        <i aria-hidden="true" class="fa fa-undo"></i>
                    </a>
                </div>
                <div class="col-12">
                    <span>契約一覧</span>
                    <div class="row">
                        <div class="col-6">
                            <span class="p-2 border bg-gray">保険者</span>
                            <span class="p-2 border">[{{ clientOrganization.client_organ_code }}] {{ clientOrganization.client_organ_name }}</span>
                        </div>
                        <div class="col-6 text-right">
                            <button @click="getDetail(itemCopy, 'Copy')" :disabled="isCheckButtonCopy" class="btn btn-primary mr-5"><i class="fas fa-plus"></i> 複製</button>
                            <button @click="getDetail(null, 'Add')" class="btn btn-primary"><i class="fas fa-plus"></i> 追加</button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="data-table-container">
            <v-data-table :headers="headers" :items="lists" disable-pagination hide-default-footer
                class="data-table table table-responsive table-hover" id="tableHighlight">
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.id" :id="'highlight' + item.id"
                        @click="changeCellHighlight(item.id)">
                            <td>
                                <span v-if="item.disable_flg">◯</span>
                            </td>
                            <td>
                                <a @click="getDetail(item, 'Detail')" class="text-primary">{{ item.contract_name }}</a>
                            </td>
                            <td>{{ String(item.contract_fiscal_year).slice(-2) }}年度</td>
                            <td>{{ codeHealthGuidanceContractDiv[item.health_guidance_contract_div] }}</td>
                            <td>{{ codeHealthGuidanceDiv[item.health_guidance_div] }}</td>
                            <td>{{ getCodeFamily[item.person_familiy_div] }}</td>
                            <td>[{{ item.health_guidance_organization.organ_code }}]{{ item.health_guidance_organization.organ_name }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
        <slide>
            <ContractDetail v-if="component === 'Detail'" pageMode="EDIT" :dataDetail="itemDetail" :insurer="clientOrganization" :key="randomString" :isShowNavigation="randomString" @reload="reload"></ContractDetail>
            <ContractDetail v-if="component === 'Add'"  pageMode="NEW" :dataDetail="null" :insurer="clientOrganization" :key="randomString" :isShowNavigation="randomString" @reload="reload"></ContractDetail>
            <ContractDetail v-if="component === 'Copy'"  pageMode="NEW" :dataDetail="itemCopy" :insurer="clientOrganization" :key="randomString" :isShowNavigation="randomString" @reload="reload"></ContractDetail>
        </slide>
    </div>
</template>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        &.disable_flg {
            min-width: 80px !important;
        }
        &.contract_fiscal_year {
            min-width: 80px !important;
        }
        &.health_guidance_contract_div {
            min-width: 100px !important;
        }
        &.health_guidance_div {
            min-width: 160px !important;
        }
        &.person_familiy_div {
            min-width: 140px !important;
        }
        &.health_guidance_organization {
            min-width: 300px !important;
        }
    }
</style>
<script>
import partnerManagement from "@/services/api/partnerManagement";
import { CODE_FAMILY, CODE_HEALTH_GUIDANCE_DIV, CODE_HEALTH_GUIDANCE_CONTRACT_DIV, TOAST_TIME_OUT } from "@/common/constants";
import {INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import { mapMutations } from "vuex";
import ContractDetail from "@/pages/partner-management/contract-detail";
import Slide from "@/components/Slide";
import mixins from "@/mixins";
import _ from "lodash";

export default {
    name: "ListContractPartnerManagement",
    mixins: [mixins],
    components: {
        Slide,
        ContractDetail
    },
    data() {
        return {
            headers: [
                { text: "無効", align: "start", sortable: true, value: "disable_flg", class: "disable_flg" },
                { text: "契約名称", sortable: true, value: "contract_name" },
                { text: "年度", sortable: true, value: "contract_fiscal_year", class: "contract_fiscal_year" },
                { text: "契約区分", sortable: true, value: "health_guidance_contract_div", class: "health_guidance_contract_div" },
                { text: "保健指導区分", sortable: true, value: "health_guidance_div", class: "health_guidance_div" },
                { text: "本人家族区分", sortable: true, value: "person_familiy_div", class: "person_familiy_div" },
                { text: "保健指導機関", sortable: true, value: "health_guidance_organization_id", class: "health_guidance_organization" },
            ],
            component: null,
            itemDetail: null,
            itemCopy: null,
            show: false,
            lists: [],
            dataDetail: [],
            getCodeFamily: [],
            codeHealthGuidanceDiv: [],
            codeHealthGuidanceContractDiv: [],
            randomString: "",
            isCheckButtonCopy: true,
        };
    },

    props: {
        clientOrganization: null
    },

    async created() {
        this.getCodeFamily = await this.getCodeDefine(CODE_FAMILY);
        this.codeHealthGuidanceDiv = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_DIV);
        this.codeHealthGuidanceContractDiv = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_CONTRACT_DIV);

        await this.getLists();
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),

        getLists() {
            this.setIsLoading(true)
            partnerManagement.getListContractByClientOrganizationId(this.clientOrganization.id)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const { data, status } = res;
                    this.setIsLoading(false)
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) return;
                    this.lists = data.data;
                })
        },

        getDetail(item, component) {
            this.itemDetail = item;
            this.component = component;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
            this.$store.commit("showSlide");
        },

        back() {
            this.$emit("back");
        },

        reload(param) {
            this.itemDetail = null;
            this.changeCellHighlight(null);
            this.isCheckButtonCopy = true;
            this.component = null;
            if (param) {
                this.getLists();
            }
        },
        changeCellHighlight(id) {
            this.isCheckButtonCopy = false;
            this.itemCopy = this.lists.filter(x => x.id == id)[0];
            $("#tableHighlight").find(".cellHighlight").removeClass("cellHighlight");
            $("#highlight" + id).addClass("cellHighlight");
        },
    }
}
</script>
<style lang="sass" scoped>
  @import "../../styles/common"
  .cellHighlight
    background-color: #d2e3fb
</style>