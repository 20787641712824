<template>
    <v-navigation-drawer class="navigation" ref="drawer" app right hide-overlay :width="navigation.width" :class="{ 'sidebar-show' : showAnimation, 'sidebar-hidden' : hiddenAnimation }">
      <div class="container-fluid">
        <div>
          <v-card-actions class="d-flex pl-0 pr-0">
            <div class="col-6 p-0">
              <span class="p-2 border bg-gray">保険者</span>
              <span class="p-2 border">[{{ insurer.client_organ_code }}] {{insurer.client_organ_name}}
              </span>
            </div>
            <div v-if="isPageMode_Edit()" class="col-6 text-right p-0">
              <Button @click="isShowDialog = true" color="error" :rounded="false">
                <v-icon>mdi-trash-can-outline</v-icon>
                削除
              </Button>
            </div>
          </v-card-actions>
          <div class="col-12 border tab-eligible-person-information">
            <div class="row">
              <div class="col-12">
                <div>
                  <div class="float-right">
                    <div class="d-flex">
                      <div style="width:300px">登録情報</div>
                      <div style="width:300px">更新情報</div>
                    </div>
                    <div class="d-flex">
                      <Sheet width="300" height="40" class="p-2">
                        {{ formatDate(dataEdit.created_at) }} <span v-if="dataEdit.created_by">by {{ dataEdit.created_by ? String(dataEdit.created_by).padStart(7, '0') : ''}}</span>
                      </Sheet>
                      <Sheet width="300" height="40" class="p-2">
                        {{ formatDate(dataEdit.updated_at) }} <span v-if="dataEdit.updated_by">by {{ dataEdit.updated_by ? String(dataEdit.updated_by).padStart(7, '0') : ''}}</span>
                      </Sheet>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row ml-1 align-items-center">
                <div class="col-1 mt-2 form-check">
                  <label class="form-check-label" for="disable_flg">無効</label>
                  <input v-model="dataEdit.disable_flg" type="checkbox" class="form-check-input" id="disable_flg" />
                </div>
                <div class="col-3">
                  <span class="required">表示順</span>
                  <input type="text" v-model="dataEdit.disp_seq" class="form-control" />
                  <span class="text-danger">{{ errors.disp_seq }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-5">
                  <span class="required">契約名称</span>
                  <input type="text" v-model="dataEdit.contract_name" class="form-control" />
                  <span class="text-danger">{{ errors.contract_name }}</span>
                </div>
                <div class="col-4">
                  <label class="form-check-label required" for="default_flag">支援担当外 参照可能レベル</label>
                  <select class="form-select" v-model="dataEdit.out_of_support_patient_ref_level">
                    <option v-for="(item, index) in out_of_support_patient_ref_level_arr" :value="item.value"
                      :key="index.text">{{ item.text }}</option>
                  </select>
                  <span class="text-danger">{{ errors.out_of_support_patient_ref_level }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="form-check-label required" for="contract_fiscal_year">年度</label>
                  <date-picker autocomplete="off"
                    v-model="dataEdit.contract_fiscal_year"
                    id="contract_fiscal_year"
                    name="contract_fiscal_year"
                    :config="year"
                  ></date-picker>
                  <span class="text-danger">{{ errors.contract_fiscal_year }}</span>
                </div>
                <div class="col-3">
                  <label class="form-check-label required" for="default_flag">契約区分</label>
                  <select class="form-select" v-model="dataEdit.health_guidance_contract_div">
                    <option v-for="(item, index) in codeHealthGuidanceContractDiv" :value="item.value"
                      :key="index.text">{{ item.text }}</option>
                  </select>
                  <span class="text-danger">{{ errors.health_guidance_contract_div }}</span>
                </div>
                <div class="col-3">
                  <label class="form-check-label required" for="default_flag">保健指導区分</label>
                  <select class="form-select" v-model="dataEdit.health_guidance_div">
                    <option v-for="(item, index) in codeHealthGuidanceDiv" :value="item.value"
                      :key="index.text">{{ item.text }}</option>
                  </select>
                  <span class="text-danger">{{ errors.health_guidance_div }}</span>
                </div>
                <div class="col-3">
                  <label class="form-check-label required" for="default_flag">本人家族区分</label>
                  <div class="form-check mt-4 d-flex flex-column">
                    <div class="d-flex">
                      <div
                          v-for="(item, index) in getCodeFamily"
                          :key="item.value + index + 'person_familiy_div'"
                      >
                        <input
                            class="form-check-input"
                            v-model="dataEdit.person_familiy_div"
                            :value="item.value"
                            type="radio"
                            :id="'flexRadioDefault1' + item.value + 'person_familiy_div'"
                        />
                        <label
                            class="mr-8 form-check-label"
                            :for="'flexRadioDefault1' + item.value + 'person_familiy_div'"
                        >
                          {{ item.text }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <span class="text-danger">{{ errors.person_familiy_div }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                    <label class="form-check-label required" for="default_flag">支援パターン</label>
                    <select class="form-select" v-model="dataEdit.health_support_pattern_id">
                      <option v-for="(item, index) in listSupportPattern" :value="item.value"
                        :key="index.text">{{ item.text }}</option>
                    </select>
                    <span class="text-danger">{{ errors.health_support_pattern_id }}</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="form-check-label required" for="default_flag">初回面接形態</label>
                  <select class="form-select" v-model="dataEdit.first_support_form_id">
                    <option v-for="(item, index) in listStatusInitialPreview" :value="item.value"
                      :key="index.text">{{ item.text }}</option>
                  </select>
                  <span class="text-danger">{{ errors.first_support_form_id }}</span>
                </div>
                <div class="col-3">
                  <label class="form-check-label required" for="continued_support_term">継続支援週数</label>
                  <input v-model="dataEdit.continued_support_term" type="text" class="form-control" id="continued_support_term" />
                  <span class="text-danger">{{ errors.continued_support_term }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                    <label class="form-check-label required" for="default_flag">保健指導機関</label>
                    <select class="form-select" v-model="dataEdit.health_guidance_organization_id">
                      <option v-for="(item, index) in listHealthAgency" :value="item.value"
                        :key="index.text">{{ item.text }}</option>
                    </select>
                    <span class="text-danger">{{ errors.health_guidance_organization_id }}</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-3">
                    <label class="form-check-label" for="default_flag">支払代行機関</label>
                    <select class="form-select" v-model="dataEdit.payment_agency_id">
                      <option v-for="(item, index) in listPaymentAgency" :value="item.value"
                        :key="index.text">{{ item.text }}</option>
                    </select>
                    <span class="text-danger">{{ errors.payment_agency_id }}</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-3">
                    <label class="form-check-label" for="default_flag">契約取りまとめ機関</label>
                    <select class="form-select" v-model="dataEdit.contract_coord_organization_id">
                      <option v-for="(item, index) in listContractAgency" :value="item.value"
                        :key="index.text">{{ item.text }}</option>
                    </select>
                    <span class="text-danger">{{ errors.contract_coord_organization_id }}</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-3">
                    <label class="form-check-label" for="default_flag">送付先機関</label>
                    <select class="form-select" v-model="dataEdit.addressee_organiation_id">
                      <option v-for="(item, index) in listDestinationInstitution" :value="item.value"
                        :key="index.text">{{ item.text }}</option>
                    </select>
                    <span class="text-danger">{{ errors.addressee_organiation_id }}</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-3">
                    <label class="form-check-label" for="default_flag">対象者入力フォームレイアウト</label>
                    <select class="form-select" v-model="dataEdit.client_organization_form_layout_control_id">
                      <option v-for="(item, index) in client_organization_form_layout_control_arr" :value="item.value"
                        :key="index.text">{{ item.text }}</option>
                    </select>
                  </div>
                  <div class="col-3">
                    <a @click="editFormLayoutControl()" class="text-primary">
                      <i class="fa fa-pencil-square text-primary mr-3 fa-2x" aria-hidden="true"></i>
                    </a>
                    <a @click="addFormLayoutControl()" class="text-primary">
                      <i class="fa fa-plus-circle text-primary fa-2x mt-7"  aria-hidden="true"></i>
                    </a>
                  </div>
                  <span class="text-danger">{{ errors.client_organization_form_layout_control_id }}</span>
              </div>
              <div class="row">
                <div class="col-3">
                  <label class="form-check-label required" for="fractionating_div">端数処理区分</label>
                  <select class="form-select" v-model="dataEdit.fractionating_div">
                    <option v-for="(item, index) in listFractionatingDiv" :value="item.value"
                      :key="index.text">{{ item.text }}</option>
                  </select>
                  <span class="text-danger">{{ errors.fractionating_div }}</span>
                </div>
                <div class="col-3">
                  <label class="form-check-label required" for="contract_unit_price_01">契約単価(XMLデータ出力)</label>
                  <div class="position-relative">
                    <input type="text" v-model="dataEdit.contract_unit_price_01" class="form-control pr-10" />
                    <p class="position-absolute percent">円</p>
                  </div>
                  <span class="text-danger">{{ errors.contract_unit_price_01 }}</span>
                </div>
                <div class="col-3">
                  <label class="form-check-label required" for="contract_unit_price_02">契約単価(請求出力)</label>
                  <div class="position-relative">
                    <input type="text" v-model="dataEdit.contract_unit_price_02" class="form-control pr-10" />
                    <p class="position-absolute percent">円</p>
                  </div>
                  <span class="text-danger">{{ errors.contract_unit_price_02 }}</span>
                </div>
              </div>
              <div class="col-12">
                <label class="form-check-label mt-3" for="">支払設定</label>
                <div class="row border">
                  <div class="col-3">
                    <label class="form-check-label required" for="start_pyament_rate">開始時</label>
                    <div class="position-relative">
                      <input type="text" v-model="dataEdit.start_pyament_rate" class="form-control pr-10" />
                      <p class="position-absolute percent">%</p>
                    </div>
                    <span class="text-danger">{{ errors.start_pyament_rate }}</span>
                  </div>
                  <div class="col-3">
                    <label class="form-check-label required" for="final_eval_payment_rate">最終評価</label>
                    <div class="position-relative">
                      <input type="text" v-model="dataEdit.final_eval_payment_rate" class="form-control pr-10" />
                      <p class="position-absolute percent">%</p>
                    </div>
                    <span class="text-danger">{{ errors.final_eval_payment_rate }}</span>
                  </div>
                  <div class="col-3">
                    <label class="form-check-label required" for="retire_payment_rate">途中終了時</label>
                    <div class="position-relative">
                      <input type="text" v-model="dataEdit.retire_payment_rate" class="form-control pr-10" />
                      <p class="position-absolute percent">%</p>
                    </div>
                    <span class="text-danger">{{ errors.retire_payment_rate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer>
            <div class="row">
              <div class="col-md-12 d-inline-flex justify-content-between">
                <button @click="hide" type="button" class="btn btn-secondary">
                  <i class="fa fa-arrow-left" aria-hidden="true"></i>
                  キャンセル
                </button>
                <button @click="isShowDialogSave = true" type="button" class="btn btn-primary">
                  <i class="fa fa-database" aria-hidden="true"></i>
                  保存
                </button>
              </div>
            </div>
          </footer>

          <Dialog v-if="isPageMode_Edit()" :maxWidth="600" :isShow="isShowDialog">
              <v-card>
              <v-container>
                  <v-card-title>削除</v-card-title>
                  <div class="m-3">削除処理を実行します。よろしいですか？</div>
                  <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                  <button type="button" class="btn btn-secondary" @click="isShowDialog = false">
                      キャンセル
                  </button>
                  <button type="button" class="btn btn-primary" @click="deleteItem(dataEdit.id)">
                      OK
                  </button>
                  </v-card-actions>
              </v-container>
              </v-card>
          </Dialog>
          <Dialog :maxWidth="600" :isShow="isShowDialogSave">
              <v-card>
              <v-container>
                  <v-card-title>保存</v-card-title>
                  <div class="m-3">保存処理を実行します。よろしいですか？</div>
                  <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                  <button type="button" class="btn btn-secondary" @click="isShowDialogSave = false">
                      キャンセル
                  </button>
                  <button type="button" class="btn btn-primary" @click="save()">
                      OK
                  </button>
                  </v-card-actions>
              </v-container>
              </v-card>
          </Dialog>
          <Dialog :maxWidth="600" :isShow="isShowDialogEditFormLayoutControl">
              <v-card>
              <v-container v-if="dataFormLayoutControl">
                  <v-card-title></v-card-title>
                  <div class="col-12">
                      <label class="form-check-label required" for="layout_name">レイアウト名</label>
                      <input v-model="dataFormLayoutControl.layout_name" type="text" class="form-control" id="layout_name" />
                      <span class="text-danger">{{ errorFormLayoutControl.layout_name }}</span>
                  </div>
                  <div class="col-12">
                      <label class="form-check-label" for="patient_eval_form_title_01">対象者入力フォーム タイトル１</label>
                      <input v-model="dataFormLayoutControl.patient_eval_form_title_01" type="text" class="form-control" id="patient_eval_form_title_01" />
                      <span class="text-danger">{{ errorFormLayoutControl.patient_eval_form_title_01 }}</span>
                  </div>
                  <div class="col-12">
                      <label class="form-check-label" for="patient_eval_form_title_02">対象者入力フォーム タイトル２</label>
                      <input v-model="dataFormLayoutControl.patient_eval_form_title_02" type="text" class="form-control" id="patient_eval_form_title_02" />
                      <span class="text-danger">{{ errorFormLayoutControl.patient_eval_form_title_02 }}</span>
                  </div>
                  <div class="col-12">
                      <label class="form-check-label" for="patient_eval_form_desc_01">対象者入力フォーム 説明</label>
                      <textarea v-model="dataFormLayoutControl.patient_eval_form_desc_01" class="form-control" rows="5"></textarea>
                      <span class="text-danger">{{ errorFormLayoutControl.patient_eval_form_desc_01 }}</span>
                  </div>
                  <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                  <button type="button" class="btn btn-secondary" @click="isShowDialogEditFormLayoutControl = false">
                      キャンセル
                  </button>
                  <button @click="deleteEditFormLayoutControl()" type="button" class="btn btn-danger">
                      削除
                  </button>
                  <button @click="saveEditFormLayoutControl()" type="button" class="btn btn-primary">
                      変更
                  </button>
                  </v-card-actions>
              </v-container>
              </v-card>
          </Dialog>
          <Dialog :maxWidth="600" :isShow="isShowDialogAddFormLayoutControl">
              <v-card>
              <v-container>
                  <v-card-title></v-card-title>
                  <div class="col-12">
                      <label class="form-check-label required" for="layout_name">レイアウト名(新規)</label>
                      <input v-model="dataFormLayoutControl.layout_name" type="text" class="form-control" id="dataFormLayoutControl_layout_name" />
                      <span class="text-danger">{{ errorFormLayoutControl.layout_name }}</span>
                  </div>
                  <div class="col-12">
                      <label class="form-check-label" for="patient_eval_form_title_01">対象者入力フォーム タイトル１</label>
                      <input v-model="dataFormLayoutControl.patient_eval_form_title_01" type="text" class="form-control" id="patient_eval_form_title_01" />
                      <span class="text-danger">{{ errorFormLayoutControl.patient_eval_form_title_01 }}</span>
                  </div>
                  <div class="col-12">
                      <label class="form-check-label" for="patient_eval_form_title_02">対象者入力フォーム タイトル２</label>
                      <input v-model="dataFormLayoutControl.patient_eval_form_title_02" type="text" class="form-control" id="patient_eval_form_title_02" />
                      <span class="text-danger">{{ errorFormLayoutControl.patient_eval_form_title_02 }}</span>
                  </div>
                  <div class="col-12">
                      <label class="form-check-label" for="patient_eval_form_desc_01">対象者入力フォーム 説明</label>
                      <textarea v-model="dataFormLayoutControl.patient_eval_form_desc_01" class="form-control" rows="5"></textarea>
                      <span class="text-danger">{{ errorFormLayoutControl.patient_eval_form_desc_01 }}</span>
                  </div>
                  <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
                  <button type="button" class="btn btn-secondary" @click="isShowDialogAddFormLayoutControl = false">
                      キャンセル
                  </button>
                  <button @click="saveAddFormLayoutControl()" type="button" class="btn btn-primary">
                      保存
                  </button>
                  </v-card-actions>
              </v-container>
              </v-card>
          </Dialog>
        </div>
      </div>
    </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import { OUT_OF_SUPPORT_PATIENT_REF_LEVEL, CODE_HEALTH_GUIDANCE_CONTRACT_DIV, CODE_HEALTH_GUIDANCE_DIV, CODE_FAMILY, TOAST_TIME_OUT } from "@/common/constants";
import { INTERNAL_SERVER_ERROR, SUCCESS} from "@/constants";
import partnerManagement from "@/services/api/partnerManagement";
import mixins from "@/mixins";
import _ from "lodash";
import Sheet from "@/components/common/Sheet";
import Button from "@/components/common/Button";
import Dialog from "@/components/common/Dialog";

export default {
    name: "contract-detail",
    mixins: [mixins],
    components: {
        Sheet,
        Button,
        Dialog
    },
    props: {
        dataDetail: null,
        insurer: null,
        pageMode: null, // "NEW":新規、"EDIT":編集
    },
    data() {
        return {
            page_mode: "NEW",
            isShowDialogSave: false,
            isShowDialog: false,
            isShowDialogEditFormLayoutControl: false,
            isShowDialogAddFormLayoutControl: false,
            dataFormLayoutControl: {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            },
            dataEdit: {
                disable_flg: false,
                disp_seq: 0,
                contract_name: null,
                out_of_support_patient_ref_level: 0,
                contract_fiscal_year: String(this.getFiscalYear()),
                health_guidance_contract_div: null,
                health_guidance_div: null,
                person_familiy_div: 1,
                health_support_pattern_id: null,
                first_support_form_id: null,
                continued_support_term: null,
                health_guidance_organization_id: null,
                payment_agency_id: null,
                contract_coord_organization_id: null,
                addressee_organiation_id: null,
                fractionating_div: 1,
                contract_unit_price_01: 0,
                contract_unit_price_02: 0,
                start_pyament_rate: 0,
                final_eval_payment_rate: 0,
                retire_payment_rate: 0,
                client_organization_form_layout_control_id: null
            },
            isUpdate: false,
            errors: {
                disp_seq: null,
                contract_name: null,
                out_of_support_patient_ref_level: null,
                contract_fiscal_year: null,
                health_guidance_contract_div: null,
                health_guidance_div: null,
                person_familiy_div: null,
                health_support_pattern_id: null,
                first_support_form_id: null,
                continued_support_term: null,
                health_guidance_organization_id: null,
                payment_agency_id: null,
                contract_coord_organization_id: null,
                addressee_organiation_id: null,
                fractionating_div: null,
                contract_unit_price_01: null,
                contract_unit_price_02: null,
                start_pyament_rate: null,
                final_eval_payment_rate: null,
                retire_payment_rate: null,
                client_organization_form_layout_control_id: null
            },
            errorFormLayoutControl: {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            },
            out_of_support_patient_ref_level_arr: [],
            codeHealthGuidanceContractDiv: [],
            codeHealthGuidanceDiv: [],
            getCodeFamily: [],
            listSupportPattern: [],
            listStatusInitialPreview: [],
            listHealthAgency: [],
            listPaymentAgency: [],
            listContractAgency: [],
            listDestinationInstitution: [],
            client_organization_form_layout_control_arr: [],
            listFractionatingDiv: [],
            navigation: {
                width: "80%",
                borderSize: 3
            },
            showAnimation: false,
            hiddenAnimation: false,
            randomString: "",
        };
    },
    watch: {
        isShowNavigation() {
            this.hiddenAnimation = false;
            this.showAnimation = true;

            $(".navigation").one("animationend", () => {
                this.showAnimation = false;
            });
        },
        dataDetail() {
            if(this.dataDetail){
                this.dataEdit = {
                    disable_flg: false,
                    disp_seq: 0,
                    contract_name: null,
                    out_of_support_patient_ref_level: null,
                    contract_fiscal_year: null,
                    health_guidance_contract_div: null,
                    health_guidance_div: null,
                    person_familiy_div: null,
                    health_support_pattern_id: null,
                    first_support_form_id: null,
                    continued_support_term: null,
                    health_guidance_organization_id: null,
                    payment_agency_id: null,
                    contract_coord_organization_id: null,
                    addressee_organiation_id: null,
                    fractionating_div: null,
                    contract_unit_price_01: null,
                    contract_unit_price_02: null,
                    start_pyament_rate: null,
                    final_eval_payment_rate: null,
                    retire_payment_rate: null,
                    client_organization_form_layout_control_id: null
                };
                this.errors = {
                    disp_seq: null,
                    contract_name: null,
                    out_of_support_patient_ref_level: null,
                    contract_fiscal_year: null,
                    health_guidance_contract_div: null,
                    health_guidance_div: null,
                    person_familiy_div: null,
                    health_support_pattern_id: null,
                    first_support_form_id: null,
                    continued_support_term: null,
                    health_guidance_organization_id: null,
                    payment_agency_id: null,
                    contract_coord_organization_id: null,
                    addressee_organiation_id: null,
                    fractionating_div: null,
                    contract_unit_price_01: null,
                    contract_unit_price_02: null,
                    start_pyament_rate: null,
                    final_eval_payment_rate: null,
                    retire_payment_rate: null,
                    client_organization_form_layout_control_id: null
                };
                this.getDetail(this.dataDetail);
            }
        }
    },
    async created() {

        this.page_mode = this.pageMode;

        await this.getOptionSelects();
        if (this.dataDetail) {
            await this.getDetail(this.dataDetail);
            if (this.isPageMode_New()) {
                delete this.dataEdit.id;
                delete this.dataEdit.created_by;
                delete this.dataEdit.updated_by;
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "common/getUser"
        }),
        year() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        cssWidth() {
            return {
                "widthAnimation": this.navigation.width,
            }
        }
    },
    mounted() {
        this.setBorderWidth();
        this.setEvents();
        this.showAnimation = true;
        this.hiddenAnimation = false;
    },
    methods: {
        /**
         * 
         */
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * 日付フォーマット
         * @param {*} date 
         */
        formatDate(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },

        /**
         * 詳細情報設定.
         * @param {*} dataDetail 
         */
        getDetail(dataDetail) {
            this.dataEdit = null;
            this.dataEdit = { ...dataDetail };
            if (this.isPageMode_New()) {
                this.dataEdit.disp_seq = 0;
                this.dataEdit.disable_flg = 0;
                this.dataEdit.created_at = "";
                this.dataEdit.created_by = null;
                this.dataEdit.updated_at = "";
                this.dataEdit.updated_by = null;
            }
            this.dataEdit.contract_fiscal_year = String(this.dataEdit.contract_fiscal_year);
            this.dataEdit.fractionating_div = parseInt(this.dataEdit.fractionating_div);
        },

        /**
         * 契約情報削除
         * @param {*} id 
         */
        deleteItem(id) {
            this.isShowDialog = false;
            this.setIsLoading(true)
            partnerManagement.deleteClientOrganizationContract(id)
                .then((res) => {
                    this.setIsLoading(false)
                    const { data, status } = res;
                    this.setIsLoading(false)
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) {
                        this.$toast.open({
                            message: res.error.message,
                            type: "error",
                        });
                    } else {
                        this.$toast.open({
                            message: "取引先契約情報の削除が完了しました。",
                            type: "success",
                        });
                        this.isUpdate = true;
                        this.hide();
                    }
                })
        },

        /**
         * バリデーション.
         */
        checkValidate() {
            this.errors = {
                disp_seq: null,
                contract_name: null,
                out_of_support_patient_ref_level: null,
                contract_fiscal_year: null,
                health_guidance_contract_div: null,
                health_guidance_div: null,
                person_familiy_div: null,
                health_support_pattern_id: null,
                first_support_form_id: null,
                continued_support_term: null,
                health_guidance_organization_id: null,
                payment_agency_id: null,
                contract_coord_organization_id: null,
                addressee_organiation_id: null,
                fractionating_div: null,
                contract_unit_price_01: null,
                contract_unit_price_02: null,
                start_pyament_rate: null,
                final_eval_payment_rate: null,
                retire_payment_rate: null,
                client_organization_form_layout_control_id: null
            };

            if (this.dataEdit.disp_seq) {
                if (!String(this.dataEdit.disp_seq).match(/^[0-9]+$/)) {
                    this.errors.disp_seq = "表示順は数字で入力してください。";
                } else if (this.dataEdit.disp_seq > 999 || this.dataEdit.disp_seq < 0) {
                    this.errors.disp_seq = "表示順は[0〜999]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.disp_seq !== 0){
                    this.errors.disp_seq = "表示順を入力してください。";
                }
            }
 
            if (!this.dataEdit.contract_name) {
                this.errors.contract_name = "契約名称を入力してください。";
            }else if(this.dataEdit.contract_name.length > 60){
                this.errors.contract_name = "契約名称は60文字以下で入力してください。";
            }

            if (!this.dataEdit.out_of_support_patient_ref_level && this.dataEdit.out_of_support_patient_ref_level != 0) {
                this.errors.out_of_support_patient_ref_level = "支援担当外 参照可能レベルを選択してください。";
            }
            
            if (!this.dataEdit.contract_fiscal_year) {
                this.errors.contract_fiscal_year = "年度を選択してください。";
            }

            if (!this.dataEdit.health_guidance_contract_div) {
                this.errors.health_guidance_contract_div = "契約区分を選択してください。";
            }

            if (!this.dataEdit.health_guidance_div) {
                this.errors.health_guidance_div = "保健指導区分を選択してください。";
            }

            if (!this.dataEdit.person_familiy_div) {
                this.errors.person_familiy_div = "本人家族区分を選択してください。";
            }

            if (!this.dataEdit.health_support_pattern_id) {
                this.errors.health_support_pattern_id = "支援パターンを選択してください。";
            }

            if (!this.dataEdit.first_support_form_id) {
                this.errors.first_support_form_id = "初回面接形態を選択してください。";
            }

            if (this.dataEdit.continued_support_term) {
                if (!String(this.dataEdit.continued_support_term).match(/^[0-9]+$/)) {
                    this.errors.continued_support_term = "継続支援週数は半角数字で入力してください。";
                } else if (this.dataEdit.continued_support_term > 9999 || this.dataEdit.continued_support_term < 1) {
                    this.errors.continued_support_term = "継続支援週数は[1〜9999]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.continued_support_term !== 0){
                    this.errors.continued_support_term = "継続支援週数を入力してください。";
                }
            }

            if (!this.dataEdit.health_guidance_organization_id) {
                this.errors.health_guidance_organization_id = "保健指導機関を選択してください。";
            }

            if (!this.dataEdit.fractionating_div) {
                this.errors.fractionating_div = "端数処理区分を選択してください。";
            }

            if (this.dataEdit.contract_unit_price_01) {
                if (!String(this.dataEdit.contract_unit_price_01).match(/^[0-9]+$/)) {
                    this.errors.contract_unit_price_01 = "契約単価は半角数字で入力してください。";
                } else if (this.dataEdit.contract_unit_price_01 > 999999 || this.dataEdit.contract_unit_price_01 < 0) {
                    this.errors.contract_unit_price_01 = "契約単価(XMLデータ出力)は[0〜999999]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.contract_unit_price_01 !== 0){
                    this.errors.contract_unit_price_01 = "契約単価(XMLデータ出力)を入力してください。";
                }
            }

            if (this.dataEdit.contract_unit_price_02) {
                if (!String(this.dataEdit.contract_unit_price_02).match(/^[0-9]+$/)) {
                    this.errors.contract_unit_price_02 = "契約単価は半角数字で入力してください。";
                } else if (this.dataEdit.contract_unit_price_02 > 999999 || this.dataEdit.contract_unit_price_02 < 0) {
                    this.errors.contract_unit_price_02 = "契約単価(XMLデータ出力)は[0〜999999]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.contract_unit_price_02 !== 0){
                    this.errors.contract_unit_price_02 = "契約単価(XMLデータ出力)を入力してください。";
                }
            }

            if (this.dataEdit.start_pyament_rate) {
                if (!String(this.dataEdit.start_pyament_rate).match(/^[0-9]+$/)) {
                    this.errors.start_pyament_rate = "開始時は半角数字で入力してください。";
                } else if (this.dataEdit.start_pyament_rate > 100 || this.dataEdit.start_pyament_rate < 0) {
                    this.errors.start_pyament_rate = "支払設定 開始時は[0〜100]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.start_pyament_rate !== 0){
                    this.errors.start_pyament_rate = "支払設定 開始時を入力してください。";
                }
            }

            if (this.dataEdit.final_eval_payment_rate) {
                if (!String(this.dataEdit.final_eval_payment_rate).match(/^[0-9]+$/)) {
                    this.errors.final_eval_payment_rate = "最終評価は半角数字で入力してください。。";
                } else if (this.dataEdit.final_eval_payment_rate > 100 || this.dataEdit.final_eval_payment_rate < 0) {
                    this.errors.final_eval_payment_rate = "支払設定 最終評価は[0〜100]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.final_eval_payment_rate !== 0){
                    this.errors.final_eval_payment_rate = "支払設定 最終評価を入力してください。";
                }
            }

            if (this.dataEdit.retire_payment_rate) {
                if (!String(this.dataEdit.retire_payment_rate).match(/^[0-9]+$/)) {
                    this.errors.retire_payment_rate = "途中終了時は半角数字で入力してください。";
                } else if (this.dataEdit.retire_payment_rate > 100 || this.dataEdit.retire_payment_rate < 0) {
                    this.errors.retire_payment_rate = "支払設定 途中終了時は[0〜100]の範囲で入力してください。";
                }
            } else {
                if(this.dataEdit.retire_payment_rate !== 0){
                    this.errors.retire_payment_rate = "支払設定 途中終了時を入力してください。";
                }
            }
        },

        /**
         * 取引先契約情報 保存.
         */
        async save() {
            this.isShowDialogSave = false;
            this.checkValidate();
            var checkValidate = true;
            for (const [key, value] of Object.entries(this.errors)) {
                if (value != null) {
                    checkValidate = false;
                    break;
                }
            }

            if (checkValidate) {
                this.setIsLoading(true);
                if (this.isPageMode_New()) {
                    await this.storeContract();
                } else {
                    await this.updateContract();
                }
            }
        },

        /**
         * 契約情報保存(新規追加)
         */
        async storeContract() {
            this.dataEdit.client_organization_id = this.insurer.id;
            partnerManagement.storeClientOrganizationContract(this.dataEdit).then((res) => {
                this.setIsLoading(false)
                const { data, status } = res;
                if(res.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                    return;
                }
                if (status !== SUCCESS) {
                    this.$toast.open({
                        message: res.error.message,
                        type: "error",
                    });
                } else {
                    this.$toast.open({
                        message: "取引先契約情報の登録が完了しました。",
                        type: "success",
                    });
                  
                    this.dataEdit = data.data.clientOrganizationContract;
                    this.dataEdit.out_of_support_patient_ref_level = data.data.clientOrganizationContract.out_of_support_patient_ref_level;
                    this.page_mode = "EDIT";
                    this.isUpdate = true;
                }
            });
        },

        /**
         * 契約情報保存(更新)
         */
        async updateContract() {
            partnerManagement.updateClientOrganizationContract(this.dataEdit.id, this.dataEdit).then((res) => {
                this.setIsLoading(false)
                const { data, status } = res;
                if(res.errorCode === INTERNAL_SERVER_ERROR) {
                    this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
                    return;
                }
                if (status !== SUCCESS) {
                    this.$toast.open({
                        message: res.error.message,
                        type: "error",
                    });
                } else {
                    this.$toast.open({
                        message: "取引先契約情報の登録が完了しました。",
                        type: "success",
                    });

                    this.dataEdit.updated_at = data.data.clientOrganizationContract.updated_at;
                    this.dataEdit.updated_by = data.data.clientOrganizationContract.updated_by;
                    this.isUpdate = true;
                }
            });
        },

        /**
         * フォームレイアウト制御 削除
         */
        deleteEditFormLayoutControl(){
            this.setIsLoading(true)
            this.isShowDialogEditFormLayoutControl = false;
            partnerManagement.deleteFormLayoutControl(this.dataFormLayoutControl.id).then((res) => {
                this.setIsLoading(false)
                const { data, status } = res;
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                if (status !== SUCCESS) {
                    this.$toast.open({
                        message: res.error.message,
                        type: "error",
                    });
                } else {
                    this.client_organization_form_layout_control_arr = this.client_organization_form_layout_control_arr.filter(x => x.id != this.dataFormLayoutControl.id);
                    this.$toast.open({
                        message: `レイアウト[${this.dataFormLayoutControl.layout_name}]の削除が完了しました。`,
                        type: "success",
                    });
                }
            });
        },

        /**
         * フォームレイアウト制御 バリデーション
         * @param {*} action 
         */
        checkValidateFormLayoutControl(action){
            this.errorFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };
            if(!this.dataFormLayoutControl.layout_name){
                this.errorFormLayoutControl.layout_name = `${action == "add" ? "レイアウト名(新規)" : "レイアウト名"}を入力してください。`;
            }else if(this.dataFormLayoutControl.layout_name.length > 20){
                this.errorFormLayoutControl.layout_name = `${action == "add" ? "レイアウト名(新規)" : "レイアウト名"}は20文字以下で入力してください。`;
            }

            if (this.dataFormLayoutControl.patient_eval_form_title_01 && this.dataFormLayoutControl.patient_eval_form_title_01.length > 60 ){
                this.errorFormLayoutControl.patient_eval_form_title_01 = "対象者入力フォーム タイトル１は60文字以下で入力してください。";
            }

            if (this.dataFormLayoutControl.patient_eval_form_title_02 && this.dataFormLayoutControl.patient_eval_form_title_02.length > 60 ){
                this.errorFormLayoutControl.patient_eval_form_title_02 = "対象者入力フォーム タイトル２は60文字以下で入力してください。";
            }

            if (this.dataFormLayoutControl.patient_eval_form_desc_01 && this.dataFormLayoutControl.patient_eval_form_desc_01.length > 500) {
                this.errorFormLayoutControl.patient_eval_form_desc_01 = "対象者入力フォーム 説明は500文字以下で入力してください。";
            }
        },

        /**
         * フォームレイアウト制御 編集
         */
        editFormLayoutControl(){

            if (!this.dataEdit.client_organization_form_layout_control_id) return;

            this.errorFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };
            this.isShowDialogEditFormLayoutControl = true;
            this.dataFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };
            this.dataFormLayoutControl = {... this.client_organization_form_layout_control_arr.filter(x => x.id == this.dataEdit.client_organization_form_layout_control_id)[0]};
        },

        /**
         * フォームレイアウト制御 保存
         */
        saveEditFormLayoutControl(){
            this.checkValidateFormLayoutControl("update");
            let checkValidate = true;
            for (const [key, value] of Object.entries(this.errorFormLayoutControl)) {
                if(value){
                    checkValidate = false;
                }
            }

            if(checkValidate){
                this.setIsLoading(true);
                partnerManagement.updateFormLayoutControl(this.dataFormLayoutControl.id, this.dataFormLayoutControl).then((res) => {
                    this.setIsLoading(false)
                    const { data, status } = res;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) {
                        this.errorFormLayoutControl = {
                            layout_name: null,
                        };
                        this.errorFormLayoutControl.layout_name = `レイアウト名[${this.dataFormLayoutControl.layout_name}]が重複しています。`;
                    } else {
                        partnerManagement.getClientOrganizationFormLayoutControl(0).then(
                            (res) => {
	                            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                            return;
	                            }
                                this.client_organization_form_layout_control_arr = [
                                    {
                                        text: "",
                                        value: "",
                                    },
                                ];
                                res.data.data.map((x) => {
                                    x.text = x.layout_name;
                                    x.value = x.id;
                                    this.client_organization_form_layout_control_arr.push(x);
                                });
                            }
                        );
                        this.isShowDialogEditFormLayoutControl = false;
                        this.$toast.open({
                            message: `レイアウト[${this.dataFormLayoutControl.layout_name}]への変更が完了しました。`,
                            type: "success",
                        });
                    }
                });
            }
        },

        /**
         * フォームレイアウト制御 新規追加
         */
        addFormLayoutControl(){
            this.isShowDialogAddFormLayoutControl = true;
            this.errorFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };
            this.dataFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };
        },

        /**
         * フォームレイアウト制御 新規保存
         */
        saveAddFormLayoutControl(){
            this.checkValidateFormLayoutControl("add");
            let checkValidate = true;
            for (const [key, value] of Object.entries(this.errorFormLayoutControl)) {
                if(value){
                    checkValidate = false;
                }
            }

            if(checkValidate){
                this.setIsLoading(true);
                this.dataFormLayoutControl.client_organ_code = this.insurer.client_organ_code;
                partnerManagement.storeFormLayoutControl(this.dataFormLayoutControl).then((res) => {
                    this.setIsLoading(false)
                    const { data, status } = res;
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    if (status !== SUCCESS) {
                        this.errorFormLayoutControl = {
                            layout_name: null,
                        };
                        this.errorFormLayoutControl.layout_name = `レイアウト名[${this.dataFormLayoutControl.layout_name}]が重複しています。`;
                    } else {
                        partnerManagement.getClientOrganizationFormLayoutControl(0).then(
                            (res) => {
	                            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                            return;
	                            }
                                this.client_organization_form_layout_control_arr = [
                                    {
                                        text: "",
                                        value: "",
                                    },
                                ];
                                res.data.data.map((x) => {
                                    x.text = x.layout_name;
                                    x.value = x.id;
                                    this.client_organization_form_layout_control_arr.push(x);
                                });
                            }
                        );
                        this.isShowDialogAddFormLayoutControl = false;
                        this.$toast.open({
                            message: `レイアウト[${this.dataFormLayoutControl.layout_name}(新規)]の登録が完了しました。`,
                            type: "success",
                        });
                    }
                });
            }
        },

        /**
         * 
         */
        setBorderWidth() {
            let i = this.$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
            i.style.width = this.navigation.borderSize + "px";
            i.style.cursor = "ew-resize";
        },

        /**
         * 
         */
        setEvents() {
            const minSize = this.navigation.borderSize;
            const el = this.$refs.drawer.$el;
            const drawerBorder = el.querySelector(".v-navigation-drawer__border");
            const direction = el.classList.contains("v-navigation-drawer--right")
                ? "right"
                : "left";

            function resize(e) {
                document.body.style.cursor = "ew-resize";
                let f =
                    direction === "right"
                        ? document.body.scrollWidth - e.clientX
                        : e.clientX;
                el.style.width = f + "px";
            }

            drawerBorder.addEventListener(
                "mousedown",
                (e) => {
                    if (e.offsetX < minSize) {
                        el.style.transition = "initial";
                        document.addEventListener("mousemove", resize, false);
                    }
                },
                false
            );

            document.addEventListener(
                "mouseup",
                () => {
                    el.style.transition = "";
                    this.navigation.width = el.style.width;
                    document.body.style.cursor = "";
                    document.removeEventListener("mousemove", resize, false);
                },
                false
            );
        },

        /**
         * 閉じる.
         */
        hide() {
            this.hiddenAnimation = true;
            this.errors = {
                disp_seq: null,
                contract_name: null,
                out_of_support_patient_ref_level: null,
                contract_fiscal_year: null,
                health_guidance_contract_div: null,
                health_guidance_div: null,
                person_familiy_div: null,
                health_support_pattern_id: null,
                first_support_form_id: null,
                continued_support_term: null,
                health_guidance_organization_id: null,
                payment_agency_id: null,
                contract_coord_organization_id: null,
                addressee_organiation_id: null,
                fractionating_div: null,
                contract_unit_price_01: null,
                contract_unit_price_02: null,
                start_pyament_rate: null,
                final_eval_payment_rate: null,
                retire_payment_rate: null,
                client_organization_form_layout_control_id: null
            };
            this.dataEdit = {
                disable_flg: false,
                disp_seq: 0,
                contract_name: null,
                out_of_support_patient_ref_level: 0,
                contract_fiscal_year: String(this.getFiscalYear()),
                health_guidance_contract_div: null,
                health_guidance_div: null,
                person_familiy_div: 1,
                health_support_pattern_id: null,
                first_support_form_id: null,
                continued_support_term: null,
                health_guidance_organization_id: null,
                payment_agency_id: null,
                contract_coord_organization_id: null,
                addressee_organiation_id: null,
                fractionating_div: 1,
                contract_unit_price_01: 0,
                contract_unit_price_02: 0,
                start_pyament_rate: 0,
                final_eval_payment_rate: 0,
                retire_payment_rate: 0,
                client_organization_form_layout_control_id: null
            };
            this.errorFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };
            this.dataFormLayoutControl = {
                layout_name: null,
                patient_eval_form_title_01: null,
                patient_eval_form_title_02: null,
                patient_eval_form_desc_01: null,
            };

            $(".tab-eligible-person-information").scrollTop(0);
            
            if (this.isUpdate) {
                this.$emit("reload", true);
                this.isUpdate = false;
            }
            this.$emit("reload", false);
            this.$store.commit("hideSlide");
        },

        /**
         * 詳細リロード
         * @param {*} param 
         */
        reloadDetail(param) {
            if (param == "update") {
                this.isUpdate = true;
            }
            this.hide();
        },

        /**
         * 各選択肢情報 取得.
         */
        async getOptionSelects(){
            // 支援担当外 参照可能レベル.
            const out_of_support_patient_ref_level_arr = await this.getCodeDefine(OUT_OF_SUPPORT_PATIENT_REF_LEVEL);
            for (const [key, value] of Object.entries(out_of_support_patient_ref_level_arr)) {
                this.out_of_support_patient_ref_level_arr.push({
                    text: value,
                    value: parseInt(key),
                });
            };

            // 保健師指導契約区分.
            const codeHealthGuidanceContractDiv = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_CONTRACT_DIV);
	        this.codeHealthGuidanceContractDiv = [
		        {
			        text: "",
			        value: "",
		        },
	        ];
            for (const [key, value] of Object.entries(codeHealthGuidanceContractDiv)) {
                this.codeHealthGuidanceContractDiv.push({
                    text: value,
                    value: parseInt(key),
                });
            };

            // 保健指導区分.
            const codeHealthGuidanceDiv = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_DIV);
	        this.codeHealthGuidanceDiv = [
		        {
			        text: "",
			        value: "",
		        },
	        ];
            for (const [key, value] of Object.entries(codeHealthGuidanceDiv)) {
                this.codeHealthGuidanceDiv.push({
                    text: value,
                    value: parseInt(key),
                });
            };

            // 本人家族区分
            const getCodeFamily = await this.getCodeDefine(CODE_FAMILY);
            for (const [key, value] of Object.entries(getCodeFamily)) {
                this.getCodeFamily.push({
                    text: value,
                    value: parseInt(key),
                });
            };

            // 支援パターン.
            await partnerManagement.getHealthSupportPattern().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listSupportPattern = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                res.data.data.map((x) => {
                    this.listSupportPattern.push({
                        text: x.support_pattern_name,
                        value: x.id,
                    });
                });
            });

            // 初回支援形態.
            await partnerManagement.getHealthSupportForm().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listStatusInitialPreview = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                res.data.data.filter(x => x.map_system_code_04 != "2").forEach((x) => {
                    this.listStatusInitialPreview.push({
                        text: x.support_form_name_disp_02,
                        value: x.id,
                    });
                });
            });

            // 特定保健指導機関.
            await partnerManagement.getMedicalGuidanceOrganization().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listHealthAgency = [
                    {
                        text: "",
                        value: "",
                        default_flag: "",
                    },
                ];
                let flag = false;
                res.data.data.map((x) => {
                    if (!flag && x.default_flag) {
                        this.dataEdit.health_guidance_organization_id = x.id;
                        flag = true;
                    }
                    this.listHealthAgency.push({
                        text: `[${x.organ_code}] ${x.organ_name}`,
                        value: x.id
                    });
                });
            });

            // 支払代行機関
            await partnerManagement.getPaymentRepresentativeOrganization().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listPaymentAgency = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.listPaymentAgency.push({
                            text: `[${x.agency_code}] ${x.agency_name}`,
                            value: x.id,
                        });
                    });
                }
            );

            // 契約取りまとめ機関
            await partnerManagement.getClientOrganizationListContract().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listContractAgency = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.listContractAgency.push({
                            text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                            value: x.id,
                        });
                    });
                }
            );

            // 送付先機関
            await partnerManagement.getClientOrganizationListContact().then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.listDestinationInstitution = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        this.listDestinationInstitution.push({
                            text: `[${x.client_organ_code}] ${x.client_organ_name}`,
                            value: x.id,
                        });
                    });
                }
            );

            // フォームレイアウト制御
            await partnerManagement.getClientOrganizationFormLayoutControl(0).then(
                (res) => {
	                if(res.errorCode === INTERNAL_SERVER_ERROR) {
		                this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		                return;
	                }
                    this.client_organization_form_layout_control_arr = [
                        {
                            text: "",
                            value: "",
                        },
                    ];
                    res.data.data.map((x) => {
                        x.text = x.layout_name;
                        x.value = x.id;
                        this.client_organization_form_layout_control_arr.push(x);
                    });
                }
            );

            // 端数処理区分
            await partnerManagement.getCodeFraction().then((res) => {
	            if(res.errorCode === INTERNAL_SERVER_ERROR) {
		            this.$toast.error(`[${res?.error.messageID}] ${res?.error.message}`, TOAST_TIME_OUT);
		            return;
	            }
                this.listFractionatingDiv = [
                    {
                        text: "",
                        value: "",
                    },
                ];
                for (const [key, value] of Object.entries(res.data)) {
                    this.listFractionatingDiv.push({
                        text: value,
                        value: parseInt(key),
                    });
                }
            });
        },

        /**
         * PageMode 新規の場合true
         */
        isPageMode_New() {
            return this.page_mode == "NEW";
        },

        /**
         * PageMode 編集の場合true
         */
        isPageMode_Edit() {
            return this.page_mode == "EDIT";
        }
    },
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
.percent
  top: 1px
  right: 1px
  font-size: 15px
  background: #e7e7e7
  padding: 6.5px
  border-radius: 0px 4px 4px 0px

.tab-eligible-person-information
  max-height: 85vh
  overflow-y: scroll

.sidebar-show
    animation: show 0.5s ease forwards

.sidebar-hidden
    animation: hidden 0.5s ease forwards

@media (max-width: 1025px)
    .navigation
        transform: translate(0%) !important
    .v-navigation-drawer--close
        visibility: unset

@keyframes hidden
    from
        width: var(widthAnimation)
    to
        width: 0

@keyframes show
    from
        width: 0
    to
        width: var(widthAnimation)
</style>
