<template>
  <div class="list">
    <div class="box-search mb-5">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="border border-top-0 rounded p-1 pr-2">
                  <div class="col-12 p-0 m-0 text-right">
                    <button @click="clearAllSearch" type="button" class="btn btn-grey">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                      クリア
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-1">
                      年度
                      <date-picker autocomplete="off" v-model="dataSearch.contract_fiscal_year" id="year" name="year" :config="optionYear">
                      </date-picker>
                      <span class="text-danger">{{ errors.contract_fiscal_year }}</span>
                    </div>
                    <div class="col-1">
                      期
                      <select v-model="dataSearch.health_guidance_period" class="form-select">
                        <option></option>
                        <option v-for="item in periodOptions" :key="item.id + 'getGuidanceOrganization'"
                                :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                      <span class="text-danger">{{ errors.health_guidance_period }}</span>
                    </div>

                    <div class="col-3">
                      請求日
                      <div class="row align-items-center">
                        <div class="col-5 mt-0 pt-0">
                          <date-picker autocomplete="off" v-model="dataSearch.date_invoice_from" id="date_invoice_from" name="date_invoice_from"
                                       :config="options"></date-picker>
                        </div>
                        <div class="col-1 text-center pt-0">〜</div>
                        <div class="col-5 mt-0 pt-0">
                          <date-picker autocomplete="off" v-model="dataSearch.date_invoice_to" id="date_invoice_to" name="date_invoice_to"
                                       :config="options"></date-picker>
                        </div>
                        <span class="text-danger">{{ errors.date_invoice_to }}</span>
                      </div>
                    </div>
                    <div class="col-2">
                      保健指導機関
                      <select v-model="dataSearch.organ_code" class="form-select">
                        <option></option>
                        <option v-for="item in getGuidanceOrganization" :key="item.id + 'getGuidanceOrganization'"
                                :value="item.organ_code">
                          [{{ item.organ_code }}] {{ item.organ_name }}
                        </option>
                      </select>
                      <span class="text-danger">{{ errors.organ_code }}</span>
                    </div>
                    <div class="col-2">
                      請求・報告先
                      <select v-model="dataSearch.claim_report_to_organ_code" class="form-select">
                        <!-- cho value là index của mảng rồi check index đó là của cái nào -->
                        <option :value="null"></option>
                        <option :value="item.code" v-for="item in where_to_issue_invoice_report" :key="item.code + 'abc'">
                          [{{ item.code }}] {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-4 align-items-center">
                      <label>XMLデータ出力状況</label>
                      <div class="row">
                        <!-- <div v-for="item in XML_data_output_status" :key="item.text + item.value"
                             class="col-auto ml-3 form-check">
                          <label class="form-check-label" :for="item.value + 'XMLデータ出力状況'">{{
                            item.text
                            }}</label>
                          <input :id="item.value + 'XMLデータ出力状況'" v-if="item.value == 10" v-model="dataSearch.not_exported_xml"
                                 type="checkbox" class="form-check-input" :value="item.value" />
                          <input :id="item.value + 'XMLデータ出力状況'" v-if="item.value == 20"
                                 v-model="dataSearch.export_xml_1_part" type="checkbox" class="form-check-input"
                                 :value="item.value" />
                          <input :id="item.value + 'XMLデータ出力状況'" v-if="item.value == 30" v-model="dataSearch.exported_xml"
                                 type="checkbox" class="form-check-input" :value="item.value" />
                        </div> -->
                        <div class="col-auto ml-3 form-check">
                          <label class="form-check-label" for="未出力10">未出力</label>
                          <input id="未出力10" v-model="dataSearch.not_exported_xml" type="checkbox" class="form-check-input" :value="10" />
                        </div>
                        <div class="col-auto ml-3 form-check">
                          <label class="form-check-label" for="一部出力済30">一部出力済</label>
                          <input id="一部出力済30" v-model="dataSearch.export_xml_1_part" type="checkbox" class="form-check-input" :value="30" />
                        </div>
                        <div class="col-auto ml-3 form-check">
                          <label class="form-check-label" for="全て出力済20">全て出力済</label>
                          <input id="全て出力済20" v-model="dataSearch.exported_xml" type="checkbox" class="form-check-input" :value="20" />
                        </div>
                      </div>
                      <div class="col-12 text-right pt-0">
                        <button @click="getList()" type="button" class="btn btn-success mt-5">
                          <i class="fa fa-search" aria-hidden="true"></i>
                          検索
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="border border-right-0 col-4 p-0">
          <p class="border-bottom p-1 bg-gray">送付先一覧</p>
          <div class="mr-3 ml-3 data-table-container data-table-container-listBlockLeft">
	          <v-data-table
		          id="tableHighlight"
		          :headers="headerTableLeft"
		          :items="listBlockLeft"
		          :fixed-header="true"
		          :height="'100%'"
		          :sort-by.sync="sortBy"
		          :sort-desc.sync="sortDesc"
		          hide-default-footer
		          class="elevation-1 data-table table table-responsive table-hover tableHighlightOne m-0"
	          >
		          <template v-slot:body="{ items }">
			          <tbody>
                  <tr v-for="item in items" :key="item.id + 'listBlockLeft'" v-bind:id="'highlight' + item.id" @click="getDetailBlockLeft(item)" :class="cellChangeHighlight">
                    <td class="border-0">{{ String(item.contract_fiscal_year).slice(-2) }}年度
                      (第{{ item.health_guidance_period }}期)
                    </td>
                    <td class="border-0">[{{ item.claim_report_to_organ_code }}] {{ item.claim_report_to_organ_name }}</td>
                    <td class="border-0">{{ item.claim_date }}</td>
                    <td class="border-0">{{ get_exported_xml_status(item.exported_xml_status) }}</td>
                  </tr>
			          </tbody>
		          </template>
		          <template v-slot:bottom>
			          <div class="text-center pt-2">
				          <v-pagination
					          v-model="currentPage"
					          :length="pagination.totalPage"
				          ></v-pagination>
				          <v-text-field
					          :model-value="perPage"
					          type="number"
					          min="-1"
					          max="15"
					          hide-details
					          @update:model-value="perPage = parseInt($event, 10)"
				          ></v-text-field>
			          </div>
		          </template>
	          </v-data-table>
          </div>
	        <Pagination
		        :total-page="totalPage"
		        :current-page="currentPage"
		        @onHandleChangePage="onHandleChangePage"
	        />
        </div>
        <div class="border col-8 p-0 pl-2">
          <br />
          <p class="border-bottom p-1">XMLデータ出力設定</p>
          <div class="col-12 row">
            <div class="col-md-3 position-relative">
              送信回数
              <input v-model="dataEdit.number_of_send" type="number" class="form-control pr-10" placeholder="" />
              <p class="position-absolute unit">回</p>
              <span class="text-danger">{{ errorsData.number_of_send }}</span>
            </div>

            <div class="col-md-3 position-relative">
              アーカイブVer
              <select v-model="dataEdit.hg_xml_file_archive_controls" class="form-select">
                <option :value="item.id" v-for="(item, index) in hg_xml_file_archive_controls" :key="index">
                  {{ item.archive_name_disp }}
                </option>
              </select>
              <span class="text-danger">{{ errorsData.hg_xml_file_archive_controls }}</span>
            </div>
          </div>
          <p class="border-bottom p-1">送付情報</p>
          <div class="col-12">
            <!-- <div class="row" v-for="(item, index) in dataDetail.claimPrepareBases"
              :key="item.file_create_organ_code + '-file_create_organ_code-' + index">
              <div class="col-2 border bg-gray">送付元機関</div>
              <div class="col-4 border">[{{ item.file_create_organ_code }}] {{ item.file_create_organ_name }}</div>
              <div class="col-2 border bg-gray">送付先機関</div>
              <div class="col-4 border">[{{ item.receiver_organ_code }}] {{ item.receiver_organ_name }}</div>
            </div> -->
            <div class="row">
              <div class="col-2 border bg-gray">送付元機関</div>
              <div class="col-4 border">
                <span v-if="dataDetail.claimPrepareBases">
                  [{{ dataDetail.claimPrepareBases[0].file_create_organ_code }}] {{
                      dataDetail.claimPrepareBases[0].file_create_organ_name
                  }}
                </span>
              </div>
              <div class="col-2 border bg-gray">送付先機関</div>
              <div class="col-4 border">
                <span v-if="dataDetail.claimPrepareBases">
                  [{{ dataDetail.claimPrepareBases[0].receiver_organ_code }}] {{
                      dataDetail.claimPrepareBases[0].receiver_organ_name
                  }}
                </span>
              </div>
            </div>
          </div>
          <br />
          <p class="border-bottom p-1">集計情報</p>
          <div class="col-12">
            <div class="row">
              <div class="col-2 border bg-gray">対象者人数</div>
              <div class="col-2 border">
                <!-- <span v-if="dataDetail.statisticalInformation">
                  {{ dataDetail.statisticalInformation.count_patients }} 人
                </span> -->
                <span>{{ countCheckboxChecked }} 人</span>
              </div>
              <div class="col-8 text-right p-0 m-0">
                <div class="container-fluid m-0 p-0">
                  <div class="row no-gutters  justify-content-end">
                    <div class="col-4">
                      <select v-model="check_health_guidance_div" class="form-select">
                        <option v-for="item in health_guidance_divs" :key="item.text + 'item'" :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-4 ml-2">
                      <select v-model="check_invoice_exec_time_code" class="form-select">
                        <option :value="null"></option>
                        <option v-for="item in invoice_exec_time_codes" :key="item.text + 'item'" :value="item.value">
                          {{ item.text }}
                        </option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <button @click="checkByCond(check_health_guidance_div, check_invoice_exec_time_code)" type="button" class="btn btn btn-outline-secondary btn-sm mx-3 mt-1">
                        選択
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2 border bg-gray">保険者請求額 合計</div>
              <div class="col-2 border">
                <!-- <span v-if="dataDetail.statisticalInformation">
                  {{ dataDetail.statisticalInformation.total_insurer_claim_amount }} 円
                </span> -->
                <span>
                  {{ total_insurer_claim_amount }} 円
                </span>
              </div>
              <div class="col-2 border bg-gray">窓口負担額 合計</div>
              <div class="col-2 border">
                <!-- <span v-if="dataDetail.statisticalInformation">
                  {{ dataDetail.statisticalInformation.total_present_own_payment_amount }} 円
                </span> -->
                <span>
                  {{ total_present_own_payment_amount }} 円
                </span>
              </div>
              <div class="col-2 border bg-gray">算定額総計</div>
              <div class="col-2 border">
                <!-- <span v-if="dataDetail.statisticalInformation">
                  {{ dataDetail.statisticalInformation.total_calculated_amount_01 }} 円
                </span> -->
                <span>
                  {{ total_calculated_amount_01 }} 円
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-2 border bg-gray">請求出力額 合計</div>
              <div class="col-2 border">
                <!-- <span v-if="dataDetail.statisticalInformation">
                  {{ dataDetail.statisticalInformation.total_calculated_amount_02 }} 円
                </span> -->
                <span>
                  {{ total_calculated_amount_02 }} 円
                </span>
              </div>
            </div>
          </div>
          <br />
          <p class="border-bottom p-1">明細情報</p>

          <div class="data-table-container">
	          <v-data-table
		          id="tableHighlight"
		          :headers="headerTableRight"
		          :items="dataDetail.claimReceptions"
		          :fixed-header="true"
		          :height="'100%'"
		          disable-pagination
		          hide-default-header
		          hide-default-footer
		          class="data-table table table-responsive table-hover tableHighlight"
	          >
		          <template v-slot:header="{ props: { headers } }">
			          <thead class="v-data-table-header">
				          <tr>
					          <th
						          v-for="(item, index) in headers"
						          :key="item.text + 'header' + index"
						          class="text-nowrap"
						          v-bind:class="[
									item.sortable ? 'sortable' : '',
									sortByHeadersListNotCreate.sortBy == item.value ? 'active' : '',
									sortByHeadersListNotCreate.descending ? 'desc' : 'asc',
	                              ]"
						          @click="item.sortable ? changeSort(item.value) : ''"
					          >
						          <input
							          v-if="index == 0"
							          @click="checkAll()"
							          class="form-check-input check-all-list"
							          type="checkbox"
						          />
						          <span v-else>{{ item.text }}</span>
						          <v-icon v-if="item.sortable" class="v-data-table-header__icon" small>mdi-arrow-up</v-icon>
					          </th>
				          </tr>
			          </thead>
		          </template>
		          <template v-slot:body="{ items }">
			          <tbody>
			                <tr v-for="(item, index) in items" :key="item.id + '-data-import-' + index">
				                <td class="text-nowrap">
					                <input v-if="!item.hg_xml_output_datetime" checked class="check-box checkbox-list"
					                       @click="checkBox(item.id)" :value="item.id" type="checkbox" />
					                <input v-else class="check-box checkbox-list" type="checkbox" @click="checkBox(item.id)"
					                       :value="item.id" />
				                </td>
				                <td class="text-nowrap">[{{ String(item.patient_id).padStart(10, "0") }}] {{ item.patient_name_kana }}</td>
				                <td class="text-nowrap">{{ code_health_guidance_div[item.health_guidance_div] }}</td>
				                <td class="text-nowrap">
					                <span v-if="item.claim_timing_div == 10">初回</span>
					                <span v-if="item.claim_timing_div == 15">初回未完了</span>
					                <span v-if="item.claim_timing_div == 20">実績評価</span>
					                <span v-if="item.claim_timing_div == 40">{{ "途中終了" + getSupportStopReason(item.support_stop_reason)}}</span>
				                </td>
				                <td class="text-nowrap">{{ item.hg_date }}</td>
				                <td class="text-nowrap">{{ item.claim_prepare_claim.done_support_point ?? "ー"}} / {{ item.claim_prepare_claim.requried_support_point ?? "ー" }}</td>
				                <td class="text-nowrap">{{ item.claim_prepare_claim.calculated_amount_01.toLocaleString() }}</td>
				                <td class="text-nowrap">{{ item.claim_prepare_claim.insurer_claim_amount.toLocaleString() }}</td>
				                <td class="text-nowrap">{{ item.claim_prepare_claim.present_own_payment_amount.toLocaleString() }}</td>
				                <td class="text-nowrap">{{ item.claim_prepare_claim.calculated_amount_02.toLocaleString() }}</td>
				                <td class="text-nowrap">[{{ item.claim_prepare_claim.claim_receiver_organ_code }}] {{
						                item.claim_prepare_claim.claim_receiver_organ_name
					                }}</td>
				                <td class="text-nowrap">{{ item.hg_xml_output_datetime }}</td>
			                </tr>
			          </tbody>
		          </template>
	          </v-data-table>
          </div>

          <footer>
            <div class="row">
              <div class="col-md-12 text-right pr-5 mb-5">
                <button type="button" class="btn btn-primary" @click="validate()">
                  <i class="fa fa-arrow-down" aria-hidden="true"></i>
                  出力
                </button>
              </div>
            </div>
          </footer>
          <Dialog :isShow="isDialogExport" persistent :maxWidth="400">
            <DialogExport @exportData="exportData" @closeDialog="closeDialog" />
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import moment from "moment";
import mixins from "@/mixins";
import InvoiceExport from "@/services/api/InvoiceExport";
import { SUCCESS } from "@/constants";
import _, { add } from "lodash";
import Dialog from "@/components/common/Dialog";
import Pagination from "@/components/common/Pagination";
import DialogExport from "@/pages/export-invoice/DialogExport";
import {CODE_HEALTH_GUIDANCE_DIV, CODE_REASON_TERMINATE, TOAST_TIME_OUT} from "@/common/constants";

export default {
    name: "list",
    mixins: [mixins],
    components: {
        Dialog,
        DialogExport,
        Pagination,
    },
    data() {
        return {
            total_calculated_amount_01: 0,
            total_present_own_payment_amount: 0,
            total_calculated_amount_02: 0,
            total_insurer_claim_amount: 0,
            countCheckboxChecked: 0,
            listBlockLeft: [],
            XML_data_output_status: [],

            health_guidance_divs: [],
            check_health_guidance_div: 1,
            
            invoice_exec_time_codes: [],
            check_invoice_exec_time_code: null,

            where_to_issue_invoice_report: [],
            getGuidanceOrganization: [],
            errors: {
                contract_fiscal_year: null,
                date_invoice_to: null,
                organ_code: null,
            },
            hg_xml_file_archive_controls: [],
            dataSearch: {
                contract_fiscal_year: String(this.getFiscalYear()),
                organ_code: null,
                claim_report_to_organ_code: null,
                date_invoice_from: moment(new Date()).subtract(1, "months").format("YYYY-MM-DD"),
                date_invoice_to: moment(new Date()).add(2, "months").format("YYYY-MM-DD"),
                not_exported_xml: true,
                export_xml_1_part: true,
                exported_xml: false,
            },

            dataEdit: {
                hg_xml_file_archive_controls: null,
                number_of_send: 0
            },

            errorsData: {
                hg_xml_file_archive_controls: null,
                number_of_send: null
            },

            dataDetail: [],
            isDialogExport: false,
            code_health_guidance_div: [],
            code_reason_terminate: [],
            claim_prepare_reception_ids: [],
            rotateNavBar: false,
	        cellChangeHighlight: null,
	        sortBy: "",
	        sortDesc: false,
	        headerTableRight: [
		        { text: "", align: "start", sortable: false, value: "" },
		        { text: "対象者", sortable: true, value: "patient_id" },
		        { text: "保健指導区分", sortable: true, value: "health_guidance_div" },
		        { text: "実施時点", sortable: true, value: "claim_timing_div" },
		        { text: "対象日", sortable: true, value: "hg_date" },
		        { text: "実施ポイント", sortable: true, value: "claim_prepare_claim.done_support_point" },
		        { text: "算定額(円)", sortable: true, value: "claim_prepare_claim.calculated_amount_01" },
		        { text: "保険者請求額(円)", sortable: true, value: "claim_prepare_claim.insurer_claim_amount" },
		        { text: "窓口負担額(円)", sortable: true, value: "claim_prepare_claim.present_own_payment_amount" },
		        { text: "請求出力額(円)", sortable: true, value: "claim_prepare_claim.calculated_amount_02" },
		        { text: "請求書送付先", sortable: true, value: "claim_prepare_claim.claim_receiver_organ_code" },
		        { text: "XML出力", sortable: true, value: "hg_xml_output_datetime" },
	        ],
	        headerTableLeft: [
		        { text: "年度", align: "start", sortable: true, value: "id", width: "20%" },
		        { text: "請求・報告先機関", sortable: true, value: "claim_report_to_organ_code", width: "35%" },
		        { text: "請求日", sortable: true, value: "claim_date", width: "20%" },
		        { text: "XML出力", sortable: true, value: "exported_xml_status", width: "25%" }
	        ],
	        sortByHeadersListNotCreate: {
		        sortBy: null,
		        descending: false,
	        },
	        totalPage: 0,
            perPage: 10,
            currentPage: 1,
        };
    },
    computed: {
        options() {
            return {
                format: "YYYY-MM-DD",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                locale: moment.locale("ja"),
            };
        },
        periodOptions() {
            return this.getHealthGuidancePeriods(this.dataSearch.contract_fiscal_year).map((v) => ({
                text: `第${v}期`,
                value: v,
            }));
        },
    },
    async created() {
        this.code_health_guidance_div = await this.getCodeDefine(CODE_HEALTH_GUIDANCE_DIV);
        this.code_reason_terminate = await this.getCodeDefine(CODE_REASON_TERMINATE);

        await InvoiceExport.getHalthGuidanceDiv().then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
                this.health_guidance_divs.push({
                    text: value,
                    value: key,
                });
            }
            this.check_health_guidance_div = this.health_guidance_divs[0].value;
        });

        await InvoiceExport.getXMLOutputStatus().then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
                this.XML_data_output_status.push({
                    text: value,
                    value: key,
                });
            }
        });

        await InvoiceExport.getGuidanceOrganization().then((res) => {
            this.getGuidanceOrganization = res.data.data;
            const checkDefault = this.getGuidanceOrganization.filter(x => x.default_flag == 1);
            if (checkDefault.length > 0) {
                this.dataSearch.organ_code = checkDefault[0].organ_code;
            }
        });

        await InvoiceExport.whereToIssueInvoiceReport().then((res) => {
            this.where_to_issue_invoice_report = res.data.data;
        });

        await InvoiceExport.getHgXmlFileArchiveControl().then((res) => {
            this.hg_xml_file_archive_controls = res.data.data;
            this.dataEdit.hg_xml_file_archive_controls = this.hg_xml_file_archive_controls[0].id
        });

        await InvoiceExport.getExecutionTime().then((res) => {
            for (const [key, value] of Object.entries(res.data)) {
                if (key == 30) continue;
                this.invoice_exec_time_codes.push({
                    text: value,
                    value: key,
                });
            }
            this.check_invoice_exec_time_code = null;
        });

        $(document).keypress((e) => {
            if (e.which === 13) {
                this.getList()
            }
        });
    // await this.getList();
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        get_exported_xml_status(exported_xml_status) {
            switch (exported_xml_status) {
            case 1:
                const checkData1 = this.XML_data_output_status.filter(x => x.value == 10);
                if (checkData1) {
                    return checkData1[0].text;
                }
                break;

            case 2:
                const checkData2 = this.XML_data_output_status.filter(x => x.value == 30);
                if (checkData2) {
                    return checkData2[0].text;
                }
                break;

            default:
                const checkData3 = this.XML_data_output_status.filter(x => x.value == 20);
                if (checkData3) {
                    return checkData3[0].text;
                }
                break;
            }
        },

	    changeSort(column) {
		    this.sortByHeadersListNotCreate.descending = !this.sortByHeadersListNotCreate.descending;
		    if(this.sortByHeadersListNotCreate.descending){
			    this.dataDetail.claimReceptions = _.orderBy(this.dataDetail.claimReceptions,`${column}`,"desc");
		    } else {
			    this.dataDetail.claimReceptions = _.orderBy(this.dataDetail.claimReceptions,`${column}`,"asc");
		    }
	    },

	    onHandleChangePage(pageNumber) {
		    this.currentPage = pageNumber;
		    this.getList();
	    },

        getDetailBlockLeft(item) {
	        $("#tableHighlight").find(".cellHighlight").removeClass("cellHighlight");
	        $("#highlight" + item.id).addClass("cellHighlight");
            const getBlockLeftDetail = {
                contract_fiscal_year: item.contract_fiscal_year,
                claim_date: item.claim_date,
                claim_report_to_organ_code: item.claim_report_to_organ_code,
                organ_code: item?.health_guidance_organization?.organ_code
            }
            InvoiceExport.getBlockLeftDetail(getBlockLeftDetail).then((res) => {
                window.scrollTo({
                    behavior: "smooth",
                    top: 0,
                });
                const { data, status, error } = res;
                this.setIsLoading(false);
                if (status !== SUCCESS) {
	                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
	                return;
                }
                this.dataDetail = data.data;

                this.clearClaimSummary();

                this.dataDetail.claimReceptions.map(x => {
                    if (!x.hg_xml_output_datetime) {
                        this.total_present_own_payment_amount += x.claim_prepare_claim.present_own_payment_amount;
                        this.total_calculated_amount_02 += x.claim_prepare_claim.calculated_amount_02;
                        this.total_insurer_claim_amount += x.claim_prepare_claim.insurer_claim_amount;
                        this.total_calculated_amount_01 += x.claim_prepare_claim.calculated_amount_01;
                        this.claim_prepare_reception_ids.push(x.id);
                    }
                });

                this.countCheckboxChecked = this.getPatientCountInClaimDetail();
            });
        },

        getList() {
	        this.sortBy = "";
	        this.sortDesc = false;
            this.errors = {
                contract_fiscal_year: null,
                date_invoice_to: null,
                organ_code: null,
            };
            if (!this.dataSearch.contract_fiscal_year) {
                this.errors.contract_fiscal_year = "年度を入力してください。";
            }
            if (!this.dataSearch.date_invoice_from || !this.dataSearch.date_invoice_to) {
                this.errors.date_invoice_to = "請求日を入力してください。";
            } else {
                if (moment(this.dataSearch.date_invoice_to).format("YYYY-MM-DD") < moment(this.dataSearch.date_invoice_from).format("YYYY-MM-DD")) {
                    this.errors.date_invoice_to = "請求日は開始と終了が逆転しています。";
                } else {
                    let countDay = moment(this.dataSearch.date_invoice_to).diff(moment(this.dataSearch.date_invoice_from), "days");
                    if (countDay > 180) {
                        this.errors.date_invoice_to = "請求日の範囲は180日以内で入力してください。";
                    }
                }
            }
            if (!this.dataSearch.organ_code) {
                this.errors.organ_code = "特定保健指導機関を選択してください。";
            }

            var checkValidate = true;
            for (const [key, value] of Object.entries(this.errors)) {
                if (value) {
                    checkValidate = false;
                }
            }

            if (checkValidate) {
                this.setIsLoading(false);
                this.dataSearch.not_exported_xml = this.dataSearch.not_exported_xml ? 1 : 0;
                this.dataSearch.export_xml_1_part = this.dataSearch.export_xml_1_part ? 1 : 0;
                this.dataSearch.exported_xml = this.dataSearch.exported_xml ? 1 : 0;
                this.dataSearch.health_guidance_period = this.dataSearch.health_guidance_period;

                InvoiceExport.getListBlockLeft(this.dataSearch).then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0,
                    });
                    const { data, status, error } = res;
                    this.setIsLoading(false);
                    if (status !== SUCCESS) {
	                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                        return;
                    }
                    this.listBlockLeft = []
                    for (const [key, value] of Object.entries(data.data)) {
                        this.listBlockLeft.push(value);
                    }
	                  this.dataDetail = [];
                    if (this.listBlockLeft.length > 1) {
                        this.getDetailBlockLeft(this.listBlockLeft[0]);
                    }

                    // Paging handling
                    const totalData = Object.keys(data.data).length;
                    this.totalPage = Math.ceil(totalData / this.perPage);

                    const startData = (this.currentPage - 1) * this.perPage;
                    const endData = startData + this.perPage;
                    this.listBlockLeft = Object.values(this.listBlockLeft).slice(startData, endData);

                    if (this.listBlockLeft.length < 1) {
                        this.notifyNoSearchResults();
                    }
                });
            }
        },
        clearAllSearch() {
            this.dataSearch = {
                contract_fiscal_year: String(this.getFiscalYear()),
                organ_code: null,
                claim_report_to_organ_code: null,
                date_invoice_from: moment(new Date()).subtract(1, "months").format("YYYY-MM-DD"),
                date_invoice_to: moment(new Date()).add(2, "months").format("YYYY-MM-DD"),
                not_exported_xml: true,
                export_xml_1_part: true,
                exported_xml: false,
            };
            this.totalPage = 0

            const checkDefault = this.getGuidanceOrganization.filter(x => x.default_flag == 1);
            if (checkDefault) {
                this.dataSearch.organ_code = checkDefault[0]?.organ_code;
            }
            this.listBlockLeft = this.dataDetail = [];
            this.getList();
        },
        checkByCond(hgdiv, exectime) {
            this.clearClaimSummary();
            const value = document.getElementsByClassName("checkbox-list");

            for (let i = 0; i < value.length; i++) {
                value[i].checked = false;
            }

            for (let i = 0; i < value.length; i++) {
                const claimReceptId = parseInt(value[i].value);
                const data = this.dataDetail.claimReceptions.filter(x => x.id == claimReceptId)[0];
                if (data.health_guidance_div == hgdiv && 
                    (exectime === null ? true : data.claim_timing_div == exectime)
                ) {
                    this.total_insurer_claim_amount += data.claim_prepare_claim.insurer_claim_amount;
                    this.total_calculated_amount_02 += data.claim_prepare_claim.calculated_amount_02;
                    this.total_present_own_payment_amount += data.claim_prepare_claim.present_own_payment_amount;
                    this.total_calculated_amount_01 += data.claim_prepare_claim.calculated_amount_01;
                    this.claim_prepare_reception_ids.push(claimReceptId);
                    value[i].checked = true;
                } else {
                    value[i].checked = false;
                }
            }

            this.countCheckboxChecked = this.getPatientCountInClaimDetail();
        },
        checkAll() {
            this.clearClaimSummary();
            let value = document.getElementsByClassName("checkbox-list");
            if (
                document.getElementsByClassName("check-all-list")[0].checked
            ) {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = true;
                    this.claim_prepare_reception_ids.push(parseInt(value[i].value));
                }

                this.dataDetail.claimReceptions.map(x => {
                    this.total_present_own_payment_amount += x.claim_prepare_claim.present_own_payment_amount;
                    this.total_insurer_claim_amount += x.claim_prepare_claim.insurer_claim_amount;
                    this.total_calculated_amount_02 += x.claim_prepare_claim.calculated_amount_02;
                    this.total_calculated_amount_01 += x.claim_prepare_claim.calculated_amount_01;
                })

                this.countCheckboxChecked = this.getPatientCountInClaimDetail();

            } else {
                for (let i = 0; i < value.length; i++) {
                    value[i].checked = false;
                }
            }
        },
        checkBox(id) {
            const check = this.claim_prepare_reception_ids.filter((x) => x == id);
            if (check.length > 0) {
                this.claim_prepare_reception_ids = this.claim_prepare_reception_ids.filter(
                    (x) => x != id
                );
                const data = this.dataDetail.claimReceptions.filter(x => x.id == id)[0];
                this.total_insurer_claim_amount = this.total_insurer_claim_amount - data.claim_prepare_claim.insurer_claim_amount;
                this.total_calculated_amount_02 = this.total_calculated_amount_02 - data.claim_prepare_claim.calculated_amount_02;
                this.total_present_own_payment_amount = this.total_present_own_payment_amount - data.claim_prepare_claim.present_own_payment_amount;
                this.total_calculated_amount_01 = this.total_calculated_amount_01 - data.claim_prepare_claim.calculated_amount_01;

            } else {
                const data = this.dataDetail.claimReceptions.filter(x => x.id == id)[0];
                this.total_insurer_claim_amount += data.claim_prepare_claim.insurer_claim_amount;
                this.total_calculated_amount_02 += data.claim_prepare_claim.calculated_amount_02;
                this.total_present_own_payment_amount += data.claim_prepare_claim.present_own_payment_amount;
                this.total_calculated_amount_01 += data.claim_prepare_claim.calculated_amount_01;
                this.claim_prepare_reception_ids.push(id);
            }

            this.countCheckboxChecked = this.getPatientCountInClaimDetail();
        },
        clearClaimSummary() {
            this.countCheckboxChecked = 0;
            this.total_insurer_claim_amount = 0;
            this.total_calculated_amount_02 = 0;
            this.total_present_own_payment_amount = 0;
            this.total_calculated_amount_01 = 0;
            this.claim_prepare_reception_ids = [];
        },
        getPatientCountInClaimDetail() {
            return this.dataDetail.claimReceptions
                .filter(x => this.claim_prepare_reception_ids.indexOf(x.id) !== -1)
                .map(x => x.patient_id)
                .filter((x, index, self) => self.indexOf(x) === index).length;
        },
        getSupportStopReason(support_stop_reason) {
            return support_stop_reason ? " (" + this.code_reason_terminate[parseInt(support_stop_reason)] + ")" : null;
        },
        validate(){
            this.errorsData = {
                hg_xml_file_archive_controls: null,
                number_of_send: null
            };
            if(this.dataEdit.number_of_send){
                if(this.dataEdit.number_of_send > 9 || this.dataEdit.number_of_send < 0){
                    this.errorsData.number_of_send = "送信回数は[0〜9]の範囲で入力してください。";
                }
            }else{
                if(this.dataEdit.number_of_send !== 0){
                    this.errorsData.number_of_send = "送信回数を入力してください。";
                }
            }

            if(!this.dataEdit.hg_xml_file_archive_controls){
                this.errorsData.hg_xml_file_archive_controls = "アーカイブVerを選択してください。";
            }

            let check = true;
            for (const [key, value] of Object.entries(this.errorsData)) {
                if(value){
                    check = false;
                    break;
                }
            }
            if(check){
                this.isDialogExport = true;
            }
        },
        async exportData(exportInfo) {
            exportInfo.hg_xml_file_archive_controls = this.dataEdit.hg_xml_file_archive_controls;
            exportInfo.number_of_send = this.dataEdit.number_of_send;
            exportInfo.claim_prepare_reception_ids = this.claim_prepare_reception_ids;

            this.setIsLoading(true);

            await InvoiceExport.exportData(exportInfo).then(async (res) => {
                if (res.status != SUCCESS) {
                    this.$toast.error(`[${res.error?.messageID}] ${res.error?.message}`, TOAST_TIME_OUT);
                    return;
                }
                if(res.status == SUCCESS){
                    let fileName = res.data.data.file_name
                    await InvoiceExport.downloadFile(fileName).then((response) => {
                        if(response.status != SUCCESS){
                            this.$toast.error("[E0700] 入力内容に誤りがあります。", TOAST_TIME_OUT)
                            return;
                        }
                        const blob = new Blob([response.data], { type: "application/zip" })
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("target", "_blank");
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        // データ URL を保持していると、それに関連付けされた実際のデータが解放されないので、不要になったら破棄.
                        setTimeout(() => {
                            URL.revokeObjectURL(url);
                        }, 180000);

                        this.$toast.open({
                            message: "データエクスポートが完了しました。",
                            type: "success",
                        });
                    })
                }
            }).finally(() => {
                this.setIsLoading(false);
            });;
            this.isDialogExport = false;
        },
        closeDialog() {
            this.setIsLoading(false);
            this.isDialogExport = false;
        },
    },
    destroyed() {
        $(document).unbind()
    }
};
</script>
<style lang="sass" scoped>
@import "../../styles/common"
.unit
  top: 32.4px
  right: 13px
  font-size: 14.6px
  background: #e7e7e7
  padding: 7px
  border-radius: 0px 4px 4px 0px

.data-table-container-listBlockLeft
    max-height: 650px
    height: 650px
.cellHighlight
    background-color: #d2e3fb
#tableHighlight tr
    cursor: pointer
</style>
