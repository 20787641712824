import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";
import { CODE_IMPORT, RESULT_IMPORT, CODE, CODE_FAMILY, CODE_HEALTH_GUIDANCE_CONTRACT_DIV, CODE_TIME_OF_MAKING_INVOICE, CODE_FRACTION } from "@/common/constants";

export default {

    async saveNotCreate(receptionIds) {
        return await Repository.post("invoice-data-report/create",receptionIds)
            .catch((e) => {
                return this.setErrors(e);
            });
    },

    async saveDetailCreate(id_claim_reception, data) {
        return await Repository.post(`invoice-data-report/edit/${id_claim_reception}`,data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },

    async getDetailCreate(id_claim_reception) {
        return await Repository.get(`invoice-data-report/detail/${id_claim_reception}`)
            .catch((e) => {
                return this.setErrors(e);
            });
    },

    async deleteListCreated(data) {
        return await Repository.post("invoice-data-report/delete", data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },

    async getListNotCreated(data, page, limit) {
        return await Repository.post("invoice-data-report/not-created", {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getListCreated(data, page, limit) {
        return await Repository.post("invoice-data-report/created", {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getCodeImport() {
        try {
            return await Repository.get(`code_define?group=${CODE_IMPORT}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getResultImport() {
        try {
            return await Repository.get(`code_define?group=${RESULT_IMPORT}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getAddresseeOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/client-organization/listContact`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getPaymentRepresentativeOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/payment-agencie/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE_HEALTH_GUIDANCE_CONTRACT_DIV}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getCodeFamily() {
        try {
            return await Repository.get(`code_define?group=${CODE_FAMILY}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getExecutionTime() {
        try {
            return await Repository.get(`code_define?group=${CODE_TIME_OF_MAKING_INVOICE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getCodeFraction() {
        try {
            return await Repository.get(`code_define?group=${CODE_FRACTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getGuidanceOrganization() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-guidance-organization/list`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
