<template>
    <v-container>
        <FormInputObject />
    </v-container>
</template>

<script>
import FormInputObject from "@/components/dashboard/FormInputObject";
export default {
    name: "FormInput",
    components: { FormInputObject }
};
</script>

<style scoped>

</style>