import Repository from "./Repository";
import { ADMIN_REPOSITORY, CODE_DEFINE_REPOSITORY } from "../../constants";
import { CODE_RECEPTION, CODE } from "@/common/constants";

export default {
    async export_data(api, data) {
        try {
            return await Repository.get(`export-data/${api}`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    
    async getMedicalIndication() {
        try {
            return await Repository.get(`code_define?group=${CODE}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getCodeReception() {
        try {
            return await Repository.get(`code_define?group=${CODE_RECEPTION}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getSupportStatus(codeId) {
        try {
            return await Repository.get(CODE_DEFINE_REPOSITORY, { group: codeId });
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
