<template>
    <div class="p-2 list-data">
        <div v-if="isShowListContract">
            <ListContract :clientOrganization="clientOrganization" @back="back"></ListContract>
        </div>
        <div v-else>
        <header class="align-items-center pb-3 d-flex flex-row-reverse">
            <button @click="getDetail(null, 'Add')" class="btn btn-primary"><i class="fas fa-plus"></i> 追加</button>
        </header>
        <div class="data-table-container">
            <v-data-table :headers="headers" :items="lists" disable-pagination hide-default-footer
                class="data-table table table-responsive table-hover">
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.id">
                            <td>
                                <span v-if="item.disable_flg">◯</span>
                            </td>
                            <td>
                                <a v-if="item.client_organ_div == 10" @click="getListContract(item)"  class="text-primary">契約</a>
                            </td>
                            <td>{{ code_client[item.client_organ_div] }}</td>
                            <td>
                                <a @click="getDetail(item, 'Detail')" class="text-primary">{{ item.client_organ_code ? item.client_organ_code : "(未設定)" }}</a>
                            </td>
                            <td>{{ item.client_organ_name }}</td>
                            <td>{{ item.client_organ_tel_01 }}</td>
                            <td>{{ item.client_organ_fax_01 }}</td>
                            <td>{{ item.client_organ_postal_code }}</td>
                            <td>{{ item.client_organ_addr_01 }} {{ item.client_organ_addr_02 }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
            <Pagination :total-page="totalPage" :current-page="currentPage" @onHandleChangePage="onHandleChangePage" />
        </div>
        <slide>
            <Detail v-if="component === 'Detail'" pageMode="EDIT" :dataDetail="itemDetail" :key="randomString" :isShowNavigation="randomString" @reload="reload"></Detail>
            <Detail v-if="component === 'Add'" pageMode="NEW" :dataDetail="null" :key="randomString" :isShowNavigation="randomString" @reload="reload"></Detail>
        </slide>
        </div>
    </div>
</template>
<style lang="scss">
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        &.client_organ_div {
            min-width: 110px !important;
        }
        &.client_organ_code {
            min-width: 110px !important;
        }
        &.postal-code {
            min-width: 100px !important;
        }
        &.contract {
            min-width: 80px !important;
        }
    }
</style>
<script>
import partnerManagement from "@/services/api/partnerManagement";
import { MESSAGE, CODE_CLIENT } from "@/common/constants";
import { SUCCESS } from "@/constants";
import { mapMutations } from "vuex";
import ListContract from "@/pages/partner-management/list-contract";
import Detail from "@/pages/partner-management/partner-detail";
import Slide from "@/components/Slide";
import mixins from "@/mixins";
import _ from "lodash";
import Pagination from "@/components/common/Pagination";

export default {
    name: "ListPartnerManagement",
    mixins: [mixins],
    components: {
        Slide,
        Detail,
        Pagination,
        ListContract
    },
    data() {
        return {
            headers: [
                { text: "無効", align: "start", sortable: false, value: "disable_flg" },
                { text: "契約", sortable: false, value: "", class: "contract" },
                { text: "取引先区分", sortable: false, value: "client_organ_div", class: "client_organ_div" },
                { text: "取引先番号", sortable: false, value: "client_organ_code", class: "client_organ_code" },
                { text: "取引先名称", sortable: false, value: "client_organ_name" },
                { text: "電話番号", sortable: false, value: "client_organ_tel_01" },
                { text: "FAX番号", sortable: false, value: "client_organ_fax_01" },
                { text: "郵便番号", sortable: false, value: "client_organ_postal_code", class: "client_organ_div" },
                { text: "住所", sortable: false, value: "client_organ_addr_01" }
            ],
            isShowListContract: false,
            clientOrganization: null,
            component: null,
            itemDetail: null,
            show: false,
            lists: [],
            dataDetail: [],
            messages: MESSAGE,
            code_client: [],
            totalPage: 1,
            currentPage: 1,
            perPage: 10,
            randomString: "",
        };
    },

    async created() {
        this.code_client = await this.getCodeDefine(CODE_CLIENT);
        await this.getLists();
    },
    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading"
        }),

        getLists() {
            this.setIsLoading(true)
            partnerManagement.getListClientOrganization(this.currentPage, this.perPage)
                .then((res) => {
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0
                    });
                    const { data, status } = res;
                    this.setIsLoading(false)
                    if (status !== SUCCESS) {
	                    this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                        return;
                    }

                    this.totalPage = data.data.last_page;
                    this.currentPage = data.data.current_page;

                    this.lists = [];
                    data.data.data.map(x => {
                        x.client_organ_code == String(x.client_organ_code);
                        x.client_organ_postal_code == String(x.client_organ_postal_code);
                        this.lists.push(x);
                    });
                })
        },

        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getLists();
        },

        getListContract(item){
            this.clientOrganization = item;
            this.isShowListContract = true;
        },

        back(){
            this.isShowListContract = false;
            this.component = null;
        },

        getDetail(item, component) {
            this.itemDetail = item;
            this.component = component;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
            this.$store.commit("showSlide");
        },

        reload(param) {
            if (param) {
                this.component = null;
                this.currentPage = 1;
                this.getLists();
            }
        }
    }
}
</script>