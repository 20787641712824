<template>
    <v-container>
        <div v-if="tokenExpired">
            このフォームは有効期限切れです。
        </div>
        <div v-else class="dashboard-assistance-area">
            <v-card>
                <v-card-title>
                    返信用フォーム
                </v-card-title>
                <v-container>
                    <div class="form-group mt-4 mb-4">
                        <label for="feedback" class="mb-3">
                            お問い合わせ内容
                        </label>
                        <span class="required"></span>
                        <span class="ml-5">( {{ formRequest.feedback?.length ?? 0 }} / {{ FEEDBACK }} )</span>
        
                        <textarea
                            v-model="formRequest.feedback"
                            name="feedback"
                            id="feedback"
                            cols="30"
                            rows="10"
                            class="form-control"
                            @keyup="handleChangeField"
                            :maxlength="FEEDBACK"
                        >
                    </textarea>
                        <span :class="{'showMessage': errors.feedback}" class="text-danger messageError">{{ errors.feedback || 'error' }}</span>
    
                    </div>
                    <v-card-actions class="d-flex justify-center align-center">
                        <Button color="info" @click="onHandleSubmit" :rounded="false">
                            送信
                        </Button>
                    </v-card-actions>
                    <div v-if="doneSubmit" class="mt-5 text-center text-danger">
                    <h2>
                        お問い合わせ内容の送信が完了しました。
                    </h2>
                </div>
                </v-container>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Button from "@/components/common/Button";
import { schemaValidationFormInputClient } from "@/common/schemaValidation";
import mixins from "@/mixins";
import {In_MaxLen, In_Required, KeyWaist, KeyWeight, Sel_Required} from "@/common/constants";
import ClientRepository from "@/services/api/ClientRepository";
import {checkIsEmpty} from "@/common/functions";
import L2 from "@/services/api/L2";

export default {
    name: "FormInputObject",
    components: { Button },
    mixins: [
        mixins
    ],
    data() {
        return {
            FEEDBACK: 1000,
            formRequest: {
                feedback: null,
            },
            errors: {},
            doneSubmit: false,
            tokenExpired: false,
        };
    },
    created() {
        this.showLoading()
        this.schemaValidate = schemaValidationFormInputClient
        if (!this.$route.query || !this.$route.query.token) {
            this.notifyError("You do not have access this url!")
            this.$router.replace({
                path: "/"
            })
        }

        L2.checkTokenExpiration( this.$route.query.token)
            .then((res) => {
                if (res.status == 200) {
                    this.tokenExpired = !res.data.data;
                }
            });

        this.hideLoading()
    },
    computed: {
        ...mapGetters({
            segment: "dashboard/getSegment"
        })
    },
    methods: {
        handleChangeField(e) {
            this.doneSubmit = false;
            this.onValidForm(e.target.name)
        },
        onValidForm(keyValid) {
            const listFormValid = [
                {
                    label: "お問い合わせ内容",
                    name: "feedback",
                    caseCheck: [In_Required, In_MaxLen]
                },
            ]

            let listError = {}
            let listFieldValid = {}
            let listFieldCustom = {}
          
            if (!keyValid) {
                listFieldValid = this.formRequest
            }
            if (keyValid) {
                listFieldValid[keyValid] = this.formRequest[keyValid]
                listError = {...this.errors}
            }

            for (let keyItem in listFieldValid) {
                const valKeyItem = listFieldValid[keyItem]
                const formValidItem = listFormValid.reduce((preVal, nextVal) => {
                    if (nextVal.name === keyItem) {
                        return nextVal
                    }
                    return preVal
                }, {})

                if (checkIsEmpty(valKeyItem)) {
                    // In_Required
                    if (formValidItem.caseCheck.includes(In_Required)) {
                        listError[keyItem] = formValidItem.label + "を入力してください。"
                    }

                    // Sel_Required
                    if (formValidItem.caseCheck.includes(Sel_Required)) {
                        listError[keyItem] = formValidItem.label + "を選択してください。"
                    }
                }

                if (!checkIsEmpty(valKeyItem)) {

                    if (formValidItem.caseCheck.includes(Sel_Required)) {
                        delete listError[keyItem]
                    }
                }

                if (keyItem === "feedback" && valKeyItem) {
                    if (valKeyItem.length > this.FEEDBACK) {
                        listError[keyItem] = formValidItem.label + `は${this.FEEDBACK}文字以下で入力してください。`
                    } else {
                        delete listError[keyItem]
                    }
                }
            }

            this.errors = listError

            if (Object.keys(listFieldCustom).length) {
                this.formRequest = {
                    ...this.formRequest,
                    ...listFieldCustom
                }
            }

            return Object.keys(listError).length
        },
        onHandleSubmit() {
            if (this.onValidForm()) {
                return
            }
            const payload = {
                ...this.formRequest
            }

            const dataUpdated = {
                feedback: this.formRequest.feedback
            }

            this.showLoading();
            this.doneSubmit = false;
            ClientRepository.feedback(this.$route.query.token, dataUpdated)
                .then(response => {
                    if (response && response.status && response.data) {
                        this.doneSubmit = true;
                        this.notifySuccess("お問い合わせ内容の送信が完了しました。");
                        return
                    }
                    
                    this.notifyError("このフォームでのお問い合わせを受け付けることができません。")
                })
                .catch(err => {
                    this.notifyError("お問い合わせ内容の送信中にエラーが発生しました。")
                }).finally(() => {
                    this.hideLoading()
                })
        },
        ...mapMutations({
            showLoading: "common/showLoading",
            hideLoading: "common/hideLoading"
        }),
    }
};
</script>

<style scoped lang="scss">
    .dashboard-assistance-area {
        .required:after {
            content: " *";
            color: red;
            font-weight: 100;
        }
        .messageError {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.5s;
        }
        .showMessage {
            visibility: visible;
            opacity: 1;
        }
    }
</style>