<template>
  <div class="demo-list">
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 start ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 
    <div class="box-search mb-3">
      <div class="row">
        <div class="col-12 position-relative">
          <div class="border rounded p-1 pr-2">
            <span class="text-left">検索条件</span>
          </div>
          <v-list class="pt-0">
            <v-list-group :value="true" class="nav-bar-group" @click="() => { this.rotateNavBar = !this.rotateNavBar }">
              <template #activator>
                <i class="fa fa-bars text-primary float-right pt-1 position-absolute" :class="[rotateNavBar ? 'nav-bar-rotate' : 'nav-bar-rollback']"></i>
              </template>
              <v-list-item>
                <div class="w-100 border border-top-0 rounded p-1 pr-2">
                  <div class="container-fluid py-1">

                    <div class="row">
                      <div class="col-12 p-0 m-0 text-right">
                        <button
                                @click="clearAllSearch"
                                type="button"
                                class="btn btn-grey">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          クリア
                        </button>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-2">
                        <span>保険者</span><span class="required"></span>
                        <select v-model="dataSearch.insurer" class="form-select" id="insurer" name="insurer" @change="validate('insurer')">
                          <option :value="null"></option>
                          <option
                                  v-for="insurer in insurers"
                                  :key="insurer.id + 'insurer'"
                                  :value="insurer.client_organ_code"
                          >
                            [{{ insurer.client_organ_code }}]
                            {{ insurer.client_organ_name }}
                          </option>
                        </select>
                        <span class="text-danger">{{ errorsSearch.insurer }}</span>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-2">
                        <span>氏名</span>
                        <input
                          id="patient_name"
                          name="patient_name"
                          type="text"
                          class="form-control"
                          placeholder="氏名 or 氏名カナ"
                          autocomplete="off"
                          v-model="dataSearch.patient_name"
                          @keyup="validate('patient_name')"
                        />
                        <span class="text-danger">{{ errorsSearch.patient_name }}</span>
                      </div>
                      <div class="col-2">
                        <span>利用券整理番号</span>
                        <input
                          id="patient_ticket"
                          name="patient_ticket"
                          v-model="dataSearch.patient_ticket"
                          type="text"
                          class="form-control"
                          placeholder=""
                          autocomplete="off"
                          @keyup="validate('patient_ticket')"
                        />
                        <span class="text-danger">{{errorsSearch.patient_ticket}}</span>
                      </div>
                      <div class="col-2">
                        <span>保健指導区分</span>
                        <select v-model="dataSearch.health_guide_div_code" class="form-select">
                          <option :value="null"></option>
                          <option
                            v-for="item in healthGuideDivCodes"
                              :key="'key_' + item.id"
                              :value="item.code">
                              {{ item.text }}
                          </option>
                        </select>
                        <span class="text-danger">{{errorsSearch.health_guide_div_code}}</span>
                      </div>
                      <div class="col-2">
                        <span>受付状況</span>
                        <select v-model="dataSearch.health_guide_reception_div_code" class="form-select">
                          <option :value="null"></option>
                          <option
                            v-for="item in healthGuideReceptionDivCodes"
                              :key="'key_' + item.id"
                              :value="item.code">
                              {{ item.text }}
                          </option>
                        </select>
                        <span class="text-danger">{{errorsSearch.health_guide_reception_div_code}}</span>
                      </div>
                      <div class="col-2">
                        <span>紐付け状況</span>
                        <select v-model="dataSearch.contact_association_status" class="form-select">
                          <option :value="null"></option>
                          <option :value="'NOTYET'">未紐付け</option>
                          <option :value="'NOTYET_HAS_CONTACT'">未紐付け(連絡あり)</option>
                          <option :value="'DONE'">紐付済み</option>
                        </select>
                        <span class="text-danger">{{errorsSearch.contact_association_status}}</span>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col">
                        <div class="container-fluid py-0">
                          <div class="row">
                            <div class="col-auto pb-0 pl-0">
                              <span>受付済条件</span>
                            </div>
                            <hr class="m-0" />
                            <div class="col-2 pl-4 pb-0">
                              <span>受付年度</span><span class="required"></span>
                              <date-picker
                                autocomplete="off"
                                v-model="dataSearch.fiscal_year"
                                id="fiscal_year"
                                name="fiscal_year"
                                :config="optionYear"
                                @input="validate('fiscal_year')">
                              </date-picker>
                              <span class="text-danger">{{errorsSearch.fiscal_year}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-0">
                      <div class="col-12 pr-0 pt-0 text-right">
                        <button
                                @click="search(); currentPage = 1"
                                type="button"
                                class="btn btn-success mt-0"
                        >
                          <i class="fa fa-search" aria-hidden="true"></i>
                          検索
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </div>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 検索条件 end ●●●●●●●●●●●●●●●●●●●●●●●●●● --> 

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧エラーメッセージ領域 start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="mb-2" id="list-item-error-msg" v-if="0 < listErrors.length">
      <ul>
        <li v-for="(errorMsg, index) in listErrors" v-bind:key="'list-item-error-' + index">
            {{ errorMsg  }}
        </li>
      </ul>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧エラーメッセージ領域 end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="data-table-container h-100">
      <v-data-table
        :headers="listHeaders"
        :items="listItems"
        :fixed-header="true"
        :height="'100%'"
        disable-pagination
        hide-default-footer
        class="data-table table table-responsive table-hover"
      >

        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="'list-item-' + item.patient_id">
              <!-- チェックボックス -->
              <td>
                <input type="checkbox" 
                  :id="'list-item-checkbox-' + item.patient_id" v-model="item.selected" class="scheduling-patient-contact-list-checkbox"
                  v-if="0 < item.scheduling_contacts.length" />
              </td>
              <!-- 対象者ID -->
              <td class="text-nowrap">
                <a @click="userDetail(item.patient_id, null, 'Detail')" class="text-primary">
                  {{ item.patient_id_format }}
                </a>
              </td>
              <!-- 氏名カナ -->
              <td class="text-nowrap">
                
                {{ item.patient_name_kana }}
              </td>
              <!-- 生年月日/性別 -->
              <td class="text-nowrap">
                {{ item.patient_birthday }} /
                {{ getCodeDef_Gender(item.patient_gender)?.text_short }}
              </td>
              <!-- 利用券整理番号 -->
              <td class="text-nowrap">
                {{ item.use_ticket_ref_number }}
              </td>
              <!-- 保健指導区分 -->
              <td class="text-nowrap">
                {{ getCodeDef_HealthGuidanceDiv(item.health_guidance_div)?.text }}
              </td>
               <!-- 保険者 -->
              <td>
                {{ formatInsurerText(item) }}
              </td>
              <!-- 受付 -->
              <td class="text-nowrap">
                {{ (item.has_reception ? '済み' : '未') }}
              </td>
              <!-- 受付年度 -->
              <td class="text-nowrap">
                {{ formatFiscalYear(item.reception_fiscal_year) }}
              </td>
              <!-- 紐付け -->
              <td class="text-nowrap">
                {{ (item.has_scheduling_contact ? '済み' : '未') }}
              </td>
              <!-- 紐付け対象 -->
              <td class="text-nowrap">
                <div class="p-2" v-if="item.has_scheduling_contact">
                  <input
                    type="radio"
                    class="form-check-input mr-4"
                    :id="'check_scheduling_contact_id_' + item.patient_id + '_unbind'"
                    :name="'check_cheduling_contact_name_' + item.patient_id"
                    v-model="item.selectedSchedulingContact"
                    value="unbind"
                    @change="changeSelectedSchedulingContact(item)" />
                  <label class="form-check-label" :for="'check_scheduling_contact_id_' + item.patient_id + '_unbind'">
                    <div>
                      解除
                    </div>
                  </label>
                </div>
                <div class="p-2" v-for="contact in item.scheduling_contacts" :key="'sche-contact' + contact.scheduling_contact_id">
                  <input
                    type="radio"
                    class="form-check-input mr-4"
                    :id="'check_scheduling_contact_id_' + contact.scheduling_contact_id"
                    :name="'check_cheduling_contact_name_' + item.patient_id "
                    v-model="item.selectedSchedulingContact"
                    :value="contact.scheduling_contact_id"
                    @change="changeSelectedSchedulingContact(item)" />
                  <label class="form-check-label" :for="'check_scheduling_contact_id_' + contact.scheduling_contact_id">
                    <div>
                      <div>
                        <span class="mr-4">{{ formatDatetime(contact.created_at )}}</span>
                        <span class="mr-2">{{ formatInsurerText(contact) }}</span>
                        <span>{{ formatFiscalYear(contact.fiscal_year) }}</span>
                      </div>
                      <div>
                        
                      </div>
                      <div class="pl-5">
                        <div>
                          <span class="mr-2">{{ contact.patient_name_kana }}</span>
                          <span class="mr-2">{{ contact.patient_birthday }}</span>
                          <span>{{ contact.patient_mail_01 }}</span>
                        </div>
                        <div>
                          <span> 
                            {{
                              '(携)' + (contact.patient_tel_01 ? contact.patient_tel_01 : ' ー') + '  ' + 
                              '(宅)' + (contact.patient_tel_02 ? contact.patient_tel_02 : ' ー') + '  ' + 
                              '(職)' + (contact.patient_tel_03 ? contact.patient_tel_03 : ' ー') + '  ' + 
                              '(他)' + (contact.patient_tel_04 ? contact.patient_tel_04 : ' ー')
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <Pagination
      :total-page="totalPage"
      :current-page="currentPage"
      @onHandleChangePage="onHandleChangePage"
    />
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 一覧 end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->


    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● フッタ start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <div class="mt-5 pr-3 pb-3 text-right">
      <button @click="dialog.isShowConfirm = true" type="button" class="btn btn-primary mt-5 pl-5 pr-5">
        <i class="fa fa-database" aria-hidden="true"></i>
        適用
      </button>
    </div>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● フッタ end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->

    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 右スライドパネル start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <slide>
      <Detail
        v-if="component === 'Detail'"
        :dataDetailId="showDetailTargetPatientId"
        :checkViewOrEdit="false"
        :isShowNavigation="randomString"></Detail>
    </slide>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● 右スライドパネル end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->


    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● ダイアログ start ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
    <Dialog :maxWidth="600" :isShow="dialog.isShowConfirm">
      <v-card>
        <v-container>
          <v-card-title> 確認 </v-card-title>
          <div class="m-3">選択行の連絡先紐付け処理を実行します。よろしいですか？</div>
          <v-card-actions class="d-flex align-content-center justify-content-between mr-2 ml-2">
            <button type="button" class="btn btn-secondary" @click="dialog.isShowConfirm = false">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="save(), dialog.isShowConfirm = false">
              OK
            </button>
          </v-card-actions>
        </v-container>
      </v-card>
    </Dialog>
    <!-- ●●●●●●●●●●●●●●●●●●●●●●●●●● ダイアログ end ●●●●●●●●●●●●●●●●●●●●●●●●●● -->
  </div>
</template>

<script>

import Slide from "@/components/Slide";
import Detail from "@/components/DetailImportDiagnosticData";
import Dialog from "@/components/common/Dialog";
import Pagination from "@/components/common/Pagination";
import { mapMutations, mapGetters } from "vuex";
import { SUCCESS } from "@/constants";
import moment from "moment";
import { schedulingPatientContactSchemaValidation as schedulingSchemaValidation } from "../../common/schemaValidation";
import { CODE_GENDER, CODE_RECEPTION, CODE_HEALTH_GUIDANCE_DIV, TOAST_TIME_OUT} from "@/common/constants";
import mixins from "@/mixins";
import SchedulingApiService from "@/services/api/SchedulingApiService";

export default {
    name: "scheduling-patient-contact-list",
    mixins: [mixins],

    components: {
        Pagination,
        Slide,
        Detail,
        Dialog,
    },

    data() {
        return {
            insurers: [],
            healthGuideReceptionDivCodes: [],
            healthGuideDivCodes: [],
            genderCodes: [],

            dataSearch: {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                contact_association_status: "NOTYET_HAS_CONTACT",
                fiscal_year: String(this.getFiscalYear()),
            },
            errorsSearch: {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                contact_association_status: null,
                fiscal_year: null,
            },

            searchedCondition: {
                insurer_code: null,
                fiscal_year: null,
            },

            totalPage: 1,
            currentPage: 1,
            perPage: 50,
            listItems: [],
            listHeaders: [
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "checkbox",
                    class: "text-nowrap patient-contact-list-checkbox-column",
                },
                {
                    text: "対象者ID",
                    sortable: false,
                    value: "patient_id",
                    class: "text-nowrap",
                },
                {
                    text: "氏名(カナ)",
                    sortable: false,
                    value: "patient_name_kana",
                    class: "text-nowrap",
                },
                {
                    text: "生年月日/性別",
                    sortable: false,
                    value: "patient_birthday_gender",
                    class: "text-nowrap",
                },
                {
                    text: "利用券整理番号",
                    sortable: false,
                    value: "patient_use_ticket_ref_number",
                    class: "text-nowrap",
                },
                {
                    text: "保健指導区分",
                    sortable: false,
                    value: "health_guidance_div",
                    class: "text-nowrap",
                },
                { 
                    text: "保険者", 
                    sortable: false, 
                    value: "insurer", 
                    class: "text-nowrap",
                },
                { 
                    text: "受付", 
                    sortable: false, 
                    value: "health_gudaince_reception", 
                    class: "text-nowrap" 
                },
                { 
                    text: "受付年度", 
                    sortable: false, 
                    value: "fiscal_year", 
                    class: "text-nowrap" 
                },
                { 
                    text: "紐付け", 
                    sortable: false, 
                    value: "patient_associated", 
                    class: "text-nowrap" 
                },
                {
                    text: "紐付け対象",
                    sortable: false,
                    value: "association_target",
                    class: "text-nowrap",
                    width: "500px",
                },
            ],
            listErrors: [],
            rotateNavBar: false,

            dialog: {
                isShowConfirm: false,
            },

            component: undefined,
            showDetailTargetPatientId: undefined,
            randomString: "",
        }
    },

    async created() {

        // 保険者 取得.
        await SchedulingApiService.getInsurer().then((res) => {
            this.insurers = [];
            this.insurers = res.data.data;
        });

        // コード定義 取得.
        await SchedulingApiService.getCodeDefineGroups([CODE_GENDER, CODE_RECEPTION, CODE_HEALTH_GUIDANCE_DIV]).then((res) => {
            const codeDefineGroups = res.data;

            // 性別
            const genderCodeGroup = codeDefineGroups[CODE_GENDER];
            this.genderCodes = genderCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                        "text_short": item["acronym"],
                    };
                });
            
            // 保健指導受付区分
            const healthGuideReceptionDivCodeGroup = codeDefineGroups[CODE_RECEPTION];
            this.healthGuideReceptionDivCodes = healthGuideReceptionDivCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                        "text_short": item["acronym"],
                    };
                })
                .filter(function(item) {
                    return item["code"] != "30";
                });
            
            // 保健指導区分
            const healthGuideDivCodeGroup = codeDefineGroups[CODE_HEALTH_GUIDANCE_DIV];
            this.healthGuideDivCodes = healthGuideDivCodeGroup
                .map(function( item ) {
                    return {
                        "id": item["id"],
                        "code": item["code"],
                        "value": item["value"],
                        "text": item["value"],
                    };
                });
        });

        // if(document.getElementById("scheduling-patient-contact-list-checkbox-all") == null){
        //     $("th.patient-contact-list-checkbox-column span").append("<input id='scheduling-patient-contact-list-checkbox-all' type='checkbox' />");
            
        //     const that = this;
        //     $("#scheduling-patient-contact-list-checkbox-all").on("change", function(){
        //         const chkVal = this.checked;
        //         $(".scheduling-patient-contact-list-checkbox").each(function(index, chk) {
        //             chk.checked = chkVal;
        //         });
        //         that.listItems.forEach(listItem => {
        //             if (listItem.selected !== null) {
        //                 listItem.selected = chkVal;
        //             }
        //         });
        //     });
        // }
    },

    computed: {
        /**
         * 年入力オプション
         */
        optionYear() {
            return {
                format: "YYYY",
                useCurrent: false,
                showClear: true,
                showClose: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
                minDate: "2000-01-01",
                maxDate: "2099-12-30",
                locale: moment.locale("ja"),
            };
        },

        ...mapGetters({
            user: "common/getUser",
        }),
    },

    methods: {
        ...mapMutations({
            setIsLoading: "common/setIsLoading",
        }),

        /**
         * 
         * @param {*} date 
         */
        formatDatetime(date) {
            if (date) {
                return moment(date).format("YYYY-MM-DD HH:mm:ss");
            }
            return null;
        },

        /**
         * 
         */
        formatFiscalYear(fiscalYear) {
            if (fiscalYear) {
                return String(fiscalYear).slice(-2) + "年度";
            }
            return null;
        },

        /**
         * コード定義取得 性別.
         * @param {*} genderCode 
         */
        getCodeDef_Gender(genderCode) {
            if (genderCode) {
                let data = this.genderCodes.filter((x) => x.code == genderCode);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * コード定義取得 保健指導区分.
         * @param {*} healthGuidanceDiv 
         */
        getCodeDef_HealthGuidanceDiv(healthGuidanceDiv) {
            if (healthGuidanceDiv) {
                let data = this.healthGuideDivCodes.filter((x) => x.code == healthGuidanceDiv);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * コード定義取得 保健指導受付区分.
         * @param {*} healthGuidanceReceptionDiv 
         */
        getCodeDef_HealthGuidanceReceptionDiv(healthGuidanceReceptionDiv) {
            if (healthGuidanceReceptionDiv) {
                let data = this.healthGuideReceptionDivCodes.filter((x) => x.code == healthGuidanceReceptionDiv);
                if (data.length < 1) return null;
                return data[0];
            }
            return null;
        },

        /**
         * 
         * @param {*} item 
         */
        formatInsurerText(item) {
            return `[${item.insurer_code}] ${item.insurer_name}`;
        },

        /**
         * 
         * @param {*} item 
         */
        changeSelectedSchedulingContact(item) {
            const chkval = (item.selectedSchedulingContact != item.scheduling_contact_id);
            $("#list-item-checkbox-" + item.patient_id).prop("checked", chkval);
            item.selected = chkval;
        },

        /**
         * 検索クリア.
         */
        clearAllSearch() {

            this.clearSearchErrorMessage();
            this.dataSearch = {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                contact_association_status: "NOTYET_HAS_CONTACT",
                fiscal_year: String(this.getFiscalYear()),
            };
        },

        /**
         * 
         */
        clearSearchedCondition() {
            this.searchedCondition = {
                insurer_code: null,
                fiscal_year: null,
            };
        },

        /**
         * 検索エラーメッセージクリア.
         */
        clearSearchErrorMessage() {
            this.errorsSearch = {
                insurer: null,
                patient_name: null,
                patient_ticket: null,
                health_guide_div_code: null,
                health_guide_reception_div_code: null,
                contact_association_status: null,
                fiscal_year: null,
            };
        },

        /**
         * 一覧エラーメッセージクリア.
         */
        clearListErrorMessage() {
            this.listErrors = [];
        },

        /**
         * バリデーション.
         * @param {*} field 
        */
        validate(field) {
            schedulingSchemaValidation
                .validateAt(field, this.dataSearch)
                .then(() => {
                    this.errorsSearch[field] = "";
                })
                .catch((err) => {
                    this.errorsSearch[field] = err.message;
                });
        },

        /**
         * 
         */
        search() {
            this.totalPage = 1;
            this.currentPage = 1;
            this.getList();
        },

        /**
         * 一覧取得.
        */
        getList() {
            schedulingSchemaValidation
                .validate(this.dataSearch, { abortEarly: false })
                .then(() => {
                   
                    this.listItems = [];

                    this.clearSearchedCondition();
                    this.clearSearchErrorMessage();
                    this.clearListErrorMessage();

                    this.setIsLoading(true);
                    this.dataSearch.fiscal_year = moment(this.dataSearch.fiscal_year).format("YYYY");
                    
                    SchedulingApiService.getPatientContactList(this.dataSearch, this.currentPage, this.perPage)
                        .then((res) => {
                            window.scrollTo({
                                behavior: "smooth",
                                top: 0,
                            });

                            const { data, status, error } = res;
                            this.setIsLoading(false);

                            if (status !== SUCCESS) {
                                this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                                return;
                            }

                            this.listItems = data.data;
                            this.listItems.forEach(listItem => {

                                listItem.patient_id_format = String(listItem.patient_id).padStart(10, "0");

                                //===================================
                                // 行選択、及び日程調整連絡先 紐付け選択値
                                //===================================
                                listItem.selected = false;
                                listItem.selectedSchedulingContact = null;
                                if (listItem.has_scheduling_contact) {
                                    // 紐付けが存在する場合は、紐付け済の連絡先
                                    listItem.selectedSchedulingContact = listItem.scheduling_contact_id;
                                } else {
                                    // 紐付けされていない場合.
                                    if (listItem.scheduling_contacts.length == 1) {
                                        // 選択肢が１つなら、その連絡先を選択.
                                        listItem.selectedSchedulingContact = listItem.scheduling_contacts[0].scheduling_contact_id;
                                        // 対象行にチェック.
                                        listItem.selected = true;
                                    } else if (1 < listItem.scheduling_contacts.length) {
                                        // 対象行にチェック.
                                        listItem.selected = true;
                                    } else {
                                        // 紐付けされておらず、選択肢もない
                                        listItem.selected = null;
                                    }
                                }
                            });

                            $(".demo-list").closest(".container-fluid").css("height", this.listItems.length ? "100%" : "auto");

                            this.totalPage = data.last_page;
                            this.currentPage = data.current_page;

                            this.searchedCondition.insurer_code = this.dataSearch.insurer;
                            this.searchedCondition.fiscal_year = this.dataSearch.fiscal_year;

                            if (this.listItems.length < 1) {
                                this.notifyNoSearchResults();
                            }
                        }).catch((err) => {
                            this.setIsLoading(false);
                            this.$toast.error(`${err}`, TOAST_TIME_OUT);
                            return;
                        });
                })
                .catch((err) => {
                    this.setIsLoading(false);
                    this.$toast.error(`${err}`, TOAST_TIME_OUT);
                    this.notifyInputInvalidToast();
                    return;
                });
        },

        /**
         * 適用.
         */
        save() {
            // エラーメッセージクリア.
            this.clearListErrorMessage();

            // 選択行の取得
            const selectedListItems = this.listItems.filter(listItem => {
                return listItem.selected;
            });

            if (selectedListItems.length < 1) {
                this.notifyWarn("適用対象にチェックを付けてください。");
                return;
            }

            // バリデーション
            selectedListItems.forEach(function(i){
                if (i.selectedSchedulingContact === null) {
                    this.listErrors.push(`[${i.patient_id_format}]${i.patient_name_kana}： 紐付け対象を選択してください。`);
                }
            }.bind(this));

            if (0 < this.listErrors.length) {
                this.notifyInputInvalidToast();
                return;
            }

            // ポストデータ生成.
            const postData = {
                fiscal_year: this.searchedCondition.fiscal_year,
                insurer_code: this.searchedCondition.insurer_code,
                associations: []
            };
            selectedListItems.forEach(function(i){
                const association = {
                    patient_id: i.patient_id,
                    has_scheduling_contact: i.has_scheduling_contact,
                    scheduling_contact_id: i.selectedSchedulingContact,
                };
                postData.associations.push(association);
            });

            try {
                // マスク.
                this.setIsLoading(true);

                SchedulingApiService.storeSchedulingPatientContactAssociation(postData)
                    .then((res) => {
                        const { data, status, error } = res;
                        this.setIsLoading(false);

                        if (status !== SUCCESS) {
                            this.$toast.error(`[${error?.messageID}] ${error?.message}`);
                            return;
                        }

                        this.$toast.open({
                            message: "連絡先紐付け処理の登録が完了しました。",
                            type: "success",
                        });

                        this.listItems = [];
                        this.search();
                    })
                    .catch((err) => {
                        // ローディング状態解除.
                        this.setIsLoading(false);
                        this.$toast.error(`${err}`, TOAST_TIME_OUT);
                    });

            } catch(err) {
                // ローディング状態解除.
                this.setIsLoading(false);
                this.$toast.error(`${err}`, TOAST_TIME_OUT);
            };
        },

        /**
         * 
         * @param {*} pageNumber 
         */
        onHandleChangePage(pageNumber) {
            this.currentPage = pageNumber;
            this.getList();
        },

        /**
         * 対象者詳細
         * @param {*} id 
         * @param {*} health_guidance_reception_id 
         * @param {*} type 
         */
        userDetail(id, health_guidance_reception_id, type) {
            this.showDetailTargetPatientId = id;
            //this.dataReceptionsId = health_guidance_reception_id;
            this.component = type;
            this.randomString = (Math.random() + 1).toString(36).substring(7);
        },
    },

    /**
     * 
     */
    destroyed() {
        $(document).unbind()
    }
}

</script>

<style lang="sass">
@import "../../styles/common"
</style>
<style lang="scss" scoped>
  .wrapper .main .content .container-fluid .data-table-container {
    padding: 0;
    .data-table {
      margin: 0;
    }
  }
  .container-pagination {
    margin: 0px !important;
    height: 60px !important;
    max-height: 60px !important;
  }

  #list-item-error-msg {
    width: 100%;
    height: 120px;
    min-height: 120px;
    max-height: 180px;
    overflow: auto;
    background-color: #FFF0F5;
    color: red;
  }
</style>