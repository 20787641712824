import { render, staticRenderFns } from "./hg-organ-detail.vue?vue&type=template&id=8081077c&scoped=true"
import script from "./hg-organ-detail.vue?vue&type=script&lang=js"
export * from "./hg-organ-detail.vue?vue&type=script&lang=js"
import style0 from "./hg-organ-detail.vue?vue&type=style&index=0&id=8081077c&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8081077c",
  null
  
)

export default component.exports