import Repository from "./Repository";
import { ADMIN_REPOSITORY, SUPPORT_FLOW } from "../../constants";
// import { CODE_SUPPORT_EXEC_DIV } from "@/common/constants";

export default {
    async getLeader(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/get-system-users/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupportEvalCodesByCode(code = null) {
        try {
            return await Repository.get("health_support_eval_codes/list-or-by-code", {code})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async segmentContinue(segment_id, action, data) {
        try {
            return await Repository.post(`${ADMIN_REPOSITORY}/health-support-flow-segment/${segment_id}/update-segment-evals-continue/${action}`, data);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getDatafirst(segment_id) {
        try {
            return await Repository.get(`health-support-form/get-data-first/${segment_id}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async list(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getHealthSupport(typeSupport, midEvalFlg) {
        try {
            return await Repository.get("health-support-form/list-form-support", {support_form_div: typeSupport, mid_eval_flg: midEvalFlg})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getOptionPlan({segment_id = null, token = null, period = null}) {
        try {
            return await Repository.get("health-support-form/get-option-plan", {
                health_support_flow_segment_id: segment_id,
                token,
                period,
            });
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getFormLayoutControl(token) {
        try {
            return await Repository.get("health-support-form/get-form-layout-control", {token: token})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getFirstPlans(token) {
        try {
            return await Repository.get("health-support-form/get-first-plans", {token: token})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getFirstPlansBySegmentId(segmentId) {
        try {
            return await Repository.get("health-support-form/get-first-plans", {segment_id: segmentId})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async checkTokenExpiration(token) {
        try {
            return await Repository.get("health-support-form/check-token-expiration", {token: token})
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async healthGuidanceReceptionDetail(id) {
        try {
            return await Repository.get(`/admin/health-guidance-reception/detail/${id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getSegmentDetail(segment_id) {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/health-support-flow-segment/detail/${segment_id}`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getOptionByCode(eval_item_code = null, eval_div, evaluator_div) {
        try {
            var optionExecutor = "0001000032";
            var eval_item_code = [optionExecutor];
            return await Repository.get("health-support-form/get-option-by-code",{eval_item_code: eval_item_code, eval_div: eval_div, evaluator_div: evaluator_div})
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getListSupportPerson() {
        try {
            return Repository.get(`${SUPPORT_FLOW}/support-person`)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getVersion(form, data) {
        try {
            return await Repository.post(`get-version/${form}`,data)
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async saveIncompleteFirstSupport(supportFlowId, { isIncomplete, date, reason }) {
        try {
            return await Repository.post(`/support-flow/${supportFlowId}/incomplete-first-support`, {
                is_incomplete: isIncomplete,
                date: date,
                reason: reason,
            });
        } catch (e) {
            return this.setErrors(e);
        }
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
