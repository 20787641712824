import Repository from "./Repository";
import { ADMIN_REPOSITORY } from "../../constants";

export default {

    async getInsurer() {
        try {
            return await Repository.get(`${ADMIN_REPOSITORY}/insurer`,);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getSystemSetting(key) {
        try {
            return await Repository.get(`system-setting/setting?key=${key}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getSystemSettings(keys) {
        try {
            return await Repository.get(`system-setting/settings?keys=${keys}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },

    async getCodeDefineGroups(groups) {
        try {
            const groupsStr = groups.join("|");
            return await Repository.get(`code-define-groups?groups=${groupsStr}`);
        } catch (e) {
            return this.setErrors(e);
        }
    },
    async getSchedulingContactList(data, page = 1, limit = 10) {
        return await Repository.post(`${ADMIN_REPOSITORY}/scheduling/contact-list`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getPatientContactList(data, page = 1, limit = 10) {
        return await Repository.post(`${ADMIN_REPOSITORY}/scheduling/patient-contact-list`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async updateSchedulingContact(data) {
        return await Repository.patch(`${ADMIN_REPOSITORY}/scheduling/contact`,data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async deleteSchedulingContact(data) {
        return await Repository.delete(`${ADMIN_REPOSITORY}/scheduling/contact`,data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async storeSchedulingPatientContactAssociation(data) {
        return await Repository.patch(`${ADMIN_REPOSITORY}/scheduling/patient-contact-association`,data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getPatientSchedulingAdjustmentList(data, page = 1, limit = 10) {
        return await Repository.post(`${ADMIN_REPOSITORY}/scheduling/adjustment/list`, {data, page, limit})
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async storeSchedulingAdjustmentRequest(data) {
        return await Repository.post(`${ADMIN_REPOSITORY}/scheduling/adjustment/request/store`,data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async getReceptionPages() {
        return await Repository.get(`${ADMIN_REPOSITORY}/scheduling/reception-page/list`)
            .catch((e) => {
                return this.setErrors(e);
            });
    },
    async storeReceptionPage(data) {
        return await Repository.post(`${ADMIN_REPOSITORY}/scheduling/reception-page/store`,data)
            .catch((e) => {
                return this.setErrors(e);
            });
    },

    setErrors(e) {
        const errorCode = e.response ? e.response.status : 500;
        return {
            status: false,
            errorCode: errorCode,
            data: e.response ? e.response.data : "error",
            error: e,
        };
    },
    catchError(e) {
        return e.response ? e.response.data : "error";
    },
};
